import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { exportXLSX } from './Export';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorBoundary from '../shared/ErrorBoundaryModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { MDBAnimation } from 'mdbreact';
import { TopBar, TopBarLeft } from 'js/components/layout/Containers';
import historyActions, {
  propType as historyType,
} from 'js/redux/reducers/History';

import customerActions, {
  propType as customerType,
  selectors as customerSelectors,
} from 'js/redux/reducers/Customer';
import orderActions from 'js/redux/reducers/Order';

import CustomerModal from '../shared/CustomerModal';
import { hasPrivilege } from '../../mylib/Privileges';
import { spinner } from '../../mylib/Utils';
import MoveCustomerOrder from './MoveCustomerOrder';
import SearchInput from '../shared/SearchInput';
import CustomerHistoryTable from './CustomerHistoryTable';
import { offline_mode } from '../../Constants';

const propTypes = {
  t: PropTypes.func.isRequired,
  customer: customerType,
  selected_customer: PropTypes.object,
  customer_pending: PropTypes.bool,
  ordersHistory: historyType.isRequired,
  loadCustomer: PropTypes.func.isRequired,
  saveCustomer: PropTypes.func.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  searchCustomerOrderitems: PropTypes.func.isRequired,
  clearCustomerOrderitems: PropTypes.func.isRequired,
  fetchOrderitem: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
  location: PropTypes.object,
};

const defaultProps = {};

class CustomerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  componentWillUnmount() {
    this.props.clearCustomerOrderitems();
  }

  showCustomerModal = () => {
    this.setState((state) => ({
      customer_modal_open: !state.customer_modal_open,
    }));
  };

  handleToggleDeleteModal = () => {
    this.setState((prevState) => ({
      show_delete_modal: !prevState.show_delete_modal,
    }));
  };

  handleDeleteCustomer = () => {
    this.props.deleteCustomer(this.props.selected_customer);
    this.props.selectCustomer(null);
    this.setState({ show_delete_modal: false });
  };

  getCustomerCard = () => {
    let style = {
      position: ' relative',
      padding: 0,
      background: ' transparent',
      display: 'flex',
      flexDirection: ' row',
    };

    const { selected_customer } = this.props;
    return (
      <Card style={style}>
        <CardBody
          className="scroll"
          style={{
            color: 'black',
            background: 'white',
            fontWeight: '800',
            alignItems: 'center',
            borderRadius: '0.5rem',
            width: '50%',
            marginRight: '1em',
            maxHeight: '8rem',
          }}
        >
          <div data-testid="card-customername">
            {selected_customer?.customername}
          </div>
          <div data-testid="card-companyname">
            {selected_customer?.companyname}
          </div>
        </CardBody>
        <Button
          data-cy={'fn.edit_customer'}
          disabled={!hasPrivilege('history_edit_customer')}
          data-denied={!hasPrivilege('history_edit_customer')}
          key="pencel"
          className="btn btn-primary btn-left btn-top"
          onClick={this.showCustomerModal}
        >
          <FontAwesomeIcon icon="pencil-alt" />
        </Button>
        {/* disabled={!hasPrivilege('history_move_customer')}
          data-denied={!hasPrivilege('history_move_customer')}*/}
        <Button
          key="move"
          className="btn btn-primary btn-left btn-top"
          onClick={() => {
            this.setState({ show_move_orders: true });
          }}
        >
          <FontAwesomeIcon icon="arrow-down" />
        </Button>
        <Button
          disabled={!hasPrivilege('history_delete_customer')}
          data-denied={!hasPrivilege('history_delete_customer')}
          key="trash"
          className="btn btn-primary btn-left btn-top"
          onClick={this.handleToggleDeleteModal}
        >
          <FontAwesomeIcon icon="trash-alt" />
        </Button>
      </Card>
    );
  };

  handleSave = (customer) => {
    this.props.saveCustomer(customer);
    this.props.selectCustomer(customer);
  };

  startCustomerOrderSearch = () => {
    const customerid = this.props.selected_customer?.customerid;
    if (customerid) {
      const {
        history_search,
        history_fromDate,
        history_toDate,
        onlyCustomFormulas,
        history_seek,
      } = this.state.formControls;
      this.props.searchCustomerOrderitems(
        history_search,
        history_fromDate,
        history_toDate,
        onlyCustomFormulas,
        false, //local_only
        customerid,
        history_seek
      );
    }
  };

  getInitialState() {
    return {
      formControls: {
        history_search: '',
        history_fromDate: '',
        history_toDate: '',
        history_seek: null,
        onlyCustomFormulas: false,
      },
      customer_modal_open: false,
      history_searched: false,
      show_delete_modal: false,
      show_move_orders: false,
    };
  }

  setShowMoveOrders = (flag) => this.setState({ show_move_orders: flag });

  orderClicked = (order) => {
    this.props.fetchOrderitem(order.itemid);
  };

  goBack = () => {
    window.history.back();
  };

  changeHandler = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state) => ({
      formControls: { ...state.formControls, [name]: value },
    }));
  };

  reset = () => {
    this.setState(this.getInitialState());
  };

  exportHandle = () => {
    const { t, ordersHistory } = this.props;
    const data = ordersHistory.customer_orderitems.data.map((orderitem) => ({
      [`${t('lbl.itemID', 'Item ID')}`]: orderitem.itemid,
      [`${t('lbl.dateTime', 'Date/Time')}`]: orderitem.orderdate,
      [`${t('lbl.colorCode', 'Color code')}`]: orderitem.colourcode,
      [`${t('lbl.colorName', 'Color name')}`]: orderitem.colourname,
      [`${t('lbl.productName', 'Product name')}`]: orderitem.productname,
      [`${t('lbl.baseCode', 'Base code')}`]: orderitem.basecode,
      [`${t('lbl.canSize', 'Can size')}`]: orderitem.cansizecode,
      [`${t('lbl.lotSize', 'Lot size')}`]: orderitem.ncans,
      [`${t('lbl.customerName', 'Customer name')}`]: orderitem.customername,
    }));

    exportXLSX(data, 'customerhistory.xlsx');
  };

  render() {
    const { t, selected_customer, customer_pending } = this.props;

    let curr = new Date();
    let maxDate = curr.toISOString().substr(0, 10);

    return (
      <MDBAnimation type="zoomIn" duration="200ms">
        {offline_mode && (
          <div className="offline_info_text">
            {t(
              'lbl.only_orders_in_offline_mode',
              'Only orders made in offline mode'
            )}
          </div>
        )}
        <Modal centered isOpen={this.state.show_delete_modal}>
          <ModalHeader>
            {t('fn.delete_customer', 'Delete customer')}
          </ModalHeader>
          <ModalBody>
            {t(
              'lbl.are_you_sure_customer',
              'Are you sure want to delete this customer ?'
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleDeleteCustomer}>
              {t('fn.delete', 'Delete')}
            </Button>
            <Button onClick={this.handleToggleDeleteModal}>
              {t('fn.cancel', 'Cancel')}
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.show_move_orders ? (
          <MoveCustomerOrder setShowMoveOrders={this.setShowMoveOrders} />
        ) : (
          <>
            <TopBar>
              <TopBarLeft>
                <h2
                  id="backToHomePage"
                  className="clickable-text directly-over-bg mt-8"
                  onClick={this.goBack}
                >
                  <FontAwesomeIcon
                    icon="arrow-left"
                    style={{ fontSize: '1.7rem' }}
                  />{' '}
                  {selected_customer?.customername || ''}
                </h2>
              </TopBarLeft>
            </TopBar>
            {customer_pending ? (
              spinner()
            ) : (
              <Container className="justify-content-center">
                <Row>
                  <Col xs="3" className="p-0 mb-40">
                    {/* Search field */}
                    <InputGroup className="p-0 pr-16">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-small-text">
                          <FontAwesomeIcon icon="search" />
                        </span>
                      </InputGroupAddon>
                      <SearchInput
                        id="search"
                        className="form-control input-small"
                        use_default_style={false}
                        value={this.state.formControls.history_search}
                        onChange={(e) =>
                          this.setState((state) => ({
                            formControls: {
                              ...state.formControls,
                              history_search: e.target.value,
                              seek: null,
                            },
                          }))
                        }
                        onKeyUp={(e) =>
                          e.key === 'Enter'
                            ? this.startCustomerOrderSearch()
                            : null
                        }
                        placeholder={t('prompt.search', 'Search')}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="2" className="p-0">
                    {/* To date */}
                    <InputGroup className="p-0">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-small-text">
                          {t('lbl.to.colon', 'To:')}
                        </span>
                      </InputGroupAddon>
                      <Input
                        className="input-small"
                        type="date"
                        name="history_toDate"
                        required="required"
                        max={maxDate}
                        value={this.state.formControls.history_toDate}
                        onChange={this.changeHandler}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="2" className="">
                    <Button
                      onClick={this.startCustomerOrderSearch}
                      className="btn-small"
                      data-testid="load"
                    >
                      {t('fn.load', 'Load')}
                    </Button>
                  </Col>
                  <Col xs="5" className="p-0">
                    {this.getCustomerCard()}
                  </Col>
                </Row>
                <Row>
                  <Col xs="2">
                    <Button
                      data-testid="export"
                      data-denied={!hasPrivilege('export')}
                      disabled={!hasPrivilege('export')}
                      onClick={this.exportHandle}
                      style={{
                        width: '100%',
                        marginBottom: '10px',
                      }}
                    >
                      {t('fn.export')}
                    </Button>
                  </Col>
                  <Col className="ml-16 pl-20 pt-8 clickable-text directly-over-bg">
                    <Input
                      type="checkbox"
                      id="customFormulasOnly"
                      name="onlyCustomFormulas"
                      checked={this.state.formControls.onlyCustomFormulas}
                      onChange={this.changeHandler}
                    />
                    <Label for="customFormulasOnly" check>
                      {t('lbl.customOnly', 'Custom formulas only')}
                    </Label>
                  </Col>
                </Row>
                <Row style={{ height: 'calc(100vh - 20.375rem)' }}>
                  {/* Actual customer table */}
                  <CustomerHistoryTable onSelect={this.orderClicked} />
                </Row>
              </Container>
            )}
            <ErrorBoundary>
              <CustomerModal
                show={this.state.customer_modal_open}
                allowSearch={false}
                onSave={this.handleSave}
                selectedCustomer={selected_customer}
                toggleShow={this.showCustomerModal}
              />
            </ErrorBoundary>
          </>
        )}
      </MDBAnimation>
    );
  }
}

CustomerPage.propTypes = propTypes;
CustomerPage.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    selected_customer: customerSelectors.selected_customer(state),
    customer_pending: customerSelectors.selected_customer_pending(state),
    ordersHistory: state.history,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCustomer: customerActions.loadCustomer,
      selectCustomer: customerActions.selectCustomer,
      saveCustomer: customerActions.saveCustomer,
      searchCustomerOrderitems: historyActions.searchCustomerOrderitems,
      clearCustomerOrderitems: historyActions.clearCustomerOrderitems,
      fetchOrderitem: orderActions.fetchOrderitem,
      deleteCustomer: orderActions.deleteCustomer,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CustomerPage)
);
