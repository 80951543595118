import { globalizeSelectors } from '../Utils';
import { hasOfflineToken, hasToken } from 'js/api/WebRequest';

/*
 * action types
 */

const types = {
  LOGOUT_SITE: 'LOGIN/LOGOUT_SITE',
  LOGOUT_SITE_FULFILLED: 'LOGIN/LOGOUT_SITE_FULFILLED',
  LOGIN_SITE: 'LOGIN/LOGIN_SITE',
  LOGIN_SITE_PENDING: 'LOGIN/LOGIN_SITE_PENDING',
  LOGIN_SITE_FULFILLED: 'LOGIN/LOGIN_SITE_FULFILLED',
  LOGIN_SITE_REJECTED: 'LOGIN/LOGIN_SITE_REJECTED',
  LOGIN_SITE_LOGGED_IN: 'LOGIN/LOGIN_SITE_LOGGED_IN',

  REFRESH_TOKEN: 'LOGIN/REFRESH_TOKEN',
  REFRESH_TOKEN_PENDING: 'LOGIN/REFRESH_TOKEN_PENDING',
  REFRESH_TOKEN_FULFILLED: 'LOGIN/REFRESH_TOKEN_FULFILLED',
  REFRESH_TOKEN_REJECTED: 'LOGIN/REFRESH_TOKEN_REJECTED',

  SET_TRIED_QT_LOGIN: 'LOGIN/SET_TRIED_QT_LOGIN',
  LOAD_OFFLINE_TOKEN: 'LOGIN/LOAD_OFFLINE_TOKEN',
  SET_TRIED_DEFAULT_LOGIN: 'LOGIN/SET_TRIED_DEFAULT_LOGIN',
  SET_LOGIN_TRIALS_PENDING: 'LOGIN/SET_LOGIN_TRIALS_PENDING',
};

/*
 * action creators
 */

const actions = {
  logInSite: (username, password, activationcode) => ({
    type: types.LOGIN_SITE,
    payload: { username, password, activationcode },
  }),
  loadOfflineToken: () => ({ type: types.LOAD_OFFLINE_TOKEN }),
  logOutSite: () => ({ type: types.LOGOUT_SITE }),
  refreshToken: () => ({ type: types.REFRESH_TOKEN }),

  setTriedQTLogin: (tried) => ({
    type: types.SET_TRIED_QT_LOGIN,
    payload: tried,
  }),
  setTriedDefaultLogin: (tried) => ({
    type: types.SET_TRIED_DEFAULT_LOGIN,
    payload: tried,
  }),
  setLoginTrialsPending: (tried) => ({
    type: types.SET_LOGIN_TRIALS_PENDING,
    payload: tried,
  }),
};

const mountPath = 'login'; // mount point in global state, must match root reducer

/**
 * Handling login and log out site actions
 */

const initialState = {
  site_logged_in: hasOfflineToken() || hasToken(),
  site_login_start: false,
  site_login_error: null,
  refresh_token_pending: false,
  refresh_token_error: null,
  tried_qt_login: false,
  tried_default_login: !window.location.host.startsWith('localhost'),
  login_trials_pending: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOGIN_TRIALS_PENDING: {
      return {
        ...state,
        login_trials_pending: action.payload,
      };
    }
    case types.SET_TRIED_QT_LOGIN: {
      return {
        ...state,
        tried_qt_login: action.payload,
      };
    }

    case types.SET_TRIED_DEFAULT_LOGIN: {
      return {
        ...state,
        tried_default_login: action.payload,
      };
    }
    case types.REFRESH_TOKEN_PENDING: {
      return {
        ...state,
        refresh_token_pending: action.payload,
      };
    }
    case types.REFRESH_TOKEN_REJECTED: {
      return {
        ...state,
        refresh_token_error: action.payload,
      };
    }
    case types.REFRESH_TOKEN_FULFILLED: {
      return {
        ...state,
        refresh_token_error: null,
      };
    }
    case types.LOGIN_SITE_PENDING: {
      return {
        ...state,
        site_logged_in: false,
        site_login_start: true,
        site_login_error: null,
      };
    }
    case types.LOGIN_SITE_REJECTED: {
      return {
        ...state,
        site_logged_in: false,
        site_login_start: false,
        site_login_error: action.payload,
      };
    }
    case types.LOGIN_SITE_LOGGED_IN:
    case types.LOGIN_SITE_FULFILLED: {
      return {
        ...state,
        site_logged_in: true,
        site_login_start: false,
        site_login_error: null,
      };
    }

    case types.LOGOUT_SITE_FULFILLED: {
      return {
        ...state,
        site_logged_in: false,
        site_login_start: false,
        site_login_error: null,
      };
    }

    default: {
      return state;
    }
  }
}

// selectors

const localSelectors = {
  logged_in: (state) => state.site_logged_in,
  refresh_token_pending: (state) => state.refresh_token_pending,
  tried_qt_login: (state) => state.tried_qt_login,
  tried_default_login: (state) => state.tried_default_login,
  login_trials_pending: (state) => state.login_trials_pending,
};

const selectors = globalizeSelectors(localSelectors, mountPath);

export { types as actionTypes, actions as default, selectors, reducer };
