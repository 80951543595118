import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import loginAPI from 'js/api/Login';
import offlineAPI from 'js/api/Offline';
import actions from '../reducers/Login';
import loginActions, {
  actionTypes as loginActionTypes,
  actionTypes,
  selectors as loginSelectors,
  selectors,
} from '../reducers/Login';
import {
  getRefreshToken,
  hasToken,
  setOfflineToken,
  setToken,
  setUserType,
} from 'js/api/WebRequest';
import configActions from '../reducers/Configuration';
import { push } from 'connected-react-router';
import {
  DEFAULT_LOGIN_PASSWORD,
  DEFAULT_LOGIN_USERNAME,
  USERTYPE_IST,
  USERTYPE_IST_PRO,
  USERTYPE_SITE,
  USERTYPE_SITE_MATCHING,
  offline_mode,
} from '../../Constants';
import errorActions from '../reducers/Errors';
import i18n from '../../localization/i18n';
import tokenApi from 'js/api/AccessToken';
import { waitForProtectionStatus } from './Protection';
import log from '../../api/Logger';

// log in with credentials as payload
function* logIn(action) {
  yield put({ type: actionTypes.LOGIN_SITE_PENDING });
  try {
    const [response] = yield race([
      call(loginAPI.login(action.payload)),
      take(actionTypes.LOGOUT_SITE),
    ]);
    if (response) {
      // Check usertype is not admin!
      if (
        response.data.usertype !== USERTYPE_SITE &&
        response.data.usertype !== USERTYPE_SITE_MATCHING &&
        response.data.usertype !== USERTYPE_IST &&
        response.data.usertype !== USERTYPE_IST_PRO
      ) {
        log.error('Invalid site user');
        yield put({
          type: actionTypes.LOGIN_SITE_REJECTED,
          payload: { response: { data: { msg: 'Invalid site user' } } },
        });
      } else {
        log.info('Successful site login');
        setToken(response.data.access_token, response.data.refresh_token);
        setUserType(response.data.usertype);
        yield put({
          type: actionTypes.LOGIN_SITE_FULFILLED,
          payload: response.data.offline_token_data,
        });
        // init app
        yield put(configActions.initializeApp());
      }
    } else {
      yield call(logOut);
    }
  } catch (e) {
    log.error('Error in site login: ' + e.response?.data?.msg || e.message);
    yield put({ type: actionTypes.LOGIN_SITE_REJECTED, payload: e });
  }
}

function* logOut() {
  try {
    log.info('Site logout');
    if (hasToken()) {
      // Clear also QtSide token cache.
      yield call(loginAPI.logout());
    }
    if (window.qtside) {
      yield call(() => tokenApi.setToken(null));
      yield call(() => tokenApi.setRefreshToken(null));
    }
  } catch (e) {
    log.error(e);
  } finally {
    // Navigate to homepage
    yield put(push('/'));
    setToken(null);
    yield put({ type: actionTypes.LOGOUT_SITE_FULFILLED });
  }
}

export function* load_offline_token() {
  if (offline_mode) {
    const offline_token = yield call(() => offlineAPI.getOfflineAccessToken());
    if (offline_token) {
      setOfflineToken(offline_token);
      yield put({
        type: loginActionTypes.LOGIN_SITE_FULFILLED,
      });
    } else {
      yield put(actions.logOutSite());
    }
  }
}

export function* tryLogins() {
  const login_trials_pending = yield select(
    loginSelectors.login_trials_pending
  );
  if (login_trials_pending) {
    return;
  }
  yield put(loginActions.setLoginTrialsPending(true));

  const tried_qt_login = yield select(loginSelectors.tried_qt_login);

  if (window.qtside && !tried_qt_login) {
    yield put(loginActions.setTriedQTLogin(true));

    const token = yield call(() => tokenApi.getToken());
    const refesh = yield call(() => tokenApi.getRefreshToken());
    if (token && token !== 'None') {
      log.info('Using app tokens');
      setToken(token, refesh);

      yield put({
        type: loginActionTypes.LOGIN_SITE_FULFILLED,
      });

      yield put(loginActions.setLoginTrialsPending(false));
      return;
    } else {
      log.warning('Tokens missing in app');
    }
  }

  const tried_default_login = yield select(loginSelectors.tried_default_login);

  if (!tried_default_login && !offline_mode) {
    log.info('Trying default login');
    yield put(
      loginActions.logInSite(DEFAULT_LOGIN_USERNAME, DEFAULT_LOGIN_PASSWORD)
    );
    // wait for successful login
    let { success, failure } = yield race({
      success: take(loginActionTypes.LOGIN_SITE_FULFILLED),
      failure: take(loginActionTypes.LOGIN_SITE_REJECTED),
    });

    yield put(loginActions.setTriedDefaultLogin(true));

    if (success) {
      yield put(loginActions.setLoginTrialsPending(false));
      return;
    } else if (failure.payload?.response?.status !== 401) {
      yield take(loginActionTypes.LOGIN_SITE_FULFILLED);
      yield put(loginActions.setLoginTrialsPending(false));
      return;
    }
  }

  yield put(loginActions.setLoginTrialsPending(false));
  yield put(actions.logOutSite());
}

function* refreshToken() {
  try {
    if (!offline_mode) {
      const refresh_pending = yield select(selectors.refresh_token_pending);
      // Refresh is not pending
      if (!refresh_pending) {
        if (!getRefreshToken()) {
          // Setting refresh token
          const token = yield call(() => tokenApi.getToken());
          const refesh = yield call(() => tokenApi.getRefreshToken());
          if (token && token !== 'None') {
            log.info('Using app tokens');
            setToken(token, refesh);
          } else {
            log.warning('Tokens missing in browser and app');
          }
        }
        yield put({ type: actionTypes.REFRESH_TOKEN_PENDING, payload: true });
        try {
          let re = yield call(loginAPI.refresh());
          if (re) {
            log.info('Token refreshed');
            setToken(re.data.access_token, re.data.refresh_token);
            yield put({
              type: actionTypes.REFRESH_TOKEN_FULFILLED,
            });
            yield put({
              type: actionTypes.LOGIN_SITE_FULFILLED,
              payload: re.data.offline_token_data,
            });
          }
        } catch (e) {
          yield call(() => tryLogins());
        }
        yield put({ type: actionTypes.REFRESH_TOKEN_PENDING, payload: false });
      }
    }
  } catch (e) {
    yield put({ type: actionTypes.REFRESH_TOKEN_PENDING, payload: false });
    // Not authorized
    yield call(() => tryLogins());
  }
}

function* refresh_tokens_to_libraries(action) {
  try {
    /**
     * Only refresh the token if the application is not running in offline_mode
     */
    if (window.qtside && !offline_mode) {
      // Check that cloud system is in place

      let prot_status = yield call(waitForProtectionStatus);

      if (prot_status && prot_status.cloud === true && action.payload) {
        // Is cloud setup so save offline token
        yield call(() => offlineAPI.createOfflineAccessToken(action.payload));
      }
    }
  } catch (e) {
    log.error(e);
    yield put(
      errorActions.showCriticalError(
        i18n.t(
          'msg.unableToPosAppToken',
          'Unable to set POS APP offline mode access token'
        ),
        e
      )
    );
  }
}

export default function* saga() {
  yield all([
    takeLatest(actionTypes.LOGIN_SITE, logIn),
    takeLatest(actionTypes.LOGOUT_SITE, logOut),
    takeLatest(actionTypes.LOGIN_SITE_FULFILLED, refresh_tokens_to_libraries),
    takeEvery(actionTypes.REFRESH_TOKEN, refreshToken),
    takeLatest(actionTypes.LOAD_OFFLINE_TOKEN, load_offline_token),
  ]);
}
