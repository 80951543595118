import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestion,
  faSyncAlt,
  faDoorOpen,
  faEyeSlash,
  faEquals,
  faTintSlash,
  faBan,
  faExclamationCircle,
  faList,
  faSpinner,
  faIndent,
  faTh,
  faTrashAlt,
  faEnvelope,
  faPrint,
  faShoppingCart,
  faHome,
  faDatabase,
  faUserCircle,
  faCog,
  faCaretDown,
  faArrowLeft,
  faMinus,
  faPlus,
  faCaretUp,
  faPencilAlt,
  faMinusCircle,
  faSearch,
  faExternalLinkAlt,
  faDownload,
  faBullhorn,
  faCaretRight,
  faCaretLeft,
  faRunning,
  faTint,
  faExclamationTriangle,
  faTimes,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faCameraRetro,
  faPlay,
  faHeart,
  faCloud,
  faSlash,
  faGlobe,
  faCheckCircle,
  faTimesCircle,
  faEye,
  faLifeRing,
  faPaintRoller,
  faCheck,
  faTrash,
  faDesktop,
  faLock,
  faCalendarTimes,
  faFile,
  faEllipsisH,
  faEllipsisV,
  faShareAlt,
  faUnlock,
  faClockRotateLeft,
  faRightLeft,
  faCircleChevronUp,
  faLinesLeaning,
  faArrowDown,
  faStar,
  faStarHalfStroke,
  faCirclePlus,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

export default function registerIcons() {
  library.add(
    faPaintRoller,
    faQuestion,
    faSyncAlt,
    faDoorOpen,
    faEye,
    faEyeSlash,
    faEquals,
    faTintSlash,
    faBan,
    faExclamationCircle,
    faList,
    faSpinner,
    faIndent,
    faTh,
    faTrashAlt,
    faEnvelope,
    faPrint,
    faShoppingCart,
    faHome,
    faDatabase,
    faUserCircle,
    faCog,
    faCaretDown,
    faArrowLeft,
    faMinus,
    faPlus,
    faCaretUp,
    faPencilAlt,
    faMinusCircle,
    faSearch,
    faExternalLinkAlt,
    faDownload,
    faBullhorn,
    faCaretRight,
    faCaretLeft,
    faRunning,
    faTint,
    faExclamationTriangle,
    faTimes,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faCameraRetro,
    faPlay,
    faHeart,
    faCloud,
    faSlash,
    faGlobe,
    faCheckCircle,
    faTimesCircle,
    faLifeRing,
    faTrash,
    faCheck,
    faDesktop,
    faLock,
    faCalendarTimes,
    faFile,
    faEllipsisH,
    faShareAlt,
    faEllipsisV,
    faUnlock,
    faClockRotateLeft,
    faRightLeft,
    faCircleChevronUp,
    faArrowDown,
    faLinesLeaning,
    faStar,
    faStarHalfStroke,
    faCirclePlus,
    faArrowUpRightFromSquare
  );
}
