// @ts-ignore
import bwipjs from 'bwip-js';

import { computeGTINCheckDigit } from './BarcodeUtils';

const BARCODE_EAN8 = 'ean8';
const BARCODE_EAN13 = 'ean13';
const BARCODE_UPCA = 'upca';

const BARCODELENGTH = {
  [BARCODE_EAN8]: 8,
  [BARCODE_UPCA]: 12,
  [BARCODE_EAN13]: 13,
};

function get_barcode_string(value: string, type: string, prefix: string) {
  prefix = prefix || '';
  let text = ''; // return empty text if error

  // padding and check digit calculation for fixed length types
  // @ts-ignore
  const fixedLength = BARCODELENGTH[type];
  if (fixedLength && value) {
    if (!prefix && value.length === fixedLength) {
      // Value should already have correct check digit, let's check
      const checkDigit = computeGTINCheckDigit(
        value.substring(0, fixedLength - 1)
      );
      if (value.charAt(fixedLength - 1) === checkDigit)
        // correct check digit
        text = value;
    } else {
      // Add prefix, padding and check digit
      const padLength = fixedLength - prefix.length - 1;
      if (padLength >= value.length) {
        // value is short enough
        value = prefix + value.padStart(padLength, '0');
        const checkDigit = computeGTINCheckDigit(value);
        if (checkDigit != null) {
          text = value + checkDigit;
        }
      }
    }
  } else {
    text = prefix + value;
  }
  return text;
}

type TypeEIValue = {
  key: string;
  value: string;
};

export type MailTemplateToHtmlProps = {
  template: string;
  ei_values: TypeEIValue[];
};

export function MailTemplateToHtml(
  template: string,
  ei_values: TypeEIValue[]
): string {
  let temp = `${template}`; //clone the old string

  // Find parts which have barcode fields

  let barcodes = temp.match(/\[\[[A-z0-9\.]*\|[A-z-0-9]*\|[0-9]*\]\]/g) || [];
  try {
    barcodes.forEach((code) => {
      const tmp = code.replace('[[', '').replace(']]', '').split('|');
      const key = tmp[0];
      const btype = tmp[1];
      const size = parseInt(tmp[2]);
      const d = ei_values.find((x: TypeEIValue) => x.key === key);
      try {
        if (d && d.value) {
          const opts = {
            bcid: btype, // Barcode type
            // NOTE: Prefix is not used at the moment!
            text: get_barcode_string(d.value, btype, ''), // Text to encode
            scale: 1, // 3x scaling factor
            width: (size / 72) * 25.4, // Bar widht, in millimeters
            height: (size / 72) * 25.4, // Bar height, in millimeters
            includetext: false, // Show human-readable text
            textxalign: 'center', // Always good to set this
            dontlint: true,
          };
          // let svg = bwipjs.render(opts, drawsvg(opts, bwipjs.FontLib));

          let canvas = document.createElement('canvas');
          bwipjs.toCanvas(canvas, opts);

          const img = `<img src="${canvas.toDataURL(
            'image/png'
          )}" alt='image' width=${size} height=${size} />`;

          temp = temp.replace(code, img);
        } else {
          temp = temp.replace(code, '-No value-');
        }
      } catch (e) {
        console.log(e);
        console.log(d);
        temp = temp.replace(code, '-Error-');
      }
    });
  } catch (e) {
    console.log(e);
  }

  ei_values.forEach((e) => {
    temp = temp.replace(`[${e.key}]`, e.value);
  });
  return temp;
}
