// from py/cps_libs/colour_calc/illum.py
export const ILLUMINANTS10 = [
  // ASTM E 308-96, table 6.19 (D65, 10 nm interval)
  [
    [
      0.103, 0.616, 1.66, 2.377, 3.512, 3.789, 3.103, 1.937, 0.747, 0.11, 0.007,
      0.314, 1.027, 2.174, 3.38, 4.735, 6.081, 7.31, 8.393, 8.603, 8.771, 7.996,
      6.476, 4.635, 3.074, 1.814, 1.031, 0.557, 0.261, 0.114, 0.106,
    ],
    [
      0.01, 0.064, 0.171, 0.283, 0.549, 0.888, 1.277, 1.817, 2.545, 3.164,
      4.309, 5.631, 6.896, 8.136, 8.684, 8.903, 8.614, 7.95, 7.164, 5.945, 5.11,
      4.067, 2.99, 2.02, 1.275, 0.724, 0.407, 0.218, 0.102, 0.044, 0.04,
    ],
    [
      0.459, 2.808, 7.868, 11.703, 17.958, 20.358, 17.861, 13.085, 7.51, 3.743,
      2.003, 1.004, 0.529, 0.271, 0.116, 0.03, -0.003, 0.001, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    ],
  ],
  // table 6.3 (A)
  [
    [
      0.02, 0.118, 0.372, 0.686, 0.982, 1.094, 1.024, 0.747, 0.326, 0.061,
      0.003, 0.189, 0.717, 1.617, 2.823, 4.296, 6.177, 8.285, 10.218, 12.041,
      12.85, 12.441, 10.872, 8.604, 5.951, 3.846, 2.259, 1.242, 0.643, 0.324,
      0.315,
    ],
    [
      0.002, 0.012, 0.038, 0.082, 0.154, 0.255, 0.414, 0.688, 1.073, 1.589,
      2.397, 3.503, 4.857, 6.096, 7.29, 8.116, 8.799, 9.039, 8.758, 8.35, 7.492,
      6.337, 5.025, 3.753, 2.469, 1.537, 0.891, 0.485, 0.25, 0.126, 0.122,
    ],
    [
      0.085, 0.54, 1.76, 3.374, 5.024, 5.876, 5.882, 5.023, 3.236, 1.926, 1.129,
      0.638, 0.377, 0.205, 0.1, 0.028, -0.003, 0.001, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    ],
  ],
  // table 6.35 (F11)
  [
    [
      0.084, 0.196, -0.134, 2.908, 4.426, 1.339, 1.348, 0.657, 0.329, 0.176,
      -0.006, 0.039, 0.015, -1.07, 11.643, 13.374, 0.159, 0.674, 7.362, 9.374,
      4.309, 29.011, 12.93, 3.263, 0.571, 0.473, 0.261, 0.073, 0.036, 0.023,
      0.026,
    ],
    [
      0.01, 0.021, -0.028, 0.381, 0.666, 0.355, 0.566, 0.513, 1.316, 3.206,
      1.464, 0.51, 0.238, -1.951, 27.854, 26.52, -0.66, 0.822, 6.226, 6.653,
      2.597, 14.71, 6.08, 1.353, 0.232, 0.187, 0.103, 0.029, 0.014, 0.009, 0.01,
    ],
    [
      0.376, 0.897, -0.756, 14.502, 22.492, 7.327, 7.783, 4.313, 3.539, 4.053,
      0.581, 0.02, 0.024, 0.005, 0.327, 0.149, -0.023, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    ],
  ],
];

export const ILLUMINANTS20 = [
  // ASTM E 308-96, table 6.20 (D65, 20 nm interval)
  [
    [
      0.059, 2.951, 7.227, 6.578, 1.278, -0.259, 1.951, 6.751, 12.223, 16.779,
      17.793, 13.135, 5.859, 1.901, 0.469, 0.117,
    ],
    [
      0.012, 0.28, 1.042, 2.534, 4.872, 8.438, 14.03, 17.715, 17.407, 14.21,
      10.121, 5.971, 2.399, 0.741, 0.184, 0.045,
    ],
    [
      0.224, 13.768, 36.808, 37.827, 14.226, 3.254, 1.025, 0.184, -0.013, 0.004,
      -0.001, 0.0, 0.0, 0.0, 0.0, 0.0,
    ],
  ],
  // table 6.4 (A)
  [
    [
      -0.009, 0.691, 2.025, 2.158, 0.642, -0.16, 1.284, 5.445, 12.238, 20.755,
      26.325, 22.187, 11.816, 4.221, 1.154, 0.372,
    ],
    [
      0.0, 0.066, 0.285, 0.796, 2.043, 4.63, 9.668, 14.621, 17.766, 17.8,
      15.129, 10.097, 4.858, 1.643, 0.452, 0.144,
    ],
    [
      -0.051, 3.226, 10.278, 12.345, 6.555, 1.966, 0.721, 0.171, -0.013, 0.004,
      -0.001, 0.0, 0.0, 0.0, 0.0, 0.0,
    ],
  ],
  // table 6.36 (F11)
  [
    [
      0.152, 0.414, 8.515, 1.544, 0.319, 1.673, -5.992, 24.601, 4.494, 10.526,
      24.099, 36.033, -4.279, 2.026, -0.397, 0.135,
    ],
    [
      0.021, 0.019, 1.22, 0.977, 1.693, 8.341, -13.547, 55.948, 7.06, 8.885,
      13.702, 17.112, -2.247, 0.952, -0.198, 0.061,
    ],
    [
      0.727, 1.729, 43.348, 9.002, 7.47, 3.484, -0.739, 0.625, -0.051, 0.014,
      -0.004, 0.001, 0.0, 0.0, 0.0, 0.0,
    ],
  ],
];

export const WHITEPOINTS = [
  [94.811, 100.0, 107.304], // D65
  [111.144, 100.0, 35.2], // A
  [103.863, 100.0, 65.607],
]; // F11

export const WHITEPOINTS1931 = [
  [95.047, 100.0, 108.883], // D65
  [109.85, 100.0, 35.585], // A
  [100.962, 100.0, 64.35],
]; // F11

export const WHITEPOINT_D50_1931 = [96.4211994, 100.0, 82.5188285];

export const ILLUNAMES = ['D65', 'A', 'F11'];

export const ILLUMAP = { D65: 0, A: 1, F11: 2 };

export const ILLUCOUNT = WHITEPOINTS.length;
