import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Axios from 'axios';
import { getToken } from 'js/api/WebRequest';
import { CustomScrollbars } from 'js/mylib/Utils';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { sortCarret } from './Utils';

const propTypes = {
  className: PropTypes.string,
  i18n: PropTypes.object,
  forceUpdate: PropTypes.func.isRequired,
  siteid: PropTypes.number,
  machines: PropTypes.object,
};

/**
 * Pagination options
 */

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <div className="btn-group" role="group">
    {options.map((option) => {
      const isSelect = currSizePerPage === `${option.page}`;
      return (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${isSelect ? 'btn-secondary' : 'btn-secondary'}`}
          style={{ height: '3rem' }}
        >
          {option.text}
        </button>
      );
    })}
  </div>
);
sizePerPageRenderer.propTypes = {
  options: PropTypes.object,
  currSizePerPage: PropTypes.number,
  onSizePerPageChange: PropTypes.func,
};

const pagination_options = {
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: '10',
      value: 10,
    },
    {
      text: '20',
      value: 20,
    },
  ],

  showTotal: true,
};

const defaultProps = {};

const SINGLE = 'S';
const MULTI = 'M';

class TranslateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      key: '',
      toTranslate: '',
      previous: '',
      translated: '',
      modalType: SINGLE,
      search: '',
      data: undefined,
      english: undefined,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  };

  findKey = (txt) => {
    const { i18n, machines } = this.props;
    const data = i18n.getDataByLanguage(i18n.language).translations;
    const english = i18n.getDataByLanguage('en').translations;

    for (let prop in data) {
      if (data[prop] === txt) {
        return [prop, english[prop]];
      }
    }
    // If no key is found try to search from English keys
    for (let prop in english) {
      if (english[prop] === txt) {
        return [prop, txt];
      }
    }

    // If no key is found try to search from tinting machine state...
    for (let { machine_state } of Object.values(machines)) {
      if (machine_state?.msgText === txt) {
        let msgid = machine_state.msgID;
        if (machine_state.error) {
          return ['machine.error.' + msgid, txt];
        } else {
          return ['machine.msg.' + msgid, txt];
        }
      }
    }
    return [];
  };

  showModal = (txt) => {
    const [key, original] = this.findKey(txt);

    // If key was not found show multi modal instead...
    if (!key) {
      this.setState({ search: txt });
      this.showMultiModal();
    } else {
      this.setState({
        show: true,
        toTranslate: original,
        previous: txt,
        key: key,
        translated: txt,
        modalType: SINGLE,
        search: '',
      });
    }
  };

  showMultiModal = () => {
    // Reload transalations
    const { i18n } = this.props;
    const data = i18n.getDataByLanguage(i18n.language).translations;
    const english = i18n.getDataByLanguage('en').translations;

    let multi_data = [];

    for (var prop in english) {
      multi_data.push({
        key: prop,
        original: english[prop],
        translated: data[prop],
      });
    }

    this.setState({
      show: true,
      modalType: MULTI,
      data: data,
      english: english,
      multi_data: multi_data,
    });
  };

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.simpleModal);
    document.removeEventListener('keyup', this.multiModalKeyEvent);
  }

  simpleModal = (e) => {
    try {
      e = e || window.event;
      let target = e.target || e.srcElement;
      let text;
      if (target.firstChild !== null) {
        text = target.firstChild.nodeValue;
      } else {
        text = target.innerText || target.textContent || target.placeholder;
      }
      if (
        e.shiftKey &&
        !this.state.show &&
        text !== null &&
        text !== undefined
      ) {
        e.preventDefault();
        this.showModal(text.trim());
      }
    } catch (e) {
      alert(e);
    }
  };

  multiModalKeyEvent = (e) => {
    if (e.shiftKey && e.key === 'F9') {
      e.preventDefault();
      this.showMultiModal();
    }
  };

  componentDidMount() {
    document.addEventListener('contextmenu', this.simpleModal, false);

    document.addEventListener('keyup', this.multiModal, false);
  }

  /**
   * Saving translation changes
   */
  save = () => {
    const { i18n } = this.props;

    /*i18n.addResourceBundle(i18n.language, 'translations', {
      [this.state.toTranslate] : this.state.translated,
    }, true, true);
    */
    this.setState({ show: false });

    // Triggers update for react to show the changed translation

    let config = {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    };

    let propos = {
      [this.state.key]: {
        language: i18n.language,
        proposal: this.state.translated,
      },
    };

    if (this.state.modalType === MULTI) {
      let obj = this.state.multi_data.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.key]: {
              language: i18n.language,
              proposal: item.translated,
            },
          }),
        {}
      );

      propos = obj;
    }

    i18n.options.backend.loadPath =
      '/site/' + this.props.siteid + '/translations/{{lng}}/{{ns}}';

    // Only send changed data
    const original = i18n.getDataByLanguage(i18n.language).translations;
    let payload = {};
    for (let prop in propos) {
      if (original[prop] !== propos[prop].proposal) {
        Object.assign(payload, { [prop]: propos[prop] });
      }
    }

    Axios.post('/translations/propose', payload, config)
      .then(() => {
        i18n.reloadResources([i18n.language]).then(() => {
          i18n.changeLanguage(i18n.language);
        });
        alert('Translations proposals saved');
      })
      .catch((e) => {
        alert(e.stack);
      });
  };

  singleModal = () => {
    return (
      <ModalBody>
        <FormGroup row>
          <Label for="key" sm={4}>
            Translation key:
          </Label>
          <Col sm={8}>
            <Input
              type="textarea"
              name="key"
              id="key"
              placeholder="-No key found-"
              readOnly
              value={this.state.key}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="original" sm={4}>
            Original:
          </Label>
          <Col sm={8}>
            <Input
              type="textarea"
              name="email"
              id="original"
              placeholder="with a placeholder"
              readOnly
              value={this.state.toTranslate}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="previous" sm={4}>
            Previous:
          </Label>
          <Col sm={8}>
            <Input
              type="textarea"
              name="email"
              id="previous"
              placeholder="with a placeholder"
              readOnly
              value={this.state.previous}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="translated" sm={4}>
            Translation:
          </Label>
          <Col sm={8}>
            <Input
              type="textarea"
              name="password"
              id="translated"
              placeholder="Give in translation"
              value={this.state.translated}
              onChange={(e) => this.setState({ translated: e.target.value })}
            />
          </Col>
        </FormGroup>
        <Button onClick={this.showMultiModal}> Show all translations</Button>
      </ModalBody>
    );
  };

  updateState = (e) => {
    let value = e.target.value;
    let key = e.target.id;
    let data = this.state.multi_data;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['key'] === key) {
        data[i]['translated'] = value;
        break;
      }
    }
    this.setState({ multi_data: data });
  };

  singleGoogleTranslate = (e) => {
    const { english } = this.state;
    const { i18n } = this.props;
    let key = e.target.getAttribute('data-key');
    e.target.innerHTML = '...';
    let target = e.target;

    Axios.get(
      'https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=' +
        i18n.language +
        '&dt=t&q=' +
        english[key]
    )
      .then((r) => {
        let data = this.state.multi_data;
        for (let i = 0; i < data.length; i++) {
          if (data[i]['key'] === key) {
            data[i]['translated'] = r.data[0][0][0];
            break;
          }
        }
        this.setState({ multi_data: data });
        target.innerHTML = 'G';
      })
      .catch((e) => {
        alert(e.stack);
        target.innerHTML = 'G';
      });
  };

  originalFormatter = (cell, row) => {
    //return JSON.stringify(row);

    return (
      <div>
        {row.original}
        <div className="font-italic" style={{ fontSize: 'x-small' }}>
          {row.key}
        </div>
      </div>
    );
  };

  translateFormatter = (cell, row) => {
    //return JSON.stringify(row);

    return (
      <FormGroup row key={row.key} style={{ margin: '0' }}>
        <Col sm={10} style={{ padding: 0 }}>
          <Input
            type="textarea"
            id={row.key}
            placeholder="Give in translation"
            defaultValue={row.translated}
            onBlur={this.updateState}
          />
        </Col>
        <Col sm={2} style={{ padding: 0 }}>
          <Button
            style={{ padding: 0 }}
            onClick={this.singleGoogleTranslate}
            data-key={row.key}
          >
            G
          </Button>
        </Col>
      </FormGroup>
    );
  };

  multiModal = () => {
    const columns = [
      {
        dataField: 'original',

        text: 'Original',
        sortCaret: sortCarret,
        sort: true,
        filter: textFilter(),
        formatter: this.originalFormatter,
        headerStyle: () => {
          return { width: '35%' };
        },
      },
      {
        dataField: 'translated',
        text: 'Translated',
        sortCaret: sortCarret,
        sort: true,
        filter: textFilter({ defaultValue: this.state.search }),
        formatter: this.translateFormatter,
      },
    ];

    return (
      <ModalBody>
        <CustomScrollbars style={{ height: '450px' }}>
          <BootstrapTable
            keyField="key"
            striped
            hover
            data={this.state.multi_data}
            columns={columns}
            pagination={paginationFactory(pagination_options)}
            filter={filterFactory()}
          />
        </CustomScrollbars>
      </ModalBody>
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.state.show}
        toggle={this.toggle}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.toggle}>Translate text</ModalHeader>

        {this.state.modalType === SINGLE
          ? this.singleModal()
          : this.multiModal()}
        <ModalFooter>
          <Row style={{ width: '100%' }}>
            <Col>
              <Button
                color="secondary"
                data-cy={'fn.cancel_translation'}
                onClick={this.toggle}
              >
                Cancel
              </Button>
            </Col>
            <Col />
            <Col>
              <Button
                color="primary"
                data-cy={'fn.save_translation'}
                onClick={this.save}
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

TranslateModal.propTypes = propTypes;
TranslateModal.defaultProps = defaultProps;

export default withTranslation('translations')(TranslateModal);
