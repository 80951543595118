import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  MACHINE_STATE_DISPENSE,
  MACHINE_STATE_GRAVIMETRIC_DISPENSING,
  BARCODE_PRODUCT,
  BARCODE_MODE_DISPENSE,
  ORDER_MODE_NORMAL,
  ORDER_MODE_FREE_DISPENSE,
  MACHINE_HIDE_MESSAGES,
  MACHINE_MSG_ID_OPEN_REFILL_PANEL,
  MACHINE_ACTION_OK,
  MACHINE_ACTION_CONTINUE,
  MACHINE_ACTION_RETRY,
  MACHINE_ACTION_CANCEL,
  MACHINE_FLUID_MSG_ID_REMOVE_CAN,
} from '../../Constants';
import machineActions, {
  selectors as machineSelectors,
} from 'js/redux/reducers/Machine';
import { selectors as cacheSelectors } from 'js/redux/reducers/Cache';
import barcodeActions from 'js/redux/reducers/BarcodeAction';
import { selectors as protectionSelectors } from 'js/redux/reducers/Protection';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { RGBColorToHex, rightToLeft } from 'js/mylib/Utils';
import ColorThumbnail from 'js/components/shared/ColorThumbnail';
import Dispense from './graphics/Dispense';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MachineColorants from './machinePage/canisters/MachineColorants';
import SlidingPane from 'react-sliding-pane';
import orderActions, { cnts_to_tint } from '../../redux/reducers/Order';
import { getColorantWarn } from '../../mylib/Utils';
import { createLevelFormatter } from '../../redux/selectors/Formatters';
import { hasPrivilege } from '../../mylib/Privileges';
import TextFit from '../shared/TextFit';

const propTypes = {
  machine: PropTypes.shape(),
  dispID: PropTypes.string.isRequired,
  drop_colors: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  cnts: PropTypes.array,
  machineAction: PropTypes.func.isRequired,
  fetchMachineState: PropTypes.func.isRequired,
  order: PropTypes.shape({
    engine_check: PropTypes.array,
    can: PropTypes.shape(),
  }),
  config_values: PropTypes.object.isRequired,
  is_pro: PropTypes.bool,
  barcodeAction: PropTypes.shape({
    barcode: PropTypes.string,
    show_barcode_blocked_during_dispense_modal: PropTypes.bool,
    canbarcodevalid: PropTypes.bool.isRequired,
    barcodemode: PropTypes.string,
    barcodeaction: PropTypes.shape({
      action: PropTypes.string,
    }),
  }),
  setShowBarcodeWarningModal: PropTypes.func,
  setCan: PropTypes.func.isRequired,
  setMachineColorantsQT: PropTypes.func.isRequired,
  fetchMachineColorants: PropTypes.func.isRequired,
  stir: PropTypes.func.isRequired,
  cap: PropTypes.func,
  purge: PropTypes.func.isRequired,
  setDropColors: PropTypes.func.isRequired,
  privileges: PropTypes.arrayOf(PropTypes.string).isRequired,
  navigateTo: PropTypes.func,
  moveToFill: PropTypes.func,
  moveToHome: PropTypes.func,
  setMachineSlideOverVisible: PropTypes.func.isRequired,
  slideOverVisible: PropTypes.bool,
  clearBarcodeActions: PropTypes.func,
  fetchRefillsList: PropTypes.func,
  fetchBatchRefillsList: PropTypes.func,
  setMachineRefillsListSaga: PropTypes.func,
  setMachineRefillsBatchListSaga: PropTypes.func,
  stirAll: PropTypes.func,
  createLevelFormatter: PropTypes.func,
  setMachineBarcodeRefillAction: PropTypes.func,
  cache: PropTypes.shape(),
  current_user: PropTypes.shape({
    privileges: PropTypes.arrayOf(PropTypes.string),
  }),
  refills_list: PropTypes.array,
  refills_batch_list: PropTypes.array,
  shotFormatter: PropTypes.object.isRequired,
};

const defaultProps = {
  machine: {},
};

const DISPENSE_INSTRUCTIONS_BOX_ID = 'dispense_instructions_box';

class MachineBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verify: false,
      selected_cntcode: null,
      barcode_warning: false,
      progressColorants: [],
      doneColorants: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Reset progress if it's not available
    if (!props.machine?.machine_state?.progress) {
      return { progressColorants: [], doneColorants: [] };
    }

    // Handle case when remove can message is shown.
    if (
      props.machine?.machine_state?.msgID === MACHINE_FLUID_MSG_ID_REMOVE_CAN
    ) {
      return {
        doneColorants: _.union(state.doneColorants, state.progressColorants),
      };
    }
    // Add colorant codes to state.doneColorants when they disappears
    // from machine_state.progress.colorantItems
    const progressColorants =
      props.machine?.machine_state?.progress?.colorantItems || [];
    if (progressColorants.length || state.progressColorants.length) {
      const doneJustNow = _.difference(
        state.progressColorants,
        progressColorants
      );
      return {
        progressColorants,
        doneColorants: _.union(state.doneColorants, doneJustNow),
      };
    }
    return null;
  }

  openMachinePopup = (cntcode) => {
    this.setState({
      selected_cntcode: cntcode,
    });
    this.props.setMachineSlideOverVisible(this.props.dispID, true);
  };

  componentDidMount() {
    // Reload machine state for machine 0
    this.props.fetchMachineState(this.props.dispID);
  }

  answerQuestion = (ans) => {
    this.props.machineAction(this.props.dispID, ans);
    if (ans === MACHINE_ACTION_CANCEL) {
      this.props.navigateTo('/');
    }
  };

  barcodeAnswer = (ans) => {
    if (ans === MACHINE_ACTION_CANCEL) {
      // Cancelling the tint
      this.props.machineAction(this.props.dispID, ans);
      this.props.navigateTo('/');
    } else if (ans === MACHINE_ACTION_CONTINUE) {
      this.setState({ verify: true });
    }
  };

  getMachineText = (mstate) => {
    const { t, barcodeAction, config_values, is_pro, order } = this.props;
    // Check machine errors first!
    /**
     *if (mstate && mstate.error) {
     *  return JSON.stringify(mstate);
     *}
     * */

    const is_custom_can = _.get(order, 'can.canid', undefined) === null;
    const barcode_check_can =
      config_values.barcode_can_check_before_dispensing && !is_custom_can;
    const barcodes_enabled = is_pro && config_values.barcode_action_enable;

    const order_mode = _.get(order, 'order_mode', ORDER_MODE_NORMAL);

    if (
      barcodes_enabled &&
      barcode_check_can &&
      order_mode !== ORDER_MODE_FREE_DISPENSE
    ) {
      if (barcodeAction.barcode) {
        if (!barcodeAction.canbarcodevalid) {
          if (
            barcodeAction.barcodemode === BARCODE_MODE_DISPENSE &&
            barcodeAction?.barcodeaction?.action !== BARCODE_PRODUCT
          ) {
            return (
              <>
                <Modal
                  isOpen={
                    barcodeAction.show_barcode_blocked_during_dispense_modal
                  }
                  centered
                >
                  <ModalHeader>{t('lbl.warning', 'Warning')}</ModalHeader>
                  <ModalBody>
                    {t(
                      'msg.barcodeActionNotAllowed',
                      'This barcode action is not allowed in this stage'
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={() =>
                        this.props.setShowBarcodeWarningModal(false)
                      }
                    >
                      {t('fn.ok', 'Ok')}
                    </Button>
                  </ModalFooter>
                </Modal>
                <div>{t('msg.incorrectBarcode')}</div>
              </>
            );
          }
          return <div>{t('msg.incorrectBarcode')}</div>;
        } else {
          const verify =
            config_values.barcode_can_check_before_dispensing_confirmation;
          if (verify && !this.state.verify) {
            return <div>{t('msg.correctBarcode')}</div>;
          }
        }
      } else {
        return <div>{t('msg.scanPaintBarcode')}</div>;
      }
    }

    if (mstate.msgID) {
      if (MACHINE_HIDE_MESSAGES.includes(mstate.msgID)) {
        return '';
      }
      if (mstate.msgID === 2 && mstate.orderItem) {
        // Ask for inserting can
        return (
          <div>
            <p className="m-0">
              {t('msg.insertCanAndConfirm.insert', 'Insert')}
            </p>
            <table className="table-machine">
              <tbody>
                <tr>
                  <td>{t('lbl.product.colon', 'Product:')}</td>
                  <td>{mstate.orderItem.productName}</td>
                </tr>
                <tr>
                  <td>{t('lbl.base.colon', 'Base:')}</td>
                  <td>{mstate.orderItem.formula.base}</td>
                </tr>
                <tr>
                  <td>{t('lbl.can.colon', 'Can:')}</td>
                  <td>{mstate.orderItem.can.name}</td>
                </tr>
              </tbody>
            </table>
            <p className="m-0">
              {t('msg.insertCanAndConfirm.andConfirm', 'and confirm')}
            </p>
          </div>
        );
      }
      return (
        <TextFit
          containerId={DISPENSE_INSTRUCTIONS_BOX_ID}
          text={t('machine.msg.' + String(mstate.msgID), {
            ...mstate.msgParams,
            defaultValue: mstate.msgText,
          })}
        />
      );
    }
  };

  getMachineButtons = (mstate) => {
    const { t } = this.props;
    if (mstate) {
      if (MACHINE_HIDE_MESSAGES.includes(mstate.msgID)) {
        return null;
      }
      if (mstate.msgText) {
        return mstate.msgButtons?.map((name, i) => (
          <Button
            data-denied={!hasPrivilege('order_tinting_process')}
            disabled={!hasPrivilege('order_tinting_process')}
            id={'action' + _.startCase(_.toLower(name))}
            autoFocus={i === 0}
            color={
              name === MACHINE_ACTION_OK ||
              name === MACHINE_ACTION_CONTINUE ||
              name === MACHINE_ACTION_RETRY
                ? 'primary'
                : 'warning'
            }
            key={name}
            onClick={() => {
              if (mstate.barcode_scanning) {
                this.barcodeAnswer(name);
              } else {
                this.answerQuestion(name);
              }
            }}
          >
            {t('machine.fn.' + name)}
          </Button>
        ));
      }
    }
  };

  getMachineState = () => {
    const { config_values, is_pro, machine, barcodeAction, t, order } =
      this.props;
    let mstate = machine.machine_state || {};
    const is_custom_can = _.get(order, 'can.canid', undefined) === null;
    const barcode_check_can =
      config_values.barcode_can_check_before_dispensing && !is_custom_can;
    const order_mode = _.get(order, 'order_mode', ORDER_MODE_NORMAL);
    const barcodes_enabled = is_pro && config_values.barcode_action_enable;

    if (
      barcode_check_can &&
      barcodes_enabled &&
      order_mode !== ORDER_MODE_FREE_DISPENSE
    ) {
      if (barcodeAction.barcode) {
        if (!barcodeAction.canbarcodevalid) {
          mstate = {
            msgText: t('msg.incorrectBarcode'),
            msgButtons: [MACHINE_ACTION_CANCEL],
            barcode_scanning: true,
            msgID: mstate.msgID,
          };
        } else {
          const verify =
            config_values.barcode_can_check_before_dispensing_confirmation;
          if (verify && !this.state.verify) {
            mstate = {
              msgText: t('msg.correctBarcode'),
              msgButtons: [MACHINE_ACTION_CONTINUE, MACHINE_ACTION_CANCEL],
              barcode_scanning: true,
              msgID: mstate.msgID,
            };
          }
        }
      } else {
        mstate = {
          msgText: t('msg.scanPaintBarcode'),
          msgButtons: [MACHINE_ACTION_CANCEL],
          barcode_scanning: true,
          msgID: mstate.msgID,
        };
      }
    }

    return mstate;
  };

  check_formula_split_refill = () => {
    const { machine } = this.props;
    let mstate = machine.machine_state || {};

    if (mstate.msgID === MACHINE_MSG_ID_OPEN_REFILL_PANEL) {
      // Ok to answer back to machine
      this.answerQuestion(mstate.msgButtons[0]);
    }
  };

  getMachineSlideOver = () => {
    const { t, machine, order, slideOverVisible, setMachineSlideOverVisible } =
      this.props;

    const cnts = order.engine_check;
    let cnt_with_lowest_volume = null;
    if (cnts) {
      const machineCnts = cnts
        .map((cnt) =>
          machine.colorants.find((mcnt) => mcnt?.code === cnt.cntcode)
        )
        .filter(Boolean);
      if (machineCnts.length)
        cnt_with_lowest_volume = machineCnts.reduce((prev, current) => {
          return prev.currLevel <= current.currLevel ? prev : current;
        });
    }
    return (
      <SlidingPane
        className="ml-16 mr-16"
        closeIcon={<FontAwesomeIcon icon="times" color="red" />}
        isOpen={slideOverVisible}
        title={t('lbl.machineColorants', 'Machine colorants')}
        from="bottom"
        width="calc(100%-2rem)"
        onRequestClose={() => {
          setMachineSlideOverVisible(this.props.dispID, false);
          this.props.setCan(order.can);
          this.check_formula_split_refill();
        }}
      >
        <MachineColorants
          t={t}
          machine={machine}
          selectedCnt={
            /* If refill is required during a process (e.g. tint process for split formula dispensing), then select canister with the lowest current level */
            this.state.selected_cntcode ||
            (machine.machine_state?.currentProcess !== null && window.qtside)
              ? cnt_with_lowest_volume?.code
              : null
          }
          fetchMachineColorants={this.props.fetchMachineColorants}
          moveToFill={this.props.moveToFill}
          moveToHome={this.props.moveToHome}
          dispID={this.props.dispID}
          setMachineColorantsQT={this.props.setMachineColorantsQT}
          stir={this.props.stir}
          purge={this.props.purge}
          setDropColors={this.props.setDropColors}
          formula={cnts}
          privileges={this.props.privileges}
          config_values={this.props.config_values}
          current_user={this.props.current_user}
          barcode_canister_action={machine.barcode_canister_action}
          clearBarcodeActions={this.props.clearBarcodeActions}
          fetchRefillsList={this.props.fetchRefillsList}
          fetchRefillsBatchList={this.props.fetchBatchRefillsList}
          refills_list={this.props.refills_list}
          refills_batch_list={this.props.refills_batch_list}
          setMachineRefillsListSaga={this.props.setMachineRefillsListSaga}
          setMachineRefillsBatchListSaga={
            this.props.setMachineRefillsBatchListSaga
          }
          stirAll={this.props.stirAll}
          createLevelFormatter={this.props.createLevelFormatter}
          cache={this.props.cache}
          setMachineBarcodeRefillAction={
            this.props.setMachineBarcodeRefillAction
          }
        />
      </SlidingPane>
    );
  };

  render() {
    const mstate = this.getMachineState();

    const { machine, cnts, shotFormatter, config_values } = this.props;
    const show_formula_amounts = !config_values?.hide_formula_volumes;
    let dispensing = false;
    if (mstate) {
      dispensing =
        (mstate.state === MACHINE_STATE_DISPENSE ||
          mstate.state === MACHINE_STATE_GRAVIMETRIC_DISPENSING) &&
        mstate.msgID !== MACHINE_FLUID_MSG_ID_REMOVE_CAN;
    }
    const formula_splitting = _.get(machine, 'config.formula_splitting', false);

    let frm = null;
    let frm_tooltip = null;
    const tint_cnts = cnts_to_tint(this.props.order, true);
    if (tint_cnts) {
      frm = tint_cnts.map((cnt) => (
        <Row
          className={rightToLeft() ? 'pr-2' : 'pl-2'}
          key={cnt.cntid + cnt.cntcode}
          onClick={() => this.openMachinePopup(cnt.cntcode)}
        >
          <Col
            xs={3}
            style={{ display: 'flex', alignItems: 'center' }}
            className={'m-0 p-0'}
          >
            <ColorThumbnail rgb={cnt.rgb} size="1rem" />
          </Col>
          {this.state.doneColorants.includes(cnt.cntcode) && (
            <Col xs={3} className={'m-0 p-0'}>
              <FontAwesomeIcon icon="check" size="1rem" />
            </Col>
          )}

          <Col>
            <div
              className={'ml-1 m-0 p-0'}
              style={{ fontSize: '0.85rem', overflow: 'hidden' }}
              data-testid={'cnt_' + cnt.cntcode}
            >
              {show_formula_amounts
                ? shotFormatter.format({
                    volume: cnt.volume,
                    specificgravity: cnt.specificgravity,
                  })
                : cnt.cntcode}
            </div>
          </Col>

          <Col>{getColorantWarn(cnt.warn, formula_splitting)}</Col>
        </Row>
      ));
      frm_tooltip = tint_cnts.map((cnt) => cnt.cntcode).join('\n');
    }
    const progressColors =
      machine.info && machine.info.progressSupported && mstate.progress
        ? mstate.progress.colorantItems.map(
            (clr) =>
              cnts.find((cnt) => cnt.cntcode === clr) &&
              RGBColorToHex(cnts.find((cnt) => cnt.cntcode === clr).rgb)
          )
        : this.props.drop_colors;

    return (
      <>
        <Col md="9" className="machine-box">
          {mstate && mstate.error && (
            <FontAwesomeIcon
              icon="exclamation-triangle"
              className="machine-error-icon"
            />
          )}

          <div className="card d-flex flex-row align-items-stretch justify-content-center align-content-center">
            <div
              id="frm_div"
              className="col-md-2 d-flex flex-column justify-content-center align-content-center dispense-machineActions-container pointer-cursor"
              style={
                rightToLeft()
                  ? { paddingLeft: 0, paddingRight: '1.2rem' }
                  : { paddingRight: 0, paddingLeft: '1.2rem' }
              }
            >
              {frm}
            </div>
            {frm_tooltip && (
              <UncontrolledTooltip placement="right" target="frm_div">
                <p style={{ whiteSpace: 'pre-line' }}>{frm_tooltip}</p>
              </UncontrolledTooltip>
            )}
            <div className="col-md-8 dispense-instructions-container">
              <div
                className="card-img-overlay d-flex flex-row flex-grow-1 justify-content-center align-items-center align-content-center"
                id={DISPENSE_INSTRUCTIONS_BOX_ID}
                style={{
                  position: 'relative',
                  top: 'auto',
                  bottom: 'auto',
                  right: 'auto',
                  left: 'auto',
                }}
              >
                <div className="text-center d-flex flex-column flex-row flex-grow-1 justify-content-center align-items-center align-content-center">
                  {this.getMachineText(mstate)}
                  {machine.info &&
                  machine.info.progressSupported &&
                  mstate.progress
                    ? mstate.progress.currentTask === 'dispensing' &&
                      mstate.progress.colorantItems.map((clr) => {
                        const decimals = mstate.progress.isGravimetric
                          ? mstate.progress.scaleResolution ?? 2
                          : 2;
                        return (
                          <>
                            <p key={clr}>
                              {clr} -{' '}
                              {mstate.progress.colorantAmounts
                                .find((colorant) => colorant.code === clr)
                                ?.volume.toFixed(decimals) || '0.00'}
                              {mstate.progress.isGravimetric ? ' g' : ' ml'}
                            </p>
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
            <div className="col-md-2 d-flex flex-column justify-content-between dispense-animation-container">
              <div style={{ marginLeft: '-8px' }}>
                <Dispense
                  height={16 * 12} // 13 rem height!
                  dispensing={dispensing}
                  progress={
                    machine.info &&
                    machine.info.progressSupported &&
                    mstate.progress
                      ? true
                      : false
                  }
                  color={progressColors}
                />
              </div>

              {/* <img alt='toptint' className="img-fluid d-block float-none" src={machineTintingTop}    style={{margin:'0 auto',maxWidth:'100%', width:'100%', maxHeight:'6rem', height:'auto'}}/>
              <div style={{margin:'0 auto',maxWidth:'100%',  maxHeight:'45px', display:'flex', overflow:'hidden'}}>
                {dispensing && <Icon fill="#3F00f4" />}
                {!window.qtside && <Icon fill="#3F00f4" />}
              </div>
              <img alt='dowtint' className="img-fluid d-block float-none" src={machineTintingBottom}    style={{margin:'0 auto',maxWidth:'30%', width:'100%', maxHeight:'6rem', height:'auto'}}/>
               */}
            </div>
          </div>
        </Col>
        <Col
          md="3"
          className={
            'info-buttons dispense-buttons ' +
            (rightToLeft() ? 'pr-16' : 'pl-16')
          }
        >
          {this.getMachineButtons(mstate)}
        </Col>
        {this.getMachineSlideOver()}
      </>
    );
  }
}

MachineBanner.propTypes = propTypes;
MachineBanner.defaultProps = defaultProps;

function mapStateToProps(state, ownProps) {
  return {
    machine: machineSelectors.machine(state, ownProps.dispID),
    cnts: cacheSelectors.cnts(state),
    drop_colors: machineSelectors.drop_colors(state),
    order: state.order,
    config_values: state.configurations.config_values,
    is_pro: protectionSelectors.is_pro(state),
    barcodeAction: state.barcodeaction,
    privileges: state.user.current_user.privileges,
    createLevelFormatter: createLevelFormatter(state),
    slideOverVisible: machineSelectors.slideOverVisible(state, ownProps.dispID),
    shotFormatter: state.configurations.shotFormatter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      machineAction: machineActions.machineAction,
      fetchMachineState: machineActions.fetchMachineState,
      setMachineSlideOverVisible: machineActions.setMachineSlideOverVisible,
      setCan: orderActions.setCan,
      fetchMachineColorants: machineActions.fetchMachineColorants,
      setMachineColorantsQT: machineActions.setMachineColorantsQT,
      moveToFill: machineActions.moveToFill,
      moveToHome: machineActions.moveToHome,
      stir: machineActions.stir,
      purge: machineActions.purge,
      setDropColors: machineActions.setDropColors,
      navigateTo: push,
      fetchMachineError: machineActions.fetchMachineError,
      fetchRefillsList: machineActions.fetchMachineRefillsList,
      fetchBatchRefillsList: machineActions.fetchMachineBatchRefillsList,
      clearBarcodeActions: machineActions.clearBarcodeActions,
      setMachineBarcodeRefillAction:
        machineActions.setMachineBarcodeRefillAction,
      stirAll: machineActions.stirAll,
      setMachineRefillsListSaga: machineActions.setMachineRefillsListSaga,
      setMachineRefillsBatchListSaga:
        machineActions.setMachineRefillsBatchListSaga,
      setShowBarcodeWarningModal: barcodeActions.setShowBarcodeWarningModal,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(MachineBanner)
);
