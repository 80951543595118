export const EXAMPLE_ORDERITEM_STATE = {
  order: {
    site: {
      address1: 'Valokaari 6',
      address2: null,
      companyname: 'Stark',
      computerid: null,
      country: 'AU',
      databasename: null,
      email: null,
      latitude: 60.462634,
      longitude: 22.280273,
      phone: 'n',
      postcode: '00750',
      replicationgroup: '_ONLY_SITE_0',
      replonewayformulas: false,
      replonewaysales: false,
      replschedule: null,
      repltype: null,
      sitecomputerid: null,
      sitedescription: null,
      siteid: 0,
      sitelicense: null,
      sitename: 'First testing site',
      timezone: null,
      website: null,
      zoneid: 1,
    },
    orderid: 57285,
    zoneid: 1,
    orderdate: '2020-01-16T06:27:09+00:00',
    modificationdate: '2020-01-16T06:45:01+00:00',
    color: {
      altcolourcode: 'NOVA K016',
      colourcode: 'NOVA K016',
      colourid: 1796,
      colournames: ['GREAT BARRIER REEF'],
      colourcardids: [1],
      rgb: 1414345,
    },
    product: {
      blendedbase: null,
      description:
        'Basic exterior use three base product with excellent colour space coverage and good hiding power.',
      extenderid: null,
      has_matching: true,
      infopage: 'http://www.chromaflo.com/en-US/Technical-Data-Sheets.aspx',
      prodimageid: 1,
      prodposition: 9999,
      productid: 1,
      producttype: null,
      productzname: 'Exterior 2401 AABC',
      systemid: 1,
      usepcomment: false,
      tintexpiry: 6,
    },
    base: {
      basecode: 'C',
      baseid: 3,
      coefficient: 1,
      gravimetricfill: false,
      maxfill: 0.1,
      minfill: null,
      nominalfill: 0.9,
      specificgravity: 1,
    },
    formula: {
      baseid: 3,
      fcomment: 'NC',
      formulaid: 718,
      pcomment: 'Not under 4l can',
      commentnotes: 'Not Controlled',
    },
    card: { cardname: 'Monicolor Nova 2024' },
    customer: {
      country: 'FI',
      customernotes: '',
      address1: 'Street name',
      siteid: 0,
      companyname: 'Chromaflo',
      customername: 'Petri Luukkonen',
      customerid: 4704,
      email: 'noreply@chromaflo.com',
      customermoddate: '2020-01-07T12:17:51+00:00',
      postcode: '50180',
      phone: '+358503698521',
      discountrate: 0.1,
      marketing: false,
      address2: 'Joensuu',
      consent: null,
    },
    customerid: 4704,
    can: {
      cansizecode: '1 l',
      cansizeid: 3,
      imageid: null,
      nominalamount: 1000,
      gravimetric: false,
      baseid: 3,
      canid: 10,
      canshapeid: null,
      defbarcode: '649990007',
      cancode: 'ABC-123456-DEF',
      fill: 1,
      basevolume: 1000,
    },
    notes: null,

    item: {
      itemid: null,
      colourcode: 'NOVA K016',
      colourname: 'GREAT BARRIER REEF',
      rgb: 1414345,
      cardname: 'Monicolor Nova 2024',
      productname: 'Exterior 2401 AABC',
      basecode: 'C',
      cansizecode: '1 l',
      ncans: 1,
      cnts: [
        {
          altcode: null,
          bfsgrade: '1',
          cleaninginterval: null,
          cleaningthreshold: null,
          cntcode: 'FT',
          cntid: 3,
          description: 'Alternate 9 colorant',
          rgb: 4194432,
          specificgravity: 1.33,
          volume: 10.950756309461,
        },
        {
          altcode: null,
          bfsgrade: '1',
          cleaninginterval: null,
          cleaningthreshold: null,
          cntcode: 'TT',
          cntid: 14,
          description: 'Alternate 9 colorant',
          rgb: 0,
          specificgravity: 1.29,
          volume: 17.794978886470002,
        },
        {
          altcode: null,
          bfsgrade: '1',
          cleaninginterval: null,
          cleaningthreshold: null,
          cntcode: 'VT',
          cntid: 16,
          description: 'Alternate 9 colorant',
          rgb: 12337168,
          specificgravity: 1.85,
          volume: 8.213067464903,
        },
        {
          altcode: null,
          bfsgrade: '1',
          cleaninginterval: null,
          cleaningthreshold: null,
          cntcode: 'XT',
          cntid: 17,
          description: 'Alternate 9 colorant',
          rgb: 16777215,
          specificgravity: 2.09,
          volume: 29.430158105493,
        },
      ],
      notes: null,
      price: {
        analysis: {
          base: 0,
          cnt: 0.7083513270544595,
          hardener: 0,
          margin: 0.10797520355778532,
          tax: 0.1836734693877552,
        },
        excTaxCan: {
          baseCost: 0,
          baseMargin: 0,
          basePrice: 0,
          baseTaxRate: 0.225,
          cntCost: 0.9421072649824311,
          cntMargin: 0.04564783705838504,
          cntMass: 114.22323390568035,
          cntPrice: 0.9877551020408162,
          cntTaxRate: 0.225,
          cntVolume: 66.388960766327,
          cost: 0.9421072649824311,
          discount: 0,
          formulaBarcode: 123456789,
          hardenerCost: 0,
          hardenerMargin: 0,
          hardenerPrice: 0,
          listPrice: 1.0857142857142856,
          margin: 0.1436070207318545,
          maxPrice: null,
          price: 1.0857142857142856,
          priceGroup: null,
          priceGroupBarcode: null,
          pricePerKilogram: 0.9744136118114657,
          pricePerLitre: 1.0181222102431284,
          tax: 0.24428571428571444,
          taxRate: 0.225,
          tintCost: 1.0421072649824312,
          tintFee: 0.1,
          tintPrice: 1.0857142857142856,
          totalMass: 1114.2232339056804,
          totalVolume: 1066.388960766327,
        },
        incTaxCan: {
          baseCost: 0,
          baseMargin: 0,
          basePrice: 0,
          baseTaxRate: 0.225,
          cntCost: 1.1540813996034782,
          cntMargin: 0.055918600396521745,
          cntMass: 114.22323390568035,
          cntPrice: 1.21,
          cntTaxRate: 0.225,
          cntVolume: 66.388960766327,
          cost: 1.1540813996034782,
          discount: 0,
          formulaBarcode: 123456789,
          hardenerCost: 0,
          hardenerMargin: 0,
          hardenerPrice: 0,
          listPrice: 1.33,
          margin: 0.17591860039652185,
          maxPrice: null,
          price: 1.33,
          priceGroup: null,
          priceGroupBarcode: null,
          pricePerKilogram: 1.1936566744690458,
          pricePerLitre: 1.2471997075478325,
          tax: 0.24428571428571444,
          taxRate: 0.225,
          tintCost: 1.2765813996034783,
          tintFee: 0.12250000000000001,
          tintPrice: 1.33,
          totalMass: 1114.2232339056804,
          totalVolume: 1066.388960766327,
        },
      },
    },
  },
  cache: { cardmap: new Map([[1, { cardname: 'Monicolor Nova 2024' }]]) },
};
