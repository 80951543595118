import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import PropTypes from 'prop-types';
import NumberInput from '../../mylib/NumberInput';

export const SliderWithValue = ({ value, setValue }) => {
  return (
    <div
      className={'pl-2 pr-2'}
      style={{ display: 'inline-flex', width: '100%' }}
    >
      <Input
        className={'pl-2 pr-2'}
        type="range"
        name="range"
        min={-50}
        max={50}
        value={value}
        onChange={(v) => setValue(parseFloat(v.target.value))}
        id="exampleRange"
      />
      <InputGroup>
        <NumberInput
          id={'scale_frm_input'}
          className="form-control"
          min={-50}
          max={50}
          value={value}
          onChange={(value) => setValue(value)}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>%</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

SliderWithValue.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
};
