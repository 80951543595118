import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import offlineActions, {
  propType as offlineProps,
} from 'js/redux/reducers/Offline';
import { withTranslation } from 'react-i18next';

import { Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import errorActions from 'js/redux/reducers/Errors';

const propTypes = {
  t: PropTypes.func.isRequired,
  offline: offlineProps,
  showError: PropTypes.func.isRequired,
  startOfflineMode: PropTypes.func.isRequired,
  stopOfflineMode: PropTypes.func.isRequired,
  reTryRequest: PropTypes.func.isRequired,
  router: PropTypes.any,
};

const defaultProps = {};

class OfflineModal extends React.Component {
  moveToOffline = () => {
    this.props.startOfflineMode();
  };

  moveToOnline = () => {
    this.props.stopOfflineMode();
    window.location.href = this.props.offline.cloud_address;
  };

  render() {
    const { t, reTryRequest, router, offline } = this.props;

    const show_modal =
      ((router.location.pathname === '/' && offline.is_online_mode_available) ||
        !offline.is_online_mode_available) &&
      offline.show_modal;

    return (
      <Modal isOpen={show_modal} centered fade zIndex={1060}>
        <ModalHeader style={{ backgroundColor: '#FF888899' }}>
          {t('fn.workOffline', 'Work offline')}
        </ModalHeader>
        <ModalBody>
          {!offline.is_online_mode_available && (
            <>
              <p>
                {t(
                  'msg.offlineConnectionLost',
                  'Connection to cloud server lost. Do you want to switch to offline mode?'
                )}
              </p>
              <Row>
                <Col>
                  <Button onClick={reTryRequest} color="warning">
                    {t('fn.retryOnline', 'Retry online')}
                  </Button>
                </Col>
                <Col />
                <Col>
                  <Button onClick={this.moveToOffline} color="info">
                    {t('fn.useOfflineMode', 'Use offline mode')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {offline.is_online_mode_available && (
            <>
              <p>
                {t(
                  'msg.offlineConnectionResumed',
                  'Connection to cloud server is resumed. Do you want to switch to online mode?'
                )}
              </p>
              <Row>
                <Col>
                  <Button onClick={this.moveToOnline} color="success">
                    {t('fn.switchToOnline', 'Switch to online')}
                  </Button>
                </Col>
                <Col />
                <Col>
                  <Button onClick={reTryRequest} color="warning">
                    {t('fn.continueOffline', 'Continue offline mode')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

OfflineModal.propTypes = propTypes;
OfflineModal.defaultProps = defaultProps;

function mapStateToProps(store) {
  return {
    offline: store.offline,
    router: store.router,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      reTryRequest: offlineActions.reTryRequest,
      startOfflineMode: offlineActions.startOfflineMode,
      stopOfflineMode: offlineActions.stopOfflineMode,

      showError: errorActions.showCriticalError,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(OfflineModal)
);
