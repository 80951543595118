/* eslint-disable */
import React from 'react';
export const eula = (
  <div
    className="scroll br-8 p-8"
    style={{
      height: 'calc(100vh - 22rem)',
      color: 'black',
      backgroundColor: 'white',
    }}
  >
    CHROMAFLO END-USER LICENSE AGREEMENT
    <br />
    <br />
    IMPORTANT - READ CAREFULLY BEFORE INSTALLING OR OTHERWISE USING THE SOFTWARE
    PROVIDED UNDER THIS AGREEMENT: This EndUser License Agreement ("EULA") is a
    binding legal agreement between you (“You”) and Chromaflo Technologies
    Finland Group Oy, a private limited liability company incorporated under the
    laws of Finland ("Chromaflo").
    <br />
    <br />
    EULA applies to Your use of the Innovatint Software Product in which this
    EULA is contained. “Innovatint Software Product” means the Innovatint Point
    of Sale Software or Innovatint LAB Software and the versions and extensions
    for which You have paid the license fees as well as related electronic
    documentation. EULA applies also to the availability of database that
    comprises of two parts: Customer Database and Innovatint Database
    ("Database"). Innovatint Database is licensed to You by Chromaflo and it
    contains Innovatint Data, including but not limited color formula data and
    characterization data as well as other tinting related data that may be used
    in connection with Innovatint Software Product. Customer Database contains
    data that is collected, stored and managed by the customer of Chromaflo that
    set up the system for the point of sale operators within its own network.
    Customer Database is maintained and licensed to Point of Sale operators by
    the customer of Chromaflo that provided You with the license keys for the
    purpose of joining the network of users of Innovatint Software System. Terms
    and Conditions that govern the transfer and use of data are stated in the
    DATA AGREEMENT that shall be accepted in connection with entering into this
    End User License Agreement. <br />
    <br />
    CHROMAFLO IS WILLING TO LICENCE THE INNOVATINT SOFWARE AND INNOVATINT
    DATABASE SUBJECT TO THE TERMS AND CONDITIONS OF THIS EULA AND DATA
    AGREEMENT. BY PRESSING THE "I ACCEPT" BUTTON, BY INSTALLING, OR BY OTHERWISE
    USING THE INNOVATINT SOFTWARE PRODUCT, YOU CONFIRM THAT YOU ARE WILLING TO
    ACCEPT THE EULA AND DATA AGREEMENT AND AGREE TO BE BOUND BY THE TERMS AND
    CONDITIONS OF THIS EULA AND DATA AGREEMENT. YOU SHALL NOT INSTALL OR USE THE
    INNOVATINT SOFTWARE PRODUCT OR DATABASE WITHOUT ACCEPTING THE TERMS AND
    CONDITIONS. IF YOU HAVE ANY QUESTIONS CONCERNING THE EULA OR DATA AGREEMENT
    OR IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS, YOU SHALL DISCONTINUE
    INSTALLATION OF THE INNOVATINT SOFTWARE PRODUCT IMMEDIATELY AND CONTACT
    CHROMAFLO [SWORDERS@CHROMAFLO.COM]. <br />
    <br />
    If You install the Innovatint Software Product on a computer that is not
    owned by You, You are bound to the terms of this EULA and DATA AGREEMEMT
    both in Your individual capacity and as an agent of the owner of the
    computer. You understand that the terms and conditions become binding on the
    owner of the computer in which the Innovatint Software Product is being
    installed upon. You represent and warrant that You have the capacity and
    authorization to enter into this EULA and DATA AGREEMENT, and to make
    binding legal agreements on behalf of the owner of the computer. For
    purposes of this EULA, the owner of a computer is the individual or entity
    that has legal title to the computer or that has the possessory interest in
    the computer if it is leased or loaned by the actual title owner.
    <br />
    <br />
    The Innovatint Software Product is licensed to be used together with
    Innovatint Database. The Innovatint Software Product is used for tinting of
    paint and color characterization, and it communicates with the Database to
    provide the user with data, including but not limited to color matching
    information, availability information, forecasting calculations, warehouse,
    stock, inventory data and depending on the extensions and versions, it
    provides possibility to manage and handle of color formulas and
    characterization data. <br />
    <br />
    Intellectual property rights. The Innovatint Software Product and Database
    are protected by intellectual property rights stated in intellectual
    property laws and international treaties, including but not limited to
    copyright laws and international copyright treaties. You acknowledge that
    all intellectual property rights in the Innovatint Software Product
    throughout the world belong to Chromaflo or its suppliers, and that rights
    in the Innovatint Software Product and Database are licensed (not sold) to
    You. This EULA contains no implied transfers and no implied licenses. You
    have no rights in, or to, the Innovatint Software Product or Database other
    than the right to use them in accordance with the terms of this EULA. <br />
    <br />
    Your rights and restrictions are stated in this EULA, and You acknowledge
    that this EULA grants you a restricted license to use the Innovatint
    Software Product and Database and that You have no other rights to make any
    reproductions or alterations and that you have no right to communicate the
    Innovatint Software Product and the Database in whole or in part to any
    third party. Your use is governed by contractual restrictions and
    prohibitions that limit the use of the Software and Database for any other
    purpose than what has been stated herein. In case and to the extent the
    Innovatint Software Product can be operated with Open-Source Software
    originally developed by third parties and licensed subject to Open-Source
    Software licenses, please see the list of relevant extensions and software
    at www.innovatint.com/thirdparty. Your right to use such Open-Source
    Software shall be governed by the applicable Open-Source Software license
    terms. <br />
    <br />
    Innovatint Software Product License. The Innovatint Software Product is
    licensed, not sold. Subject to and conditioned on Your ongoing compliance
    with the terms and conditions of this EULA, Chromaflo hereby grants to You a
    restricted, personal, non-exclusive, non-transferable, non-sublicensable
    license to use the Innovatint Software Product in machine readable object
    code form and for Your internal business purposes only. You may install the
    Innovatint Software Product and make the reproductions of the Innovatint
    Software Product that are technically necessary for running the Innovatint
    Software Product. Your license to use the Innovatint Software Product does
    not authorize You to make alterations or modifications or to communicate the
    Innovatint Software Product to any third party or public in whole or in
    part. <br />
    <br />
    Notwithstanding the foregoing, You are authorized to make a permanent
    back-up copy of the Innovatint Software Product. The back-up copy shall not
    be used for any other purposes, and it shall be stored in a manner that it
    can be easily deleted from Your computer hardware and systems after the
    termination of Your license. <br />
    <br />
    You agree and understand, that You are responsible for procuring the
    computer hardware, computer software and network connections and
    subscriptions that are needed to be able to use the Innovatint Software
    Product.
    <br />
    <br />
    If You are installing a copy of the Innovatint Software Product as an
    upgrade, update, patch, or enhancement of a previous release of the same
    Innovatint Software Product which was installed on the same computer, Your
    rights under the previous license agreement for the Innovatint Software
    Product are terminated, and all of Your use of the Innovatint Software
    Product (including its previous versions) are solely under the terms of the
    new EULA.
    <br />
    <br />
    Innovatint Database License. Subject to Your ongoing compliance with the
    terms and conditions of this EULA, Chromaflo grants You a restricted,
    personal, non-exclusive, non-transferable, non-sublicensable license to use
    the Database for Your internal business purposes only and only together with
    validly licensed Innovatint Software Product. The Database can be accessed
    by You through use of the Innovatint Software Product and over the Internet.
    You are responsible for Your online connection and for any hardware that You
    may need for the use of the Innovatint Software Product and Innovatint
    Database. You are authorized to make the partial reproductions of the
    Innovatint Database that are technically necessary for authorized use. You
    shall not make any permanent reproductions of the Database in whole or in
    part.
    <br />
    <br />
    Restrictions. Except as expressly set out in this EULA Your Innovatint
    Software Product License and Database License are restricted as follows. You
    shall not:
    <br />
    • copy the Innovatint Software Product of Innovatint Database in whole or in
    part for any other purpose than what is expressly allowed; or
    <br />
    • make alterations to, or modifications of, the whole or any part of the
    Innovatint Software Product; or
    <br />
    • disassemble, de-compile or reverse engineer the whole or any part of the
    Innovatint Software Product or Innovatint Database nor attempt to gain
    access to the source code or algorithms, structures, or functionalities of
    the Innovatint Software Product or Innovatint Database; or
    <br />
    • permit the Innovatint Software Product or Innovatint Database to be
    combined with, or become incorporated in, any other programs without express
    prior written consent of Chromaflo; or
    <br />
    • rent, lease, sub-license, loan, translate, merge, adapt, vary, or modify
    the Innovatint Software Product license or Innovatint Database license
    without express prior written consent of Chromaflo; or
    <br />
    • provide, or otherwise make available, the Innovatint Software Product or
    Innovatint Database in any form, in whole or in part, to any person without
    express prior written consent of Chromaflo; or <br />
    • create derivative works based on the Innovatint Software Product; or
    <br />
    • remove or alter any trademark, copyright or other intellectual property
    notice of Chromaflo on Your copy of the Innovatint Software Product; or
    <br />
    • create any work around for technical restrictions that may be included in
    the Innovatint Software Product or Innovatint Database. <br />
    <br />
    Interoperability. In the event modifications and examination of source code
    would be essential for the purpose of achieving interoperability of the
    Innovatint Software Product with another software program, You shall notify
    Chromaflo thereof in writing and reserve Chromaflo a possibility to make the
    necessary modifications, develop interfaces or solve interoperability issues
    prior to any attempt to reverse engineer or decompile the software to gain
    information for such activities. Chromaflo shall have the possibility to
    charge regular service fees for technical support required for achieving
    interoperability. <br />
    <br />
    In the event Chromaflo decides not to provide assistance with
    interoperability issues or other services or extensions to solve the
    problem, You may be provided with an express authorization to use the source
    code only for the purpose of achieving inter-operability of the Innovatint
    Software Product with another software program, provided however that
    persons involved in the examination of the source code enter into
    non-disclosure agreements with Chromaflo. All rights in and to any software
    that is created or may be used to achieve interoperability with the
    Innovatint Database, shall be transferred to Chromaflo. Interoperability
    shall be achieved in a manner that no information is disclosed or
    communicated to any third party without prior written consent of Chromaflo
    and that the information that is gained during solving interoperability
    issues shall not be used to any other purpose. Information that is gained
    through accessing the source code of Innovatint Software Product or Database
    shall in no event be used for creating any software which is substantially
    similar to the Innovatint Software Product or Database. <br />
    <br />
    You undertake to keep Your copy of the Innovatint Software Product secure,
    to supervise and control use of the Innovatint Software Product and to
    ensure that the Innovatint Software Product is used by Your employee or
    representative in accordance with the terms of this EULA. You must comply
    with any technical limitations in the Innovatint Software Product that only
    allow You to use it in certain ways. <br />
    <br />
    Data and Information. Chromaflo shall have the right to collect data and
    information concerning Your use of Innovatint Software Product and on the
    use environment, including but not limited to operating system data, screen
    resolution and usability data. Such data is collected only during
    installation or updating the Innovatint Software Product and it is used by
    Chromaflo in the development the Innovatint Software Product. The terms and
    conditions concerning transfer, collection and use of other data, such as
    product information, paint, color, color characterization and tinting data
    and information on the markets are in the Data Agreement. No personal data
    or information is collected by Chromaflo for any purpose. <br />
    <br />
    Privacy Chromaflo does not collect, process, or gain access to any personal
    data or information. It is, however, possible that a user of Innovatint LAB
    acquires an extension, that enables collecting and processing of data
    concerning Point of Sale operators, who use Innovatint Point of Sale
    Software. In case You use the Innovatint Software Product in a manner that
    involves a possibility to process any personal data of Point of Sale
    operators, You shall provide sufficient information and acquire all
    necessary permissions from the data subjects, and comply with all the terms
    and conditions provided in the Regulation (EU) 2016/679 of the European
    Parliament and of the Council of 27 April 2016 on the protection of natural
    persons with regard to the processing of personal data and on the free
    movement of such data, and repealing Directive 95/46/EC (General Data
    Protection Regulation). You shall plan your use and data processing
    principles and documentation to be mindful of the rights of data subjects
    and your use shall at all times be lawful and compliant with the General
    Data Protection Regulation, related guidelines, and case law. <br />
    <br />
    Confidential information. You understand and acknowledge that the source
    code, algorithms and structure of Innovatint Software Products and
    Innovatint Database are valuable trade secrets and technical instructions of
    Chromaflo. You shall not disclose, try to obtain, or use any such
    information or any other confidential information, including but not limited
    to technical, commercial, or financial information of Chromaflo that You may
    receive from Chromaflo during the commercial relation related to Innovatint
    Software Products for any other purpose than what is necessary for normal
    use of the Innovatint Software Product. The foregoing restriction shall not
    apply to such information that can be shown to have been in the public
    domain through no fault of the receiving party prior to its disclosure under
    this EULA. Also, information, that can be proven to be independently created
    by persons with no access to confidential information of Chromaflo falls
    outside the scope of the foregoing restriction. <br />
    <br />
    Warranty. The warranties and disclaimers described in this paragraph are
    collectively the "Limited Warranty". Chromaflo warrants to You (and only
    You) that the Innovatint Software Product and Database will, when properly
    used, perform substantially in accordance with the functions described in
    the accompanying documentation if any for a period of ninety (90) days from
    the date of original purchase of a license to the Innovatint Software
    Product (the "Purchase Date"). You acknowledge that the Innovatint Software
    Product and Database have not been developed to meet Your individual
    requirements and that it is therefore Your responsibility to purchase and
    maintain required hardware and internet connections and to ensure that the
    facilities and functions of the Innovatint Software Product as described in
    the accompanying documentation (if any) meet Your requirements. You
    acknowledge that the Innovatint Software Product may not be free of bugs or
    errors and that the use of the Innovatint Software Product may not be
    uninterrupted or fit for a particular purpose. You agree that the existence
    of any minor errors shall not constitute a breach of this EULA. <br />
    <br />
    TO THE MAXIMUM EXTENT PERMITTED BY LAW, CHROMAFLO AND ITS SUPPLIERS DISCLAIM
    ALL OTHER WARRANTIES AND CONDITIONS WITH REGARD TO OR ARISING OUT OF THE
    INNOVATINT SOFTWARE PRODUCT, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
    LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
    PURPOSE, NON-INFRINGEMENT AND/OR ACCURACY OF INFORMATION. THE LIMITED
    WARRANTY PROVIDED HEREIN IS PERSONAL TO YOU, AND INTENDED SOLELY FOR THE
    BENEFIT OF YOU AND DOES NOT EXTEND TO ANY THIRD PARTY. <br />
    <br />
    The Limited Warranty is void and not applicable if failure of the Innovatint
    Software Product has resulted from:
    <br />
    a) accident, abuse, misapplication, handling, storage, use or maintenance of
    the Innovatint Software Product other than as described in the documentation
    issued by Chromaflo; and/or <br />
    b) modification or repair to the Innovatint Software Product otherwise than
    as authorized in writing by Chromaflo; and/or
    <br />
    c) use of the Innovatint Software Product in combination with other computer
    programs that are not compatible with the Innovatint Software Product;
    and/or
    <br />
    d) Your installation of the Innovatint Software Product; and/or <br />
    e) a defect in Your own products or software; and/or
    <br />
    f) installation or wiring of the Innovatint Software Product other than in
    accordance with the documentation issued by Chromaflo or Chromaflo's
    instructions; and/or
    <br />
    g) transfer of the Innovatint Software Product from the computer equipment
    on which it was originally installed; and/or <br />
    h) Your breach of the terms of this EULA; and/or <br />
    i) any fault of You or Your representatives. <br />
    <br />
    Goods, software and labor used, as well as any and all expenses and costs
    reasonably incurred, by Chromaflo for the repair or replacement or
    correction of the Innovatint Software Product found in whole or in part to
    be non-conforming for reasons listed above under (a) up to and including (i)
    shall be for Your account. The Limited Warranty does not apply to any
    third-party products or software or the Data. No individual (except a duly
    authorized officer of Chromaflo) and no reseller has any authority to amend
    or add to any of the above representations and disclaimers. All Data are
    provided "AS IS". For any Data, Your sole and exclusive remedy will be the
    re-issuance of the Data. <br />
    <br />
    <br />
    Remedies. A notice of a Limited Warranty breach is notified to Chromaflo by
    providing a written description of the alleged breach. In case a breach is
    found, Chromaflo shall at its own discretion either: (a) return the price
    You paid (if any) for the Innovatint Software Product (at which time Your
    rights under this EULA are deemed to have terminated); or (b) repair or
    replace the Innovatint Software Product, provided that You make available
    all information that may be necessary to assist Chromaflo in resolving the
    defect or fault, including but not limited to (i) adequate records that
    accurately document operating time and maintenance performed on the
    Innovatint Software Product and (ii) sufficient information to enable
    Chromaflo to recreate the defect or fault. <br />
    <br />
    You acknowledge that the pricing of Innovatint Software Products reflects
    ownership of intellectual property rights and the limitation of liability
    hereunder. Any condition, warranty, representation, or other term concerning
    the supply of the Innovatint Software Product which might otherwise be
    implied into, or incorporated in, this EULA, whether by statute or
    otherwise, is hereby excluded to the fullest extent permitted by law. Your
    remedies described in this section are Your exclusive remedies and shall not
    be deemed to fail in their essential purpose so long as Chromaflo is willing
    to repair or replace the Innovatint Software Product or return the price You
    paid for the Innovatint Software Product. <br />
    <br />
    Liability. To the maximum extent permitted by applicable law, in no event
    shall Chromaflo or its suppliers be liable for any special, incidental,
    punitive, exemplary, indirect or consequential loss or damage of any kind
    howsoever arising and whether caused by tort (including negligence), breach
    of contract or otherwise (including, without limitation, damages for loss of
    income, loss of business profits or contracts, loss of production, business
    interruption, loss of the use of money or anticipated savings, loss of
    business information, loss of opportunity, loss of revenues, interest,
    capital, financing, goodwill or reputation, opportunity or productivity,
    loss of, damage to or corruption of data or any other pecuniary loss)
    arising out of the use of or inability to use the Innovatint Software
    Product or Innovatint Data, even if Chromaflo has been advised of the
    possibility of such loss or damage. <br />
    <br />
    In any case, maximum aggregate liability of Chromaflo under or in connection
    with any provision of this EULA, whether in contract, tort (including mild
    negligence) or otherwise, shall be limited to the amount actually paid by
    You for the Innovatint Software Product. Any action against Chromaflo must
    be brought within twelve (12) months after the event giving rise to the
    cause of action. <br />
    <br />
    This EULA sets out the full extent of obligations and liabilities of
    Chromaflo in respect of the supply of the Innovatint Software Product and
    Database. In particular, there are no other conditions on liability,
    warranties, representations, or other terms, express or implied, that are
    binding on Chromaflo except as specifically stated in this EULA.
    <br />
    <br />
    Indemnification. You agree to defend, indemnify, and hold Chromaflo harmless
    against any claims, actions, damages, losses, costs, and expenditures,
    including but not limited to attorneys’ fees and costs incurred by Chromaflo
    or its affiliates as a result of unauthorized use or transfer of the
    Innovatint Software Product, Database or Personal data. Chromaflo shall have
    the right to take all necessary steps at Your expense to defend itself until
    You assign a counsel and initiate defense in a professional manner to the
    reasonable satisfaction of Chromaflo. <br />
    <br />
    Third party works. Nothing in this agreement shall be interpreted as
    transfer of intellectual property rights of Chromaflo or its licensors to
    You. To the extent the Innovatint Software Product licensed under this EULA
    contains any third party's intellectual property, the third party shall
    retain exclusive right to its components. Use of such third-party components
    may be subject to restrictions contained in the third party’s end-user
    license agreement in addition to the conditions set forth in this EULA.
    Chromaflo shall make the applicable third party’s end-user license agreement
    available to You. Copyright and other proprietary rights notices of
    Chromaflo and third parties are contained in the Innovatint Software
    Product, and You shall not modify, delete, or obfuscate such notices. <br />
    <br />
    Audit. You must permit Chromaflo and its representatives, at all reasonable
    times and on reasonable advance notice, to inspect and have access to any
    premises, and to the computer equipment located there, at which the
    Innovatint Software Product is being kept or used, for the purpose of
    ensuring You compliance with the terms and conditions of this EULA. Upon
    fifteen (15) business day notice to You, Chromaflo, or its designated
    third-party auditor, shall have the right to conduct an on-site audit during
    Your normal business hours to verify Your compliance with the terms and
    conditions of this EULA. You agree to cooperate with Chromaflo by making
    applicable records available and directing all employees to cooperate with
    Chromaflo. If the audit reveals any unpaid license fees or under licensing,
    You shall pay the amounts owed and reasonable expenses of Chromaflo in
    conducting the audit. Chromaflo shall have the right to conduct an audit to
    ensure that Customer has duly complied with the terms and conditions
    concerning the effects of termination. Such right shall survive the
    termination of this Agreement. <br />
    <br />
    Termination. Your license to use Innovatint Software Product terminates at
    the end of paid license period. Unless otherwise expressly agreed, the
    licenses are renewed annually unless terminated in accordance with a
    Framework Agreement for licensing of Innovatint Software System or with 90
    days prior written notice. Without prejudice to any other rights, Chromaflo
    may, without incurring any liability, terminate this EULA or Your rights
    under this EULA at any time if the third-party Customer terminates the
    agreement concerning Innovatint Software System that You use or if You fail
    to comply with any of the terms and conditions of this EULA. <br />
    <br />
    Upon termination of Your rights under this EULA for any reason, or upon
    termination of the EULA itself, You must immediately delete or remove the
    Innovatint Software Product from the computer equipment in Your possession,
    custody, or control (including all component parts, printed materials, any
    previous versions, and this EULA) and certify to Chromaflo that You have
    done so. You understand and agree that the uninstalling of Innovatint
    Software Products will delete the keys to decryption of the Customer
    Database and that the entire Customer Database will be permanently deleted
    once the Database used by the licensee of the Innovatint Software System is
    deleted. You are solely responsible for taking back up of the Customer
    Database and for the timely data portability prior to termination of the
    EULA. The terms of this paragraph, DATA AGREEMENT, transfers of Data and
    Rights, Confidentiality, Liability, Indemnification and General terms as
    well as any other terms and conditions that by their nature should survive,
    shall survive any kind of termination of this EULA. <br />
    <br />
    Transfer. You may not transfer, assign, charge or otherwise dispose of this
    EULA, or any of Your rights or obligations arising under it to a third party
    without the prior written consent of Chromaflo. In the case Chromaflo by
    written agreement grants permission to transfer Your rights under this EULA,
    You are not allowed to retain any copies of the Innovatint Software Product;
    You are to transfer Your copy of the Innovatint Software Product (including
    all component parts, printed materials, any prior versions, and this EULA),
    and the recipient must agree to be subject to the terms of this EULA. Upon
    the occurrence of such a transfer, Your rights under this EULA terminate
    immediately. Chromaflo may transfer, assign, charge, sub-contract or
    otherwise dispose of this EULA, or any rights or obligations arising under
    it, at any time during the term of this EULA.
    <br />
    <br />
    <br />
    Remote Support. Provided that You have a valid license to use Innovatint
    Software Product(s), You may request technical assistance related to
    Innovatint Software Products from technical support representative of
    Chromaflo, who may either be a Chromaflo service technician, or a third
    party instructed by Chromaflo. Such assistance may involve costs which will
    be invoiced from You. Assistance is provided through a remote connection to
    Your computer which requires installation of third-party client software to
    Your computer. Chromaflo has acquired sufficient rights to use the said
    third-party software for remote support, and You agree that when making the
    assistance request, You provide Chromaflo technical support personnel with
    an access to and control of Your computer. <br />
    <br />
    You may be requested to provide the technical support personnel with an
    access to files that reside on Your computer. Be sure to close any
    confidential or personal files that You may be working on, before allowing
    remote access to Your computer. In order to provide the services, Chromaflo
    technical support personnel is not expected to need to make any copies or
    downloads of Your files or to retain any information accessed from Your
    computer. Chromaflo recommends that You remain at Your desktop and observe
    the entirety of the remote session and strictly follow all safety measures
    provided in the equipment’s technical manual when working via remote
    support. The rotating parts can be dangerous when the equipment is left
    unattended, and any damage incurred by You or third parties during the
    remote access shall be for Your risk and account.
    <br />
    <br />
    The maximum aggregate liability of Chromaflo and the liability of its
    service providers shall be limited to the value of the Chromaflo remote
    technical support services to be provided to You. Chromaflo and its service
    providers make no representations or warranties of any kind with regard to
    the technical support services provided hereunder. In no event shall
    Chromaflo or its service providers be liable for any direct, indirect,
    punitive, incidental, special, consequential damages or any other damages
    whatsoever including, without limitation, damages for loss of use, data or
    profits arising out of or any way connected with the use or performance of
    Chromaflo remote assistance. By requesting Chromaflo to remotely access a
    computer to perform technical support, You accept the terms of the above
    representation and warranty disclaimer, limitation of liability and other
    instructions and limitations that affect the liability of Chromaflo under
    this EULA.
    <br />
    <br />
    <br />
    General. This EULA may be a part of a Framework Agreement for licensing of
    Innovatint Software System. It will follow the order of precedence of the
    Framework Agreement for licensing of Innovatint Software System. For the
    avoidance of doubt, in no event shall any conditions set in a purchase order
    whether accepted or not, or any message or other communication replace,
    modify, amend, or override anything contained herein. Any changes to this
    EULA shall be expressly agreed upon in writing by a duly authorized officer
    of Chromaflo. <br />
    <br />
    If any provision of this EULA is held to be unenforceable for any reason,
    such provision shall be reformed only to the extent necessary to make it
    enforceable, and such decision shall not affect the enforceability of such
    provision under other circumstances, or of the remaining provisions hereof
    under all circumstances. <br />
    <br />
    No waiver by Chromaflo of any breach of any term or provision of this EULA
    shall be construed to be a waiver of any preceding or succeeding breach of
    the same or any other term or provision hereof. The rights and remedies
    hereunder shall be construed to be cumulative and no one of them is
    exclusive of any other or of any right or remedy allowed by law. <br />
    <br />
    This EULA shall be governed by and construed in accordance with the laws of
    Finland, except to the extent the local law of Your local jurisdiction
    requires use of Your local jurisdiction's law. Any dispute, controversy or
    claim arising out of or relating to this contract, or the breach,
    termination or validity thereof, shall be finally settled by arbitration in
    accordance with the Arbitration Rules of the Finland Chamber of Commerce.
    The language of the proceedings shall be English, or if both parties are
    domiciled in Finland, then Finnish. The seat of arbitration shall be
    Helsinki. Notwithstanding the foregoing, matters related to license fees and
    outstanding payment can be decided in the local court that would be
    competent in the absence of the foregoing arbitration clause.
    <br />
    <br />
    This EULA shall benefit Chromaflo and all of its successors and assignees.{' '}
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    TERMS OF USE OF INNOVATINT DATA (“DATA AGREEMENT”) <br />
    <br />
    1 DEFINITIONS
    <br />
    "Customer" means the Company that has licensed Innovatint Software System in
    accordance with Framework Agreement for licensing of Innovatint Software
    System, or You, if You are using the Innovatint Database or any of its parts
    under the license granted in EULA.
    <br />
    “Customer Data” is the data that is collected and processed by the customers
    of Innovatint Software System. Customer Data is in a separate table of the
    Database and itis stored and controlled by the Customer of the relevant
    Frame Agreement in its own ICT environment. Customer Data is encrypted, and
    the keys are in the Innovatint Software Products within the same system.
    Innovatint Point of Sale Software communicates with the Innovatint Lab
    Software within the same Innovatint Software System and the members of the
    same Innovatint Software System can share Customer Data, but it is not
    replicated or sent to the Innovatint Database managed by Chromaflo.
    Chromaflo has no access to or control over the tables of the Customer.
    Uninstalling the Database from the Customers ICT environment and deleting
    the related Customer Database permanently deletes the Customer Data. <br />
    "Data" has the meaning set forth in Section 2.1 of these terms
    <br />
    “Database” means the database that contains Customer Database and Innovatint
    Database and that is located, stored, and used within the Customer’s own ICT
    environment.
    <br />
    "EULA” or “End User License Agreement" means the terms and conditions that
    govern the use of Innovatint Software Product, including Innovatint Point of
    Sale Software, Innovatint Lab Software, Innovatint Database, and possible
    later Innovatint Software Products. This Data Agreement is incorporated in
    the EULA.
    <br />
    “Innovatint Data” is Information which is used, received, stored, or sent by
    the Innovatint Software, Chromaflo, Customer or Point of Sale operators (end
    users), or a combination thereof, and which is stored in the Innovatint
    Database. Innovatint Data concerns (i) tinting information and product data;
    (ii), paints, substances, solutions, colors, colorants, color
    characterization, formulas, matching information, base data, chemical data;
    (iii) the operation of the device, database and software; or (iv) provision
    of service for mixing paints, substances, solutions and colorants, or a
    combination thereof without any Personal Information, and (v) the market
    environment, operation conditions or other similar information on the
    operation of the same, such as current prices, availability, stock of the
    substances, delivery times, order statuses, warehouse data, stock
    management, services for making of colors, new colors, demand of colors,
    forecast of consumption or a combination of such information, and (vi) other
    similar Information that is received, stored, processed, created or sent by
    the Innovatint Software Product or Innovatint Software System, Chromaflo,
    Customer, Point of sale operator or that is received in connection to
    provision of tinting goods and services information and Data which is or
    becomes part of the Innovatint Database. <br />
    “Innovatint Database” is the collection of Innovatint Data that are stored
    in the tables of the Database that can communicate with the master version
    of Innovatint Database maintained by Chromaflo. Innovatint is owned by
    Chromaflo and licensed in accordance with the EULA and/or Frame Agreement.
    <br />
    “Frame Agreement” means the Framework Agreement for licensing of Innovatint
    Software System, which allows the establishing of a system that can be used
    by the point-of-sale operators that have accepted the EULA and whose
    Innovatint Software Product has been connected to the said Innovatint
    Software System.
    <br />
    "Party" means the Customer or Chromaflo, and "Parties" means the Customer
    and Chromaflo together.
    <br />
    “Personal data” means any data or information that, directly or indirectly,
    can identify a living natural person.
    <br />
    <br />
    2 DEFINITION, OWNERSHIP AND USE OF DATA <br />
    <br />
    2.1 DEFINITION OF DATA
    <br />
    2.1.1 Data means information and data entries (i) which a Party has received
    or will receive during use of Innovatint Software Product or Innovatint
    Software System based on EULA or Frame Agreement, and (ii) which is formed
    or collected in connection with the use of devices and software; and (ii)
    which is recorded in a database of a Party. <br />
    2.1.2 Innovatint Data is collected and managed by Chromaflo. Any Data that
    is entered to the Innovatint Database is transferred to Chromaflo and
    licensed back to the customer. The Innovatint Database comprises of the
    following information and Data. The list is not exhaustive. <br />
    a) Color and Product related Data and information that is used, received,
    stored, or sent by the software or service supplied by Chromaflo to Customer
    under the EULA and/or Frame Agreement, or a combination thereof, and which
    concerns product data and data on tinting and characterization or colors,
    color formulas, colorants, bases; paints, substances, solutions, colors,
    colorants, matching information, base data, chemical data; and/or
    <br />
    b) Data and information concerning the stock, sales and consumption of
    products related to tinting, colors, and color characterization, current
    prices, availability, stock of the substances, delivery times, order
    statuses, warehouse data, stock management, new colors, demand of colors,
    forecast of consumption and similar information and data; and/or
    <br />
    c) Data and Information that is formed or processed in connection with
    supplying and installing the device, software or service meant in the Frame
    Agreement or EULA; and/or
    <br />
    d) Data and information that concerns the operation of the software, device
    or service for mixing paints, substances, solutions and colorants, or a
    combination thereof, and the environment, chemical compositions, acceptable
    compositions and operation conditions or other similar Data and information
    of the operation of the same, such as information concerning the operation
    or usage of software and service for making of paints, substances, solutions
    and colorants, or a combination thereof; and/or
    <br />
    e) Data and information that appears from the materials or documentation
    specified in further detail by the Parties; and
    <br />
    f) Other Data and information that is received, stored, or sent by the
    devices, software or services, or combinations thereof, as specified in
    technical descriptions in further detail by the Parties. <br />
    2.1.3 Innovatint Data contains no personal information, and Chromaflo does
    not engage in collecting and use of personally identifiable data. <br />
    2.1.4 Customer Data is a table in the Database that is collected and managed
    by Customer of Innovatint Software System. Customer Data is not communicated
    to Chromaflo, and Customer is solely responsible for any back-ups and for
    any use of the Customer Data. <br />
    <br />
    2.2 OWNERSHIP OF DATA <br />
    2.2.1 Chromaflo is the owner of Innovatint Database. Nothing in this
    Agreement is intended to transfer any ownership or other rights to Customer
    excluding for the restricted fixed time license that has been expressly
    agreed in the Frame Agreement or EULA.
    <br />
    2.2.2 The ownership to any Data that is recorded to the Innovatint Database,
    shall be entirely transferred by the Customer to Chromaflo at the time the
    data is recorded in the Innovatint Database. Chromaflo shall own and have an
    unrestricted, irrevocable, and perpetual right in all Innovatint Data and
    Data that is included in the Innovatint Database and to any Data and
    information that is formed or created by using such Data, including but not
    limited to the unrestricted right to use the Data and to license and/or
    transfer any and all Innovatint Data and information to any third party.{' '}
    <br />
    2.2.3 The Customer represents and warrants that it has the right to transfer
    the rights in Innovatint Data to Chromaflo. The Customer represents and
    warrants that it has taken reasonably required measures to ensure that the
    right to make the transfer of the Data entries and information in accordance
    with these terms and conditions, and that nothing in the transfer infringes
    any rights of a third-party. <br />
    2.2.4 Licensee of Innovatint Software System, is provided a copy of the
    Database in connection to the delivery, installing and setting up of
    Innovatint Software System. The copy of the Database is licensed and not
    sold to the Customer and shall be deleted from the Customer’s ICT
    environment after the termination of the Frame Agreement. The Innovatint
    Software System communicates Innovatint Data from the Database to Chromaflo,
    but the Customer Data in the Customer Database is not replicated nor
    communicated in any manner to Chromaflo. <br />
    2.2.5 Customer shall continue to own the Customer Data and nothing in these
    terms and conditions shall be deemed to transfer any rights to Customer Data
    to Chromaflo. <br />
    2.2.6 In case the Customer requests consultation or technical support
    related to the use of Innovatint Software System in a manner that the
    employee of Chromaflo gains access to Customer Database or Customer Data,
    the employees of Chromaflo shall have the license to process the data to
    fulfil the service order as requested by the Customer.
    <br />
    <br />
    2.3 USE OF DATA
    <br />
    2.3.1 Use of Data referred to in these terms shall mean all the measures
    that a Party exercises or may exercise in respect of Data, including but not
    limited to collecting, recording, copying, combining, compiling,
    structuring, storing, organization, amending, analyzing, comparing,
    utilizing, disseminating, assigning, or publishing the Data either as such
    or in connection with other information.
    <br />
    2.3.2 Chromaflo is the owner of the Innovatint Data and any rights in and to
    Innovatint Data including but not limited to trade secrets, technical
    instructions, and Confidential information. Chromaflo can use it in all of
    its present and future operations. Nothing in these terms of use shall
    restrict the right of Chromaflo to use, disclose or further transfer
    Innovatint Data in part or in whole to third parties.
    <br />
    2.3.3 Chromaflo uses the Innovatint Data for example by collecting market,
    paint and tinting related data and information, and data and information
    concerning use and environment, including but not limited to operating
    system data, screen resolution and usability data, that is created through
    use of the Innovatint Software Product. Market, paint and tinting related
    data and information is owned by Chromaflo and licensed to users of
    Innovatint Software Products. Use and environment data is collected during
    installation or updating the Innovatint Software Product and it is used by
    Chromaflo in the development of the Innovatint Software Product. No Personal
    Data or information is collected by Chromaflo for any purpose. <br />
    2.3.4 Market, paint and tinting related information and data is collected
    and entered into Innovatint Database in a manner that no individuals can be
    identified. Customer is responsible for the contents of the Customer
    Database and in case Personal Data is collected, Customer shall ensure the
    compliance with all applicable agreements, laws, and instructions. <br />
    2.3.5 Customer shall maintain necessary back-up copies of the Customer Data
    independently and unless otherwise separately agreed, Customer shall ensure
    the portability and export of the Customer Data prior to termination of the
    Frame Agreement or EULA. The deleting of the Databases is required in
    connection to the termination of Frame Agreement and EULA, and the deleting
    of the Database shall permanently delete all Customer Data. <br />
    2.3.6 Upon termination or expiration of Frame Agreement the Customer with
    license to manage the Innovatint Software System shall have the right to
    request a copy of the Innovatint Data that concerns the Innovatint Software
    System licensed under Frame Agreement. Chromaflo shall provide the
    Innovatint Data in a commonly used format within 6 months from the data
    portability request. The request and provision of a copy of relevant data
    shall not transfer or limit the ownership of Chromaflo and rights to
    Innovatint Data and Innovatint Database. Customer with Innovatint Software
    System license is granted a perpetual and irrevocable license to use the
    exported data in its internal operations. <br />
    2.3.7 Customer shall respect the exclusive right of Chromaflo to the
    Innovatint Database. Customer shall not disclose, communicate, reproduce, or
    transfer the Innovatint Database in whole or in part to third-parties,
    unless expressly agreed otherwise. The foregoing shall not restrict the
    Customer from using Innovatint Software Product and Innovatint Software
    System for their intended purpose subject to Confidentiality obligations,
    but the Customer shall refrain from taking any substantial extracts of
    Innovatint Data from the Innovatint Database unless expressly authorized by
    Chromaflo. <br />
    2.3.8 Customer undertakes to ensure that the Confidential Information, as
    defined in Frame Agreement and EULA, remains confidential and nothing in
    these terms and conditions shall remove or modify any obligation of
    confidentiality related to Innovatint Data.
    <br />
    2.3.9 Customer shall not have any rights to such information or materials
    which Chromaflo, either independently or through third parties, has
    developed, derived, or otherwise created using Innovatint Data that has been
    transferred from Customer to Chromaflo to it in accordance with the terms
    and conditions of Frame Agreement, EULA, or Data Agreement.
    <br />
    2.3.10 Unless otherwise agreed by the Parties and unless expressly otherwise
    provided herein, the rights and limitations set forth in this Section 2 are
    perpetual and shall survive the termination or expiration of the Agreement.
    <br />
    2.3.11 The Parties may separately agree upon additional terms and conditions
    concerning the use of Data. The said additional terms and conditions shall
    however be agreed by the Parties in writing in order to be binding upon the
    Parties. <br />
    3 PERSONAL DATA <br />
    3.1.1 In case Customer uses the Innovatint Software Product as a platform
    that allows collection and limited use of Personal Data of Point of sale
    operators, Customer shall be responsible for the use of Personal Data and
    undertakes to provide sufficient information to and acquire all necessary
    permissions from the Data subjects. <br />
    3.1.2 Innovatint Lab Software with certain extensions can be used by the
    Customer for collecting following Customer Data: Customer name, Company
    name, Address, Postcode, Country, Phone, Email, Marketing acceptance and
    Customer notes.
    <br />
    3.1.3 Customer shall comply with all the terms and conditions provided in
    the Regulation (EU) 2016/679 of the European Parliament and of the Council
    of 27 April 2016 on the protection of natural persons with regard to the
    processing of personal data and on the free movement of such data, and
    repealing Directive 95/46/EC (General Data Protection Regulation, or GDPR).{' '}
    <br />
    3.1.4 Customer shall be the data controller as defined in the GDPR in
    relation to any Personal Data and undertakes to plan the collection, use and
    data processing principles and create corresponding documentation related to
    the possible processing of Personal Data. Customer shall identify the basis
    of processing and be mindful of the rights of data subjects and undertakes
    to fulfil all the obligations towards other customers and data subjects as
    defined in the GDPR. Any use of the Innovatint Software Product shall at all
    times be lawful and compliant with the General Data Protection Regulation,
    related guidelines, and case law. <br />
    3.1.5 Chromaflo provides the software that can be used in processing of
    Customer Data. Customer Data can contain Personal Data and the role of
    Chromaflo in the processing of Personal Data is the developer of the
    technical environment used in the processing of Personal Data. The Customer
    Database is located and stored in the ICT environment of the Customer.
    Innovatint Software System communicates the Innovatint Data to Chromaflo but
    nothing from the Customer Database to Chromaflo. Customer Database is
    encrypted and readable only with locally installed decryption components
    contained in the Innovatint Software Products within the same Innovatint
    Software System. Chromaflo does not have any access to any Customer Data
    through the System.
    <br />
    3.1.6 However, the possibility of seeing personally identifiable information
    cannot be entirely excluded in case the Customer requests provision of
    professional services that relates to Customer Database. Employee of
    Chromaflo can be provided a remote access to the Customer’s copy of the
    Innovatint Software Product that discusses with the Customer Database. The
    Employees of Chromaflo shall not use or disclose any such information to any
    third party or to any purpose and shall not collect or save any such
    information on any media. <br />
    3.1.7 Database that is required for the use of Innovatint Software is stored
    by the Customer of the Frame Agreement and although is stored and used
    within the ICT environment of the Customer, Chromaflo recognizes its role in
    the deployment of technical safeguards that are applied for the protection
    of personal data. <br />
    3.1.8 The table containing Customer Data is encrypted and accessible solely
    with Innovatint Software Products containing the key for the decryption.
    Chromaflo implements appropriate technical and organizational measures
    necessary in order to ensure a level of security, as required pursuant to
    the Data Protection Legislation Article 32 of the GDPR and undertakes to
    notify Customer without delay in case the information security has been
    modified or infringed. Chromaflo shall assist Customer on matters relating
    to compliance with the technical requirement set forth in the GDPR.
    <br />
    3.1.9 Chromaflo cooperates with the Customer, data processor and controller
    in case there is a threat that the protection of personal data could be
    compromised. Chromaflo cooperates with Customer / Controller to collect the
    necessary information about the disturbance and the related measures,
    especially:
    <br />
    • a description of the nature of the infringement of information security,
    including the information of registered groups and estimated number of
    registered persons affected by the infringement along with the information
    required by Data Protection Legislation; and/or
    <br />
    • necessary information regarding to the statutory obligations and
    fulfilment of the contractual obligations of Controller. These obligations
    shall be based, inter alia, Data Protection Legislation, agreements made
    with third parties and/or a request, a guidance and/or a ruling made by the
    supervisory authority or a tribunal; and/or
    <br />
    • necessary information for preventing similar infringements of the
    information security and information required for the notifications made for
    the registered persons and possible third parties.
    <br />
    3.1.10 Chromaflo and Customer shall cooperate, on request, with the
    supervisory authority in the performance of their respective tasks.
    <br />
    <br />
    4 LIABILITY <br />
    The use of Data shall be each Party's own responsibility. Neither Party
    shall be responsible for the accuracy or non-infringement of the Data, or
    for damages caused by the Data to the Party using the Data, provided,
    however, that these terms and conditions have been complied with. If the
    Party acts in violation of these terms when using the Data or otherwise, the
    liability of the Party for the damages caused to the other Party shall be as
    agreed in the Frame Agreement and EULA.
    <br />
    <br />
    5 OTHER TERMS AND CONDITIONS
    <br />
    5.1 PROFESSIONAL SKILLS AND EXPERIENCE
    <br />
    These terms shall not limit a Party's right to utilize the professional
    skills and experience gained by the Party during the Agreement. <br />
    5.2 SUBCONTRACTORS
    <br />
    A Party may use subcontractors when acting under these terms. The Party
    shall be liable for the acts and omission of its subcontractors as for its
    own.
    <br />
    5.3 INTELLECTUAL PROPERTY RIGHTS
    <br />
    No assignment or right is granted by these terms to the other Party's
    patents, utility patents, trademarks, designs, works enjoying copyright
    protection (or related rights) or to other forms of intellectual property
    rights, whether registered or not.
    <br />
    5.4 AFFILIATED COMPANIES AND SUCCESSORS
    <br />
    5.4.1 The rights and the related obligations concerning Data processing
    shall benefit in full the Affiliates, successors, and assignees of
    Chromaflo. The affiliated companies of Customer shall be treated as
    individual companies who are required to accept the EULA and these terms and
    conditions as an individual Customer company. Each Party shall, however, be
    responsible for the acts and omissions of its affiliated companies as for
    its own, if the affiliated companies process data in the same Innovatint
    Software System. A Party may invoke these terms against the other Party's
    affiliated companies but shall initially direct any claims concerning the
    affiliated companies to the Party of the Frame Agreement in question.
    <br />
    5.4.2 An affiliated company meant in this Section refers to a company or
    other legal entity which at any given time (a) controls the Party, (b) is
    directly or indirectly controlled by the Party or (c) is under the same
    control as the Party. "Control" in this section means a minimum of fifty per
    cent (50 %) stake of the shares or voting rights in the company or entity,
    or right to name or dismiss minimum of fifty per cent (50 %) of the board
    members or members in an equivalent body, or other effective control in the
    company or entity.
    <br />
    5.5 INTERFACES AND TECHNICAL REQUIREMENTS
    <br />
    5.5.1 Insofar as the use of the Data requires access to the other Party's or
    third party's devices, software or other technical environments, the Parties
    shall separately agree upon the related interfaces, data security
    requirements and other technical issues in case there is a need to deviate
    from the terms and conditions stated in the Frame Agreement or EULA.
    <br />
    5.5.2 All rights in and to any software that is created or may be used to
    achieve interoperability between the Innovatint Database and third party
    products, shall be transferred to Chromaflo and anyone who gains access to
    or information on Confidential Information of Innovatint Software Product,
    Innovatint Software System and/or Innovatint Database, shall be bound by a
    non-disclosure agreement with Chromaflo. Interoperability shall be achieved
    in a manner that no information is disclosed or communicated to any third
    party without prior written consent of Chromaflo and that the information
    that is gained during solving interoperability issues shall not be used to
    any other purpose. Information that is gained through accessing the source
    code of Innovatint Software Product or Database shall in no event be used
    for creating any software which is substantially similar to the Innovatint
    Software Product or Database.
    <br />
    5.6 ORDER OF PRECEDENCE
    <br />
    5.6.1 To the extent the rights and obligations related to the transfer and
    use of Data meant in these terms contradict with the terms and conditions of
    the Agreement or a related contract between the Parties, these terms shall
    prevail in respect of Data.
    <br />
    5.6.2 This Data Agreement is meant to specify the definition and use of
    Data. All other definitions, concept of use and terms and conditions in the
    Frame Agreement or EULA between the Parties shall remain unaffected.
    <br />
    5.6.3 The potential restrictions regarding the use of Data set out in these
    terms shall not be applied to the extent the same would prevent the Party
    from fulfilling the Agreement.
    <br />
  </div>
);
