import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { selectors as orderSelectors } from '../reducers/Order';

import { actionTypes as machineActionTypes } from '../reducers/Machine';

import { selectors as configSelectors } from 'js/redux/reducers/Configuration';
import printActions, {
  actionTypes as printActionTypes,
} from 'js/redux/reducers/PrintPreview';
import EPValues from 'js/redux/selectors/EPValues';
import { assignVariables } from 'e-p';
import { ORDER_MODE_FREE_DISPENSE } from 'js/Constants';
import { SOURCE_USER } from '../../Constants';
import log from '../../api/Logger';

const BEFORE = 'BEFORE';
const AFTER = 'AFTER';
const BEFORE_ALL = 'BEFORE_ALL';

/** Check if `layout` contains a barcode item that has a non-empty value in `values` */
function has_barcode(layout, values) {
  for (const { itemType, varCode } of Object.values(layout.items)) {
    if (itemType === 'barcode' && values[varCode]) {
      return true;
    }
  }
  return false;
}

export function check_conditions(task, order, labeldata) {
  // Check label valid
  // Check conditions
  if (task.conditions.length === 0) {
    return true;
  }
  const valid = task.conditions.map((c) => {
    const compare =
      c.contains === 'true'
        ? (a, b) => a.includes(b)
        : c.startsWith === 'true'
        ? (a, b) => a.startsWith(b)
        : (a, b) => a === b;
    let v = true;
    //base
    if (c.baseCode && order.item.basecode) {
      v = compare(order.item.basecode, c.baseCode);
    }
    // product
    else if (c.productName && order.item.productname) {
      v = compare(order.item.productname, c.productName);
    }
    //can name
    else if (c.canName && order.item.cansizecode) {
      v = compare(order.item.cansizecode, c.canName);
    }
    // can
    else if (c.customCanSize) {
      v = order.item.can.canid === null;
    }
    // color
    else if (c.colourCode && order.item.colourcode) {
      v = compare(order.item.colourcode, c.colourCode);
    }
    // barcode
    else if (c.barcodeExists) {
      v = has_barcode(task.layout, labeldata);
    }
    // Formula
    else if (c.addition) {
      v = order.item.additionOnly;
    } else if (c.customFormula) {
      v = order.item.source === SOURCE_USER;
    } else if (c.freeDispense) {
      v = order.order_mode === ORDER_MODE_FREE_DISPENSE;
    } else if (c.hasUfi) {
      v = !!labeldata.extraInfo.formulaUFIs;
    }

    return c.negate === 'true' ? !v : v;
  });

  if (task.logical_conjunction === 'OR') {
    // Some condition valid
    return valid.some(Boolean);
  } else {
    // All conditions valid
    return valid.every(Boolean);
  }
}

function* check_task(when) {
  const tasks = (yield select(configSelectors.localPrintTasks)).filter((t) =>
    when === BEFORE_ALL
      ? t.all_before_tint
      : when === BEFORE
      ? t.before_tint
      : t.after_tint
  );
  if (tasks.length === 0) {
    // No task valid to be executed
    console.log('No print tasks to be executed');
    return;
  }
  const order = yield select(orderSelectors.order);
  const canNumber =
    when === AFTER ? order.ncans_tinted : order.ncans_tinted + 1;

  const labeldata = EPValues(yield select(), 1);
  for (const task of tasks) {
    if (check_conditions(task, order, labeldata)) {
      if (when === BEFORE_ALL) {
        for (let i = canNumber; i <= order.item.ncans; i++) {
          yield* printSilent(task, i);
        }
      } else {
        if (task.show_preview) {
          yield* showPreview(task, order, canNumber);
        } else {
          yield* printSilent(task, canNumber);
        }
      }
    }
  }
}

function* printSilent(task, canNumber) {
  console.log('Print task silent');
  if (window.qtside) {
    // Only need the labeldata as JSON on QtSide
    // File it_pos_app/Printer.py handles this call!
    const labeldata = EPValues(yield select(), canNumber);
    const label = assignVariables(task.layout.data, labeldata);
    window.qtside.printer.printLabel(
      JSON.stringify(label),
      task.printer,
      task.num_labels
    );
  }
}

function* showPreview(task, order, canNumber) {
  yield put(printActions.setOrder(order, canNumber));
  yield put(
    printActions.setLayout({
      taskname: task.name,
      name: task.layout.name,
      ...task.layout.data,
    })
  );
  yield put(printActions.setPrinter(task.printer));
  yield put(printActions.setCopies(task.num_labels));
  yield put(printActions.setVisible(true));
  // Waiting for popup to close before next label task
  yield take(printActionTypes.SET_VISIBLE);
}

function* check_before_tint() {
  try {
    //const { payload } = action;
    /**
     * Handle before tint printing
     * Payload contains the itemid
     * */

    yield call(check_task, BEFORE_ALL);
    yield call(check_task, BEFORE);
  } catch (e) {
    log.error(e);
  }
}

function* check_after_each_can() {
  try {
    //const { payload } = action;
    /**
     * Handle after tint printing
     * Payload contains the itemid
     * */
    yield call(check_task, AFTER);

    /**
     * Handle before tint templates for next if all cans are not tinted
     */

    const order = yield select(orderSelectors.order);

    if (order.ncans_tinted < order.item.ncans) {
      yield call(check_task, BEFORE);
    }
  } catch (e) {
    log.error(e);
    alert(JSON.stringify(e));
  }
}

export default function* saga() {
  yield all([
    takeLatest(machineActionTypes.CMD_TINT, check_before_tint),
    takeLatest(machineActionTypes.TINT_CAN_READY, check_after_each_can),
  ]);
}
