/**
 * Shows modals of the machine if mandatory purge is requested
 */

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  MACHINE_PROGRESS_PURGE,
  MACHINE_PROGRESS_PURGE_ALL,
  MACHINE_PROGRESS_PURGE_AUTOMATIC,
} from 'js/Constants';
import {
  selectors as machineSelectors,
  propType as machineType,
} from 'js/redux/reducers/Machine';
import MachineModalCard from './MachineModalCard';
import userActions from '../../redux/reducers/User';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
  t: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  machines: PropTypes.objectOf(machineType).isRequired,
  dispIDs: PropTypes.array.isRequired,
  login: PropTypes.shape({
    site_logged_in: PropTypes.bool.isRequired,
  }),
  showLoginUser: PropTypes.func.isRequired,
};

const defaultProps = {};

class MachineModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    const cards = [];
    // eslint-disable-next-line
    for (const id of this.props.dispIDs) {
      const currentProcess = _.get(
        this.props.machines[id],
        'machine_state.currentProcess'
      );
      if (
        currentProcess === MACHINE_PROGRESS_PURGE ||
        currentProcess === MACHINE_PROGRESS_PURGE_ALL ||
        currentProcess === MACHINE_PROGRESS_PURGE_AUTOMATIC
      )
        cards.push(<MachineModalCard dispID={id} key={id} />);
    }

    const showModal = cards.length > 0;

    return (
      <Modal isOpen={showModal && this.props.login.site_logged_in} centered>
        <ModalHeader>
          {t('lbl.machineOperations', 'Machine operations')}
          <div
            style={{ position: 'absolute', top: '0.25rem', right: '0.25rem' }}
          >
            <Button
              onClick={() => {
                this.props.loginUser(null, null);
                this.props.showLoginUser();
              }}
            >
              <FontAwesomeIcon icon={'unlock'} />
            </Button>
          </div>
        </ModalHeader>
        <ModalBody>{cards}</ModalBody>
      </Modal>
    );
  }
}

MachineModal.propTypes = propTypes;
MachineModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    machines: machineSelectors.machines(state),
    dispIDs: machineSelectors.dispIDs(state),
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoginUser: userActions.showLoginUser,
      loginUser: userActions.loginUser,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(MachineModal)
);
