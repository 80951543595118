import React from 'react';
import PropTypes from 'prop-types';
import ColorThumbnail from 'js/components/shared/ColorThumbnail';
import { Row, Progress, Col } from 'reactstrap';

const propTypes = {
  cnt: PropTypes.shape({
    code: PropTypes.string.isRequired,
    circuits: PropTypes.array.isRequired,
    barcode: PropTypes.string,
    enabled: PropTypes.bool,
    minLevel: PropTypes.number.isRequired,
    rgb: PropTypes.number,
    id: PropTypes.number,
    currLevel: PropTypes.number.isRequired,
    warnLevel: PropTypes.number.isRequired,
    maxLevel: PropTypes.number.isRequired,
    canEnable: PropTypes.bool,
  }),
};

const defaultProps = {};

export default function Colorant(props) {
  let color = 'success';
  if (props.cnt.currLevel < props.cnt.minLevel) {
    color = 'danger';
  } else if (props.cnt.currLevel < props.cnt.warnLevel) {
    color = 'warning';
  } else {
    // Needed for some reason!!
    color = 'success';
  }

  var style = {
    cursor: 'pointer',
  };

  return (
    <Row style={style}>
      <div style={{ width: '32px' }} className="pl-4">
        <ColorThumbnail rgb={props.cnt.rgb} size="24px">
          {props.cnt.id}
        </ColorThumbnail>
      </div>
      <Col>{props.cnt.code}</Col>
      <Col className="m-auto">
        <Progress
          value={props.cnt.currLevel}
          max={props.cnt.maxLevel}
          color={color}
        />
      </Col>
    </Row>
  );
}

Colorant.propTypes = propTypes;
Colorant.defaultProps = defaultProps;
