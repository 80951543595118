import React, { ReactNode, CSSProperties } from 'react';

interface ModalFooterProps {
  children?: ReactNode;
  style?: CSSProperties;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  children,
  style = {},
}) => {
  return (
    <div
      style={{
        ...style,
        alignItems: 'normal',
        borderBottomLeftRadius: 'calc(1rem - 1px)',
        borderBottomRightRadius: 'calc(1rem - 1px)',
        borderTop: '1px solid #dee2e6',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        padding: '.75rem',
      }}
    >
      {children}
    </div>
  );
};

ModalFooter.defaultProps = {
  style: {},
};
