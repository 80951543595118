import { omitNullValues, assertNotEmpty, isNotNumber } from './ObjectUtils';

/**
 *
 * NumberFormatter formats numbers to strings with units and allows
 * customization of separators, number of decimals, and use of trailing zeros,
 * and scaling of values
 */
export class NumberFormatter {
  constructor(options) {
    [this.defaultGroupSeparator, this.defaultDecimalSeparator] =
      localeDefaultSeparators();

    let {
      decimals = 2,
      allowTrailingZeros = false,
      groupSeparator = this.defaultGroupSeparator,
      decimalSeparator,
      symbol,
      scale = 1,
      currencyFormat = 3,
      fallback = '',
      ...rest
    } = omitNullValues(options);
    assertNotEmpty(rest, 'Invalid option name');

    // use default when nullish, or empty string
    decimalSeparator = decimalSeparator || this.defaultDecimalSeparator;
    if (groupSeparator === decimalSeparator) {
      groupSeparator = '';
    }

    Object.assign(this, {
      decimals,
      allowTrailingZeros,
      groupSeparator,
      decimalSeparator,
      symbol,
      scale,
      currencyFormat,
      fallback,
    });

    this.isDefault =
      decimalSeparator === this.defaultDecimalSeparator &&
      groupSeparator === this.defaultGroupSeparator;
  }

  /**
   * Converts a numeric value to string with unit symbol, non-number to fallback string.
   * Non-empty strings are coerced to numbers.
   *
   * @param {*} num Value
   * @returns {string}
   */
  format(num) {
    if (isNotNumber(num)) return this.fallback;
    const value = this.formatWithoutSymbol(num);
    if (!this.symbol) return value;
    switch (this.currencyFormat) {
      case 0:
        return `${this.symbol}${value}`;
      case 1:
        return `${value}${this.symbol}`;
      case 2:
        return `${this.symbol} ${value}`;
      default:
        return `${value} ${this.symbol}`;
    }
  }

  /**
   * Converts a numeric value to string without unit symbol
   *
   * @param {*} num Value, is converted to Number first
   * @returns {string}
   */
  formatWithoutSymbol(num) {
    if (isNotNumber(num)) return this.fallback;
    const str = (num * this.scale).toLocaleString(undefined, {
      maximumFractionDigits: this.decimals,
      minimumFractionDigits: this.allowTrailingZeros ? this.decimals : 0,
    });
    return this.isDefault
      ? str
      : // replace non-digit substrings
        str.replace(/\D+/g, this._replaceSeparator);
  }

  _replaceSeparator = (sep) => {
    switch (sep) {
      case this.defaultDecimalSeparator:
        return this.decimalSeparator;
      case this.defaultGroupSeparator:
        return this.groupSeparator;
      default:
        // eg. minus sign passes here
        return sep;
    }
  };

  _normalizeSeparators(string) {
    let str = String(string);
    if (this.groupSeparator) {
      str = str.replaceAll(this.groupSeparator, '');
    }
    if (this.decimalSeparator !== '.') {
      str = str.replace(this.decimalSeparator, '.');
    }
    return str;
  }

  /**
   * Converts a formatted String to Number.
   *
   * @param {string} string
   * @returns {number}
   */
  parse(string) {
    if (typeof string === 'number') {
      return string / this.scale;
    }
    let str = String(string);
    if (this.symbol) {
      str = str.replace(this.symbol, '');
    }
    str = this._normalizeSeparators(str);
    return (
      parseFloat(str) / this.scale || 0 // parseFloat returns NaN for ''
    );
  }

  /**
   * Validate possibly incomplete user input to be parsed
   *
   * @param {string} string User input
   * @returns {boolean} true if valid
   */
  validate(string) {
    const str = this._normalizeSeparators(string);
    return !!str.match(/^[+-]?\d*([.]\d*)?$/);
  }
}

/**
 * Finds out the default separators for number formatting,
 * specifically those used by toLocaleString()
 *
 * @returns {array} [groupSeparator, decimalSeparator]
 */
export function localeDefaultSeparators() {
  // Make and parse an example string
  const example = (111111111.2).toLocaleString();
  const matches = example.match(/(\D*)\d+(\D+)\d$/);
  return matches ? [matches[1], matches[2]] : [];
}

export class ArrayFormatter {
  constructor(options) {
    const { valueSeparator = '\n', ...rest } = omitNullValues(options);
    this.valueSeparator = valueSeparator;
    this.numberFormatter = new NumberFormatter(rest);
  }

  format(numbers) {
    if (!numbers) return '';

    return numbers
      .map((x) => this.numberFormatter.format(x))
      .join(this.valueSeparator);
  }
}

/**
 * RangeFormatter is used for paint coverage
 */
export class RangeFormatter {
  constructor(options) {
    this.numberFormatter = new NumberFormatter(options);
  }

  format(numbers) {
    if (!numbers) return this.numberFormatter.fallback;

    const filtered = numbers.filter((x) => x != null);
    let result = '';
    switch (filtered.length) {
      case 0:
        return this.numberFormatter.fallback;
      case 2:
        result =
          this.numberFormatter.formatWithoutSymbol(filtered.shift()) + ' - ';
        break;
      default:
    }
    return result + this.numberFormatter.format(filtered[0]);
  }
}

export class InputNumberFormatter {
  constructor(decimals) {
    this.decimalSeparator = localeDefaultSeparators()[1] || '.';
    this.decimals = decimals;
    this.regexp = new RegExp(`^[+-]?\\d*([.${this.decimalSeparator}]\\d*)?$`);
  }
  format(number) {
    let n = number || 0;
    if (typeof n !== 'number') {
      return '';
    }

    if (this.decimals != null) {
      const fixed = n.toFixed(this.decimals);
      // remove trailing zeros by parsing back
      n = parseFloat(fixed);
    }
    return n.toString().replace('.', this.decimalSeparator);
  }
  validate(string) {
    return !!string.match(this.regexp);
  }
  parse(string) {
    return parseFloat(string.replace(this.decimalSeparator, '.')) || 0;
  }
}
