import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FORMULA_VERSION_ALTERNATIVE,
  FORMULA_VERSION_HISTORY,
} from '../../../Constants';
import { Button, Card, CardBody } from 'reactstrap';
import { scale_to_can } from '../../../mylib/Formula';
import { bindActionCreators } from 'redux';
import orderActions, {
  propType as orderType,
} from '../../../redux/reducers/Order';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FormulaTable from './FormulaTable';

import { CustomScrollbars } from '../../../mylib/Utils';
import { createShotFormatter } from '../../../redux/selectors/Formatters';
import {
  dateTimeToLocaleString,
  dateToLocaleString,
} from '../../../mylib/DateUtils';

class FormulaVersionModal extends Component {
  handleAlternativeFormulaClick = (formula, can) => {
    this.props.setFormula(formula);
    this.props.setOriginalFormula(formula);
    this.props.setCan(can);
    this.props.setItemNotes(
      formula.ishistory
        ? this.props.t('lbl.valid_formula', 'Valid: {{start}} - {{end}}', {
            start: dateToLocaleString(formula.startdate),
            end: dateToLocaleString(formula.enddate),
          })
        : ''
    );

    this.props.toggleFormulaVersionModal();
  };

  createFormulaButton = (formula, idx) => {
    const { t } = this.props;
    if (!formula) {
      return;
    }

    let cntinformula = formula.cntinformula.map((cntin) => ({
      ...this.props.cache.cntmap.get(cntin.cntid),
      cntid: cntin.cntid,
      volume: cntin.volume,
    }));

    const base = this.props.order?.product?.basepaints.find(
      (basepaint) => basepaint.baseid === formula.baseid
    );
    const can = base?.cans.find(
      (can) => can.cansizecode === this.props.order.can?.cansizecode
    );
    cntinformula = base && can && scale_to_can(cntinformula, base, can);
    const scaledFormula = {
      ...formula,
      cntinformula,
    };

    let borderColor =
      formula.ishistory || formula.isalternative || formula.isfallback
        ? '#133347'
        : 'white';

    return (
      <Button
        className="formula_version_card_body_button"
        key={idx}
        data-testid={'formula_' + idx}
        onClick={() => this.handleAlternativeFormulaClick(formula, can)}
        style={{ width: '250px', borderColor }}
      >
        <p>{base?.basecode}</p>
        {formula.startdate && (
          <p>
            <span>{t('lbl.from.colon')}</span>
            {dateTimeToLocaleString(formula.startdate)}
          </p>
        )}
        {formula.enddate && (
          <p>
            <span>{t('lbl.to.colon')}</span>
            {dateTimeToLocaleString(formula.enddate)}
          </p>
        )}
        <FormulaTable
          dispID={this.props.dispID}
          largefrm={false}
          shotFormatter={
            this.props.shotFormatter || this.props.createShotFormatter()
          }
          formula={scaledFormula}
          onlyTable={true}
        />
      </Button>
    );
  };

  render() {
    const { formula_version_modal_type } = this.props.order;
    const formulas = this.props.formula.frms.filter((x) => {
      const iscurrent = !x.ishistory && !x.isalternative && !x.isfallback;
      if (formula_version_modal_type === FORMULA_VERSION_HISTORY) {
        return x.ishistory || iscurrent;
      }
      if (formula_version_modal_type === FORMULA_VERSION_ALTERNATIVE) {
        return x.isalternative || iscurrent;
      }
      return false;
    });
    return (
      formula_version_modal_type && (
        <div className="formula_version_modal">
          <div
            className="formula_input_options_triangle--right"
            onClick={() => this.props.toggleFormulaVersionModal()}
          />
          <Card className="formula_version_card">
            <CardBody className="formula_version_card_body">
              <CustomScrollbars style={{ height: '100%', paddingTop: 0 }}>
                {formulas.map((formula, idx) =>
                  this.createFormulaButton(formula, idx)
                )}
              </CustomScrollbars>
            </CardBody>
          </Card>
        </div>
      )
    );
  }
}

FormulaVersionModal.propTypes = {
  dispID: PropTypes.string,
  order: orderType.isRequired,
  formula: PropTypes.object.isRequired,
  cache: PropTypes.shape({
    cntmap: PropTypes.instanceOf(Map).isRequired,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        unitid: PropTypes.number,
        unitname: PropTypes.string,
      })
    ),
  }),
  toggleFormulaVersionModal: PropTypes.func.isRequired,
  setCan: PropTypes.func.isRequired,
  setFormula: PropTypes.func.isRequired,
  setOriginalFormula: PropTypes.func.isRequired,
  setItemNotes: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  shotFormatter: PropTypes.object, // currently not passed from OrderPage
  createShotFormatter: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    order: store.order,
    formula: store.formula,
    cache: store.cache,
    createShotFormatter: createShotFormatter(store),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleFormulaVersionModal: orderActions.toggleFormulaVersionModal,
      setCan: orderActions.setCan,
      setFormula: orderActions.setFormula,
      setOriginalFormula: orderActions.setOriginalFormula,
      setItemNotes: orderActions.setItemNotes,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(FormulaVersionModal)
);
