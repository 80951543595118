import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import hoverActions from 'js/redux/reducers/HoverProduct';

class ProductName extends Component {
  mouseOver = () => {
    this.props.setHoverProduct(this.props.productData);
  };
  mouseOut = () => {
    this.props.setHoverProduct(null);
  };

  mouseClick = () => {
    this.props.setProduct(this.props.productData);
  };

  render() {
    const { products_having_formula } = this.props;
    const product_with_formula = products_having_formula?.find(
      (item) => item.productid === this.props.productData?.productid
    );

    return (
      <div
        className="product-item"
        style={{
          ...this.props.style,
          backgroundColor: this.props.selected && 'lightblue',
        }}
        onMouseEnter={this.mouseOver}
        onMouseUp={this.mouseClick}
        onMouseLeave={this.mouseOut}
        data-testid={
          this.props.productData && this.props.productData.productzname
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <span>
            {' '}
            {this.props.productData && this.props.productData.productzname}
          </span>
          <span>
            {' '}
            {this.props.productData && product_with_formula?.basecode}
          </span>
        </div>
      </div>
    );
  }
}

ProductName.propTypes = {
  productData: PropTypes.object.isRequired,
  products_having_formula: PropTypes.array,
  setProduct: PropTypes.func.isRequired,
  setHoverProduct: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
};

function mapStateToProps(store) {
  return {
    products_having_formula: store.formula.products_having_formula,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setHoverProduct: hoverActions.setHoverProduct,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductName);
