import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddBarcodeModal } from './BarcodeModal';
import { PreviewModal } from './PreviewModal';

export const EM_TYPE_TEXT = 'EM_TYPE_TEXT';
export const EM_TYPE_BARCODE = 'EM_TYPE_BARCODE';

type Props = {
  initialValue: {
    template: string;
    name: string;
    pageSize: string;
    subject: string;
  };
  onSave: (d: {
    template: string;
    name: string;
    pageSize: string;
    subject: string;
  }) => void;
  onCancel: () => void;
  menuItems: {
    label: string;
    value: string;
    variables: {
      label: string;
      value: string;
      type?: string;
      barcode_types?: string[];
    }[];
  }[];
  variables: { key: string; value: string; barcode_types?: string[] }[];
  tinymceScriptSrc?: string;
  translate?: (key: string, defaultValue: string) => string;
};

const defaultProps = {
  initialValue: {
    template: '',
    name: '',
    pageSize: 'A4',
    subject: 'Innovatint - order([orderID])',
  },
  onSave: console.log,
  menuItems: [],
};

/* const sizes = [
  'A0',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'B0',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'B6',
  'B7',
  'B8',
  'B9',
  'B10',
  'C5E',
  'Comm10E',
  'DLE',
  'Executive',
  'Folio',
  'Ledger',
  'Legal',
  'Letter',
  'Tabloid',
];

 */

const MailEditor = ({
  initialValue,
  onSave,
  onCancel,
  menuItems,
  variables,
  tinymceScriptSrc,
  translate,
}: Props): JSX.Element => {
  const editorRef = useRef(null);

  const { t } = useTranslation();
  const trans = translate || t;

  const [name, setName] = useState('');
  const [size, setSize] = useState('A4');
  const [subject, setSubject] = useState('Innovatint - order([orderID])');
  const [toggle, setToggle] = useState(false);
  const [addBarcodeVisible, setAddBarcodeVisible] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [barcode_types, setBarcodeTypes] = useState(['qrcode']);
  // @ts-ignore
  const content = editorRef?.current?.getContent() || '';

  useEffect(() => {
    setName(initialValue.name);
    setSize(initialValue.pageSize);
    setSubject(initialValue.subject);
  }, [initialValue.name, initialValue.pageSize, initialValue.subject]);

  const saveTemplate = () => {
    onSave({
      // @ts-ignore
      template: editorRef?.current?.getContent(),
      name: name,
      subject: subject || 'Innovatint - order([orderID])',
      pageSize: size,
    });
  };

  const toggleModal = () => {
    setToggle(!toggle);
  };

  return (
    <div className={'em-background-div'}>
      <div className={'em-flex-div'}>
        <p className={'em-paragraph'}>{trans('lbl.name.colon', 'Name:')}</p>
        <input
          className={'em-input-field'}
          value={name}
          data-testid="TemplateName"
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setName(e.target.value)
          }
        ></input>
      </div>
      <div className={'em-flex-div'}>
        <p className={'em-paragraph'}>
          {trans('lbl.subject.colon', 'Subject:')}
        </p>
        <input
          className={'em-input-field'}
          value={subject}
          data-testid="Subject"
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setSubject(e.target.value)
          }
        ></input>
      </div>
      {/*<Flexdiv>
        <Paragraph>{trans('lbl.pageSize.colon', 'Page size:')}</Paragraph>
        <Select
          disabled={true}
          value={size}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setSize(e.target.value)
          }
        >
          {sizes.map((e, index) => (
            <option key={index}>{e}</option>
          ))}
        </Select>
      </Flexdiv> */}
      <Editor
        id="TemplateEditor"
        initialValue={initialValue.template}
        // @ts-ignore
        onInit={(evt, editor) => {
          // @ts-ignore
          editorRef.current = editor;
        }}
        tinymceScriptSrc={
          tinymceScriptSrc
            ? tinymceScriptSrc
            : process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
        }
        init={{
          // @ts-ignore
          height: 500,
          menubar: 'file edit view insert format tools table tc help data',
          menu: {
            data: {
              title: 'Data',
              items: menuItems.map((z) => z.value).join(' '),
            },
          },
          // @ts-ignore
          setup: function (editor) {
            menuItems.forEach((e) => {
              editor.ui.registry.addNestedMenuItem(e.value, {
                text: e.label,
                getSubmenuItems: function () {
                  return e.variables.map((a) => ({
                    type: 'menuitem',
                    text: a.label,
                    onAction: function () {
                      if (a.type === EM_TYPE_BARCODE) {
                        setBarcodeTypes(a.barcode_types || ['qrcode']);
                        setBarcode(a.value);
                        setAddBarcodeVisible(true);
                      } else {
                        editor.insertContent(`[${a.value}]`);
                      }
                    },
                  }));
                },
              });
            });

            //adding preview button
            editor.ui.registry.addButton('previewTemplate', {
              //text: 'Preview',
              icon: 'preview',
              tooltip: 'Preview template',
              onAction: toggleModal,
            });
          },

          plugins: [
            'importcss',
            'searchreplace',
            'autolink',
            'autosave',
            'save',
            'directionality',
            'code',
            'visualblocks',
            'visualchars',
            'image',
            'link',
            'media',
            'table',
            'charmap',
            'pagebreak',
            'nonbreaking',
            'anchor',
            'advlist',
            'lists',
            'wordcount',
            'help',
            'charmap',
            'quickbars',
            'emoticons',
          ],
          toolbar:
            'undo redo | previewTemplate | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen | insertfile image media template link anchor codesample | ltr rtl',
          toolbar_sticky: true,
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          quickbars_selection_toolbar:
            'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          noneditable_noneditable_class: 'mceNonEditable',
          toolbar_mode: 'sliding',
        }}
      />
      <div className={'em-flex-div'}>
        <button
          className={'em-cancel-button'}
          onClick={onCancel}
          data-testid="cancel"
        >
          {trans('fn.cancel', 'Cancel')}
        </button>
        <button
          onClick={saveTemplate}
          disabled={!name || !subject}
          data-testid="save"
          className={'em-save-button'}
        >
          {trans('fn.save', 'Save')}
        </button>
      </div>
      <PreviewModal
        template={content}
        ei_values={variables}
        toggleModal={toggleModal}
        isOpen={toggle}
      />
      <AddBarcodeModal
        barcode_types={barcode_types}
        data_key={barcode}
        toggleModal={() => setAddBarcodeVisible(false)}
        isOpen={addBarcodeVisible}
        editorRef={editorRef}
      />
    </div>
  );
};

MailEditor.defaultProps = defaultProps;

// eslint-disable-next-line import/no-default-export
export default MailEditor;
