import _ from 'lodash';

export function omitNullValues(obj) {
  return _.omitBy(obj, _.isNull);
}

export function assertNotEmpty(obj, msg) {
  console.assert(
    _.isEmpty(obj),
    (msg || 'Object not empty') + ' ' + JSON.stringify(obj)
  );
}

/**
 * Test if value is NaN, '', null or undefined.
 *
 * @param {*} value
 */
export function isNotNumber(value) {
  return value == null || value === '' || isNaN(value);
  // Note that isNaN coerces to number.
}

/**
 * Group objects in an array and reduce each group
 * @param {array} arr Array of objects
 * @param {string} keyName Name of property to group by
 * @param {function} reducer f(acc, obj) to aggregate the grouped objects;
 *  acc is previous aggeregate or undefined, obj is an object in arr
 * @returns
 */
export function groupAndReduce(arr, keyName, reducer) {
  const map = arr.reduce((acc, obj) => {
    const key = obj[keyName];
    acc.set(key, reducer(acc.get(key), obj));
    return acc;
  }, new Map());

  return Array.from(map, ([key, row]) => {
    return {
      [keyName]: key,
      ...row,
    };
  });
}
