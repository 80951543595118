import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

import PropTypes from 'prop-types';
import { RGBColorToHex, sortFormula } from '../../mylib/Utils';
import { expandComments } from '../../redux/selectors/FormulaComments';
import { useTranslation } from 'react-i18next';
import { scale_to_can } from '../../mylib/Formula';
import { makeFormatters } from '../../redux/selectors/EIValues'; // import necessary utility functions
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import ErrorBoundary from '../shared/ErrorBoundaryModal';
import log from '../../api/Logger';

const formatFormula = (cache, cntinformula, base, can) => {
  const scaledFormula =
    cntinformula && base && can && scale_to_can(cntinformula, base, can);
  return (
    scaledFormula &&
    scaledFormula.map((formula) => {
      return {
        volume: formula.volume,
        cntcode: cache.cntmap.get(formula.cntid).cntcode,
        cntid: formula.cntid,
      };
    })
  );
};

const page0 = (
  t,
  std,
  data,
  configurations,
  id_prefix,
  formatters,
  cache,
  item
) => {
  const { shotFormatter } = configurations;

  const measuredColorHex = RGBColorToHex(std?.rgb || null);
  const matchingColorHex = RGBColorToHex(data?.rgb || null);
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div className="card__colors">
          <div
            style={{
              backgroundColor: measuredColorHex,
              width: '50%',
            }}
          />
          <div
            style={{
              backgroundColor: matchingColorHex,
              width: '50%',
            }}
          />
        </div>
        <b className="card__infos">{data?.base?.basecode}</b>
        <div className={'scroll'} style={{ maxHeight: '8rem' }}>
          {data?.formula?.cntinformula &&
            data?.base &&
            data?.can &&
            sortFormula(
              configurations,
              formatFormula(
                cache,
                data.formula.cntinformula,
                data.base,
                data.can
              )
            ).map((cnt, index) => (
              <p
                key={index}
                className="card__infos"
                data-testid={id_prefix + '_frm' + (item + 1) + '_txt' + index}
              >
                {cnt.cntcode} -{' '}
                {shotFormatter.format({
                  volume: cnt.volume,
                  specificgravity: cache.cntmap.get(cnt.cntid).specificgravity,
                })}
              </p>
            ))}
        </div>
      </div>
      <div className={'mb-1'}>
        <Table size={'sm'} borderless className="m-0">
          <tbody>
            <tr>
              <td className={'p-0'}>{t('extraInfo.cost')}</td>
              <td className={'p-0'}>
                {formatters.EI_TYPE_CURRENCY.default.format(data?.cost)}
              </td>
            </tr>
            <tr>
              <td className={'p-0'}>{'dE'}</td>
              <td className={'p-0'}>
                {formatters.EI_TYPE_NUMBER.format(data?.delta?.ciede2000)}
              </td>
            </tr>
            <tr>
              <td className={'p-0'}>{'ME'}</td>
              <td className={'p-0'}>
                {formatters.EI_TYPE_NUMBER.format(data?.delta?.me)}
              </td>
            </tr>
            <tr>
              <td className={'p-0'}>{'CR'}</td>
              <td className={'p-0'}>
                {formatters.EI_TYPE_NUMBER.format(data?.delta?.cr)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const page1 = (t, data, configurations, id_prefix, formatters, cache, item) => {
  return (
    <>
      <p className="card__infos">
        {expandComments(data?.matchGrade, cache.commentTexts)}
      </p>
      <Table size={'sm'} borderless>
        <thead>
          <tr>
            <td></td>
            <td>{'D65'}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{'dL*'}</td>
            <td data-testid={id_prefix + '_dl_txt_' + item}>
              {formatters.EI_TYPE_NUMBER.format(data?.delta?.lab[0])}
            </td>
          </tr>
          <tr>
            <td>{'da*'}</td>
            <td>{formatters.EI_TYPE_NUMBER.format(data?.delta?.lab[1])}</td>
          </tr>
          <tr>
            <td>{'db*'}</td>
            <td>{formatters.EI_TYPE_NUMBER.format(data?.delta?.lab[2])}</td>
          </tr>
          <tr>
            <td>{'dC*'}</td>
            <td>{formatters.EI_TYPE_NUMBER.format(data?.delta?.lch[1])}</td>
          </tr>
          <tr>
            <td>{'dH*'}</td>
            <td>{formatters.EI_TYPE_NUMBER.format(data?.delta?.lch[2])}</td>
          </tr>
          <tr>
            <td>{'Me'}</td>
            <td>{formatters.EI_TYPE_NUMBER.format(data?.delta?.me)}</td>
          </tr>
          <tr>
            <td>{'dE2000'}</td>
            <td data-testid={id_prefix + '_de_txt_' + item}>
              {formatters.EI_TYPE_NUMBER.format(data?.delta?.ciede2000)}
            </td>
          </tr>
          <tr>
            <td>{'CR'}</td>
            <td>{formatters.EI_TYPE_NUMBER.format(data?.delta?.cr)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const page2 = (t, std, data, label) => {
  // Create line chart from data. Data contains 31 values and minimum value is zero and maximum is 100

  const lines = [];
  try {
    for (let i = 0; i < 31; i++) {
      lines.push({
        name: 400 + i * 10,
        value: data?.refl?.[i],
        std: std?.refl?.[i],
      });
    }
  } catch (error) {
    log.error(error);
  }

  return (
    <ErrorBoundary>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minWidth={100}
        minHeight={100}
      >
        <LineChart
          width={200}
          height={200}
          data={lines}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="name" />
          <YAxis
            domain={[0, 100]}
            tickCount={1}
            allowDecimals={false}
            width={10}
          />
          <Line
            type="monotone"
            name={t('lbl.standard_graph', 'Standard')}
            dataKey="std"
            stroke="rgb(48, 141, 199)"
            dot={false}
          />
          <Line
            type="monotone"
            name={label}
            dataKey="value"
            stroke="#FFFFFF"
            dot={false}
          />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </ErrorBoundary>
  );
};

const FormulaCard = ({
  colourcode,
  colournames,
  item,
  configurations,
  currentSection,
  previousSectionHandler,
  nextSectionHandler,
  selectColorHandler,
  std,
  cache,
  active,
  chooseColorHandler,
  id_prefix,
  data,
}) => {
  const { t } = useTranslation();
  const label = colourcode
    ? colourcode
    : t('lbl.color_graph', 'Color {{index}}', { index: item + 1 });
  const formatters = makeFormatters(configurations);

  let section;

  const cardActiveStyle = classnames('search-closest-card', {
    'search-closest-card--active': active,
  });

  switch (currentSection) {
    case 2:
      section = page2(t, std, data, label);
      break;
    case 1:
      section = page1(
        t,
        data,
        configurations,
        id_prefix,
        formatters,
        cache,
        item
      );
      break;

    case 0:
      section = page0(
        t,
        std,
        data,
        configurations,
        id_prefix,
        formatters,
        cache,
        item
      );
      break;
    default:
      break;
  }

  return (
    <Card
      id={id_prefix + '_card' + (item + 1)}
      onClick={selectColorHandler}
      onDoubleClick={chooseColorHandler}
      tag={Button}
      className={cardActiveStyle}
    >
      <CardHeader className="card__header">
        <span>{label}</span>
        <span style={{ display: 'block' }}>
          {colournames && colournames.length > 0 ? colournames[0] : ''}
        </span>
      </CardHeader>
      <CardBody style={{ width: '100%', position: 'relative' }}>
        <div className="card__details">
          {currentSection > 0 && (
            <div
              onClick={previousSectionHandler}
              className="card__btn card__btn__left"
              data-testid={id_prefix + '_prev_page' + (item + 1)}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </div>
          )}
          <div
            className="card__section"
            data-testid={id_prefix + '_page' + (item + 1)}
          >
            {section}
          </div>
          {currentSection < 2 && (
            <div
              onClick={nextSectionHandler}
              className="card__btn card__btn__right"
              data-testid={id_prefix + '_next_page' + (item + 1)}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

FormulaCard.propTypes = {
  item: PropTypes.number, // Just for testing!
  std: PropTypes.shape({
    rgb: PropTypes.number,
    refl: PropTypes.arrayOf(PropTypes.number),
  }),
  cache: PropTypes.shape({
    commentTexts: PropTypes.object,
    cntmap: PropTypes.instanceOf(Map),
  }),
  configurations: PropTypes.object,
  currentSection: PropTypes.number,
  nextSectionHandler: PropTypes.func,
  previousSectionHandler: PropTypes.func,
  data: PropTypes.shape({
    refl: PropTypes.arrayOf(PropTypes.number),
    base: PropTypes.shape({
      coefficient: PropTypes.number,
      specificgravity: PropTypes.number,
      nominalfill: PropTypes.number,
      basecode: PropTypes.string,
    }),
    can: PropTypes.shape({
      basevolume: PropTypes.number,
      fill: PropTypes.number,
    }),
    rgb: PropTypes.number,
    cost: PropTypes.number,
    canSize: PropTypes.string,
    delta: PropTypes.object,
    formula: PropTypes.shape({
      baseid: PropTypes.number,
      cntinformula: PropTypes.arrayOf(
        PropTypes.shape({
          cntcode: PropTypes.string,
          volume: PropTypes.number,
          cntid: PropTypes.number,
        })
      ),
    }),
    baseCode: PropTypes.string,
    matchGrade: PropTypes.string,
  }),
  colourcode: PropTypes.string,
  colournames: PropTypes.array,
  selectColorHandler: PropTypes.func,
  chooseColorHandler: PropTypes.func,
  active: PropTypes.bool,
  id_prefix: PropTypes.string,
};
export default FormulaCard;
