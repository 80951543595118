import { ArrayFindOneOrNull } from '../../../mylib/Utils';
import _ from 'lodash';

const VALID_NOTFIT = 'VALID_NOTFIT';
const VALID_OK = 'VALID_OK';
const INVALID_CNT = 'INVALID_CNT';
const TOO_MUCH_CNT = 'TOO_MUCH_CNT';
const INVALID_CANSIZE = 'INVALID_CANSIZE';
const INVALID_MACHINE_BARCODE = 'INVALID_MACHINE_BARCODE';

export function validate_machine_barcode(
  colorant_barcode,
  canister_barcode,
  barcode_refill_action,
  barcode_details,
  barcode_canister_action,
  config,
  cache,
  machine,
  t,
  selectedCnt,
  machine_canister_barcode
) {
  let db_cnt = null;

  if (selectedCnt) {
    db_cnt = ArrayFindOneOrNull(cache.cnts, 'cntid', selectedCnt.cntid);
  }

  if (barcode_refill_action && colorant_barcode) {
    db_cnt = ArrayFindOneOrNull(
      cache.cnts,
      'cntid',
      barcode_refill_action.cntid
    );
  } else if (barcode_canister_action && colorant_barcode) {
    db_cnt = ArrayFindOneOrNull(
      cache.cnts,
      'cntcode',
      barcode_canister_action.cntcode
    );
  } else if (barcode_details?.action === 'colorant' && colorant_barcode) {
    db_cnt = ArrayFindOneOrNull(cache.cnts, 'cntid', barcode_details?.cntid);
  }

  let machine_cnt =
    selectedCnt && !canister_barcode
      ? selectedCnt
      : ArrayFindOneOrNull(machine.colorants, 'barcode', canister_barcode);
  let cansize = barcode_refill_action
    ? ArrayFindOneOrNull(
        cache.cansizes,
        'cansizeid',
        barcode_refill_action.cansizeid
      )
    : null;

  let valid_rs = null;
  let warning_set = false;

  if (!cansize && barcode_details) {
    cansize = ArrayFindOneOrNull(
      cache.cansizes,
      'cansizeid',
      barcode_details.cansizeid
    );
    // Succesfully checked the can size from barcode details. Then also confirm that it's for this colorant
    if (cansize) {
      if (
        machine_cnt &&
        barcode_details.cntid !== machine_cnt.cntid &&
        colorant_barcode
      ) {
        db_cnt = ArrayFindOneOrNull(cache.cnts, 'cntid', barcode_details.cntid);

        valid_rs = INVALID_CNT;

        warning_set = true;
      }
    }
  }
  if (!barcode_details && !barcode_refill_action) {
    warning_set = true;
  }

  // Case where canister barcode validation is not active
  if (
    !machine?.config?.canister_barcode_confirmation &&
    machine?.config &&
    db_cnt &&
    !machine_cnt
  ) {
    // Try to select machine colorant based on db_cntcode
    machine_cnt = _.find(machine.colorants, (x) => x.code === db_cnt.cntcode);
  }

  let validation = VALID_OK;
  let to_add = null;

  if (cansize && machine_cnt) {
    to_add = cansize.nominalamount * _.get(config, 'refill_factor.value', 1);

    if (machine_cnt.currLevel + to_add > machine_cnt.maxLevel) {
      validation = VALID_NOTFIT;
    }
  }

  if (
    !warning_set &&
    colorant_barcode &&
    (canister_barcode ||
      (!machine?.config?.canister_barcode_confirmation && machine?.config))
  ) {
    if (machine_cnt) {
      if (machine_cnt.code === db_cnt?.cntcode) {
        // Valid colorant now check amount
        if (validation === VALID_OK) {
          valid_rs = VALID_OK;
        } else {
          valid_rs = TOO_MUCH_CNT;
        }
      } else {
        // Invalid colorant!
        valid_rs = INVALID_CNT;
      }
    }
  }

  if (!cansize && colorant_barcode) {
    valid_rs = INVALID_CANSIZE;
  }

  // When manually selected colorant and now verified with barcode
  // Ensure that correct colorant is selected
  if (selectedCnt && db_cnt) {
    if (selectedCnt.cntid !== db_cnt.cntid) {
      valid_rs = INVALID_CNT;
    }
  }

  if (
    !machine_cnt &&
    machine?.config?.canister_barcode_confirmation &&
    canister_barcode
  ) {
    valid_rs = INVALID_MACHINE_BARCODE;
  }

  // Changing default value to true if barcode confirmation is not required.
  let barcode_validation_ok = !machine?.config?.colorant_barcode_confirmation;
  let valid_style = '';
  let info_1st_line = '';
  let info_txt = [];
  switch (valid_rs) {
    case VALID_OK: {
      barcode_validation_ok = true;
      valid_style = 'alert-success';
      info_1st_line = t(
        'msg.compatibilityCheckSuccess',
        'Compatibility check successfully passed'
      );
      info_txt.push(
        t(
          'msg.confirmRefillCanister',
          'You have chosen to refill your canister {{machine_cnt.id}} with colorant {{db_cnt.cntcode}}.',
          { machine_cnt, db_cnt }
        )
      );
      break;
    }
    case INVALID_CNT: {
      barcode_validation_ok = false;
      valid_style = 'alert-danger';
      info_1st_line = t('lbl.error', 'Error');
      info_txt.push(
        t(
          'msg.canisterCannotContainCnt',
          'Canister {{machine_cnt.id}} cannot contain {{db_cnt.cntcode}} colorant.\n' +
            'Please choose a compatible colorant.',
          { machine_cnt, db_cnt }
        )
      );
      break;
    }
    case INVALID_CANSIZE: {
      barcode_validation_ok = false;
      valid_style = 'alert-danger';
      info_1st_line = t('lbl.error', 'Error');
      info_txt.push(
        t('msg.unknownColorantBarcode', 'Unknown colorant barcode')
      );
      break;
    }
    case TOO_MUCH_CNT: {
      barcode_validation_ok = false;
      valid_style = 'alert-danger';
      info_1st_line = t('lbl.error', 'Error');
      info_txt.push(
        t(
          'msg.canisterCannotContainVolume',
          'Canister {{machine_cnt.id}} cannot contain the volume of the chosen {{db_cnt.cntcode}} colorant.',
          { machine_cnt, db_cnt }
        )
      );
      break;
    }
    case INVALID_MACHINE_BARCODE: {
      barcode_validation_ok = false;
      valid_style = 'alert-danger';
      info_1st_line = t('lbl.error', 'Error');
      info_txt.push(
        t('msg.unknownCanisterBarcode', 'Unknown canister barcode')
      );
      break;
    }
    default: {
      break;
    }
  }

  if (
    !machine_canister_barcode &&
    machine?.config?.colorant_barcode_confirmation &&
    machine?.config?.canister_barcode_confirmation &&
    !(machine_cnt?.barcode || selectedCnt?.barcode) &&
    (machine_cnt || selectedCnt)
  ) {
    barcode_validation_ok = false;
    valid_style = 'alert-danger';
    info_1st_line = t('lbl.error', 'Error');

    info_txt.push(
      t(
        'msg.canisterBarcodeNotAssignedCode',
        'Barcode not assigned to Canister id {{machine_cnt.id}}({{machine_cnt.code}})',
        { machine_cnt: { ...selectedCnt, ...machine_cnt } }
      )
    );
  }
  if (
    !machine_canister_barcode &&
    machine?.config?.canister_barcode_confirmation &&
    !machine?.config?.colorant_barcode_confirmation &&
    !(machine_cnt?.barcode || selectedCnt?.barcode) &&
    (machine_cnt || selectedCnt)
  ) {
    barcode_validation_ok = true;
    valid_style = '';
    info_1st_line = '';
  }

  if (
    db_cnt &&
    _.sumBy(db_cnt?.cntcans, 'barcodes.length') === 0 &&
    machine?.config?.colorant_barcode_confirmation
  ) {
    barcode_validation_ok = !!machine_cnt;
    valid_style = 'alert-danger';
    info_1st_line = t('lbl.error', 'Error');
    info_txt.push(
      t(
        'msg.colorantBarcodeNotAssigned',
        'No barcode defined for colorant in database. Report to database manager that colorant {{db_cnt.cntcode}} is missing barcodes',
        { db_cnt }
      )
    );
  }

  if (selectedCnt && machine_cnt && machine_cnt.id !== selectedCnt.id) {
    barcode_validation_ok = false;
    valid_style = 'alert-danger';
    info_1st_line = t('lbl.error', 'Error');

    info_txt.push(
      t(
        'msg.canisterBarcodeValidInvalidCanisterCnt',
        'Barcode assigned to canister id {{selectedCnt.id}}({{selectedCnt.code}}) does not the read code {{machine_cnt.id}}({{machine_cnt.code}})',
        { selectedCnt, machine_cnt }
      )
    );
  }

  if (info_txt.length === 0) {
    if (
      machine?.config?.canister_barcode_confirmation &&
      machine?.config?.colorant_barcode_confirmation
    ) {
      info_txt.push(
        t(
          'msg.readColorantOrCanisterBarcode',
          'Please read (or manually input) a colorant or canister barcode'
        )
      );
    } else if (
      machine?.config?.colorant_barcode_confirmation &&
      !machine?.config?.canister_barcode_confirmation
    ) {
      info_txt.push(
        t(
          'msg.readColorantBarcode',
          'Please read (or manually input) a colorant barcode'
        )
      );
    }
  }

  return {
    db_cnt,
    valid_style,
    info_1st_line,
    info_txt,
    machine_cnt,
    barcode_validation_ok,
    cansize,
  };
}
