import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { VelocityComponent } from 'velocity-react';
class Container extends PureComponent {
  renderToggle() {
    const { animations } = this.props;

    if (!animations) {
      return this.renderToggleDecorator();
    }

    return (
      <>
        <VelocityComponent
          animation={animations.toggle.animation}
          duration={animations.toggle.duration}
        >
          {this.renderToggleDecorator()}
        </VelocityComponent>
      </>
    );
  }

  renderToggleDecorator() {
    const { style, decorators } = this.props;
    return <decorators.Toggle style={style.toggle} />;
  }

  render() {
    const { style, decorators, terminal, onClick, node } = this.props;
    return (
      <div
        data-testid={'tree_item_' + node?.name}
        onClick={onClick}
        style={node.active ? { ...style.container } : { ...style.link }}
      >
        {!terminal ? this.renderToggle() : null}
        <decorators.Header node={node} style={style.header} />
      </div>
    );
  }
}

Container.propTypes = {
  style: PropTypes.object.isRequired,
  decorators: PropTypes.object.isRequired,
  terminal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  animations: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
    .isRequired,
  node: PropTypes.object.isRequired,
};

export default Container;
