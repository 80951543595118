import Container from './Container';
import Header from './Header';
import Loading from './Loading';
import Toggle from './Toggle';

export default {
  Container,
  Header,
  Loading,
  Toggle,
};
