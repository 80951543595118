import { authenticated } from './WebRequest';

export default class CacheAPI {
  static loadZones() {
    return authenticated.get('/auth/zone/');
  }

  static loadInitZoneBundle(zoneid, siteid) {
    return authenticated.get(`/bundle/zone/${zoneid}/site/${siteid}/init_zone`);
  }

  static loadProducts(zoneid, siteid) {
    if (siteid !== undefined) {
      return authenticated.get(
        `/query/zone/${zoneid}/site/${siteid}/products_list`
      );
    }
    return authenticated.get('/query/zone/' + zoneid + '/products_list');
  }

  static loadProductGroups(zoneid) {
    return authenticated.get(`/query/zone/${zoneid}/product_groups`);
  }

  static loadCards(zoneid) {
    return authenticated.get('/query/zone/' + zoneid + '/cards_list');
  }

  static loadCanSizes() {
    return authenticated.get('/rest/cansize/');
  }

  static loadUnits() {
    return authenticated.get('/rest/unit/');
  }

  static loadColorants(zoneid) {
    return authenticated.get('/query/zone/' + zoneid + '/colorants_list/');
  }

  static loadLanguages() {
    return authenticated.get('/translations/languages/');
  }

  static loadCommentsList(zoneid) {
    return authenticated.get(`/query/zone/${zoneid}/comments_list`);
  }
}
