import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import { lab as d3Lab } from 'd3-color';
import { differenceCiede2000 } from 'd3-color-difference';

import { PreviewBox } from '../../shared/order/ColorPreview';
import { selectors as cacheSelectors } from 'js/redux/reducers/Cache';
import { makeFormatters } from '../../../redux/selectors/EIValues';
import { RGBColorToHex, findMatchLimit } from '../../../mylib/Utils';
import { handle_db_name } from '../../../redux/Utils';

function calculateColorDifference(desired_color, actual_color, matchLimits) {
  const desired_lab = d3Lab(...desired_color.lab);
  const measured_lab = d3Lab(...actual_color.lab);

  const simpleColorDifference = _.mapValues(
    desired_lab,
    (desired_value, key) => measured_lab[key] - desired_value
  );
  const ciede2000_colorDifference = differenceCiede2000(
    measured_lab,
    desired_lab
  );
  const matchLimit = findMatchLimit(matchLimits, ciede2000_colorDifference);

  return { simpleColorDifference, ciede2000_colorDifference, matchLimit };
}

function ColorDiffPanel({
  title,
  desired_color,
  actual_color,
  configurations,
  matchLimits,
}) {
  const numberFormatter = makeFormatters(configurations).EI_TYPE_NUMBER;
  const diff = calculateColorDifference(
    desired_color,
    actual_color,
    matchLimits
  );
  return (
    <Row>
      <Col xs={4}>
        <div
          data-testid="desired_color"
          className="bordered box-shadow"
          style={{
            height: '20.5vh',
            paddingTop: '5rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            borderRadius: '.5rem',
            overflow: 'hidden',
            backgroundColor: RGBColorToHex(desired_color.rgb),
          }}
        >
          <PreviewBox
            height={'10.5vh'}
            ignoreBoxShadow={true}
            color={actual_color.rgb}
          />
        </div>
      </Col>
      <Col>
        {' '}
        <div className="pr-3">
          <table className="table table-sm">
            <thead>
              <tr>
                <th width={'50%'}></th>
                <th>{title}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{'dL'}</td>
                <td>{numberFormatter.format(diff.simpleColorDifference.l)}</td>
              </tr>
              <tr>
                <td>{'da'}</td>
                <td>{numberFormatter.format(diff.simpleColorDifference.a)}</td>
              </tr>
              <tr>
                <td>{'db'}</td>
                <td>{numberFormatter.format(diff.simpleColorDifference.b)}</td>
              </tr>
              <tr>
                <td>{'dE'}</td>
                <td>
                  {numberFormatter.format(diff.ciede2000_colorDifference)}
                </td>
              </tr>
              {diff.matchLimit && (
                <tr>
                  <td>
                    {diff.matchLimit.imageid && (
                      <div>
                        <img
                          style={{
                            maxHeight: 'calc(100% - 8rem)',
                            maxWidth: 'calc(100% - 2rem)',
                          }}
                          alt="limit"
                          src={handle_db_name(
                            `/rest/image/${diff.matchLimit.imageid}/data`
                          )}
                        />
                      </div>
                    )}
                  </td>
                  <td>{diff.matchLimit.commenttext}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
}

const colorType = PropTypes.shape({
  lab: PropTypes.array.isRequired,
  rgb: PropTypes.number,
});

ColorDiffPanel.propTypes = {
  title: PropTypes.string,
  desired_color: colorType.isRequired,
  actual_color: colorType.isRequired,
  configurations: PropTypes.object.isRequired,
  matchLimits: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  configurations: state.configurations,
  matchLimits: cacheSelectors.matchLimits(state),
});

export default connect(mapStateToProps)(ColorDiffPanel);
