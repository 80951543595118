import React, { Component } from 'react';
import { customControlStyles, CustomScrollbars } from '../../../mylib/Utils';
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  CustomInput,
  Input,
  Label,
  Row,
  Col,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import machineActions from 'js/redux/reducers/Machine';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class AutoDispenser extends Component {
  handleChange = (e) => {
    e.preventDefault();
    const { driver_config } = this.props.machine;
    const index = e.target.id;
    const newArr = driver_config.alfa_dispensers.slice();
    newArr[index].value = e.target.value;

    this.props.setDriverConfig({ ...driver_config, alfa_dispensers: newArr });
  };

  addAlfaInput = () => {
    const { driver_config } = this.props.machine;
    const newArr = driver_config?.alfa_dispensers.slice();
    newArr.push(null);
    this.props.setDriverConfig({ ...driver_config, alfa_dispensers: newArr });
  };

  removeAlfaInput = () => {
    const { driver_config } = this.props.machine;
    const newArr = driver_config.alfa_dispensers.slice();
    newArr.pop();
    this.props.setDriverConfig({ ...driver_config, alfa_dispensers: newArr });
  };

  handleToggleEnable = (name) => {
    const { driver_config } = this.props.machine;
    const typeOfConnection = `${name}_enabled`;
    switch (typeOfConnection) {
      case 'next_driver_enabled':
        this.props.setDriverConfig({
          ...driver_config,
          d300_enabled: false,
          salve_enabled: false,
          e4g_enabled: false,
          e5g_enabled: false,
          [typeOfConnection]: driver_config
            ? !driver_config[typeOfConnection]
            : true,
        });
        break;
      case 'uidd_enabled':
        this.props.setDriverConfig({
          ...driver_config,
          idd_enabled: false,
          idd_gravimetricDispensing: false,
          idd_us_enabled: false,
          [typeOfConnection]: driver_config
            ? !driver_config[typeOfConnection]
            : true,
        });
        break;

      default:
        this.props.setDriverConfig({
          ...driver_config,
          [typeOfConnection]: driver_config
            ? !driver_config[typeOfConnection]
            : true,
        });
        break;
    }
  };

  handlePortChange = (port) => {
    const { driver_config } = this.props.machine;
    this.props.setDriverConfig({
      ...driver_config,
      pearls_port: port,
    });
  };

  handleGravimetricDispensing = () => {
    const { driver_config } = this.props.machine;
    this.props.setDriverConfig({
      ...driver_config,
      idd_gravimetricDispensing: driver_config
        ? !driver_config.idd_gravimetricDispensing
        : true,
    });
  };

  handleAlfa = (value, index) => {
    const {
      t,
      machine: { driver_config: driver_config },
    } = this.props;
    const regexIP =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (!regexIP.test(value)) {
      alert(t('lbl.IP_not_valid', 'The IP Address is not valid'));
      return;
    }
    const alfa_dispensers = driver_config.alfa_dispensers.slice();
    alfa_dispensers[index] = value;
    this.props.setDriverConfig({
      ...driver_config,
      alfa_dispensers: driver_config ? alfa_dispensers : null,
    });
  };

  handleSantint = (name, value) => {
    const { driver_config } = this.props.machine;
    switch (name) {
      case 'legacy':
        this.props.setDriverConfig({
          ...driver_config,
          santint_uselegacyconnection: driver_config
            ? !driver_config.santint_uselegacyconnection
            : true,
        });
        break;
      case 'registercode':
        this.props.setDriverConfig({
          ...driver_config,
          santint_registercode: value,
        });
        break;
      default:
        break;
    }
  };

  handleDirectoryChange = (name, value) => {
    const { driver_config } = this.props.machine;
    switch (name) {
      case 'idd_us':
        this.props.setDriverConfig({
          ...driver_config,
          idd_us_path: value,
        });
        break;
      default:
        this.props.setDriverConfig({
          ...driver_config,
          [`${name}_root`]: value,
        });
        break;
    }
  };

  render() {
    const { t } = this.props;
    const { driver_config } = this.props.machine;

    return (
      <Card style={{ height: 'calc((100vh - 102px) - 6rem)' }}>
        <CustomScrollbars>
          <CardBody>
            <CardHeader>
              <span>
                {t('lbl.automaticDispensers', 'Automatic Dispensers')}
              </span>
            </CardHeader>
            <Table bordered>
              <thead>
                <tr>
                  <th>{t('lbl.connection', 'Connection')}</th>
                  <th />
                  <th>
                    {t(
                      'lbl.configuration_file_directory',
                      'Configuration file/directory'
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3">
                    <h5>Corob</h5>
                  </td>
                </tr>
                <tr>
                  <td>Next-Driver</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="nextdriver"
                        type="checkbox"
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        defaultChecked={
                          driver_config && driver_config.next_driver_enabled
                        }
                        onChange={() => this.handleToggleEnable('next_driver')}
                      />
                      <Label for="nextdriver">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td colSpan="3">
                    <h5>
                      {t(
                        'lbl.oldChromafloConnections',
                        'Old Chromaflo connections'
                      )}
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td>Corob legacy</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="coroblegacy"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.d300_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('d300')}
                      />
                      <Label for="coroblegacy">
                        {t('fn.enable', 'Enable')}
                      </Label>
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>Slave</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="slave"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.slave_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('slave')}
                      />
                      <Label for="slave">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>E4G</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="E4G"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.e4g_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('e4g')}
                      />
                      <Label for="E4G">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>E5G</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="E5G"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.e5g_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('e5g')}
                      />
                      <Label for="E5G">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td colSpan="3">
                    <h5>Fluid</h5>
                  </td>
                </tr>
                <tr>
                  <td>UIDD</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="uidd"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.uidd_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('uidd')}
                      />
                      <Label for="uidd">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td colSpan="3">
                    <h5>
                      {t(
                        'lbl.oldChromafloConnections',
                        'Old Chromaflo connections'
                      )}
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td>IDD</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="IDD"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.idd_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('idd')}
                      />
                      <Label for="IDD">{t('fn.enable', 'Enable')}</Label>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="gravimetric"
                        type="checkbox"
                        defaultChecked={
                          driver_config &&
                          driver_config.idd_gravimetricDispensing
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={this.handleGravimetricDispensing}
                      />
                      <Label for="gravimetric">
                        {t(
                          'fn.enableGravimetricDispensing',
                          'Enable Gravimetric dispensing'
                        )}
                      </Label>
                    </div>
                  </td>
                  <td>
                    <Label for="IDD input">
                      {t('lbl.fileDirectory', 'File directory')}
                    </Label>
                    <Input
                      disabled={
                        (driver_config && !driver_config.idd_enabled) ||
                        this.props.accessDenied('automatic_dispensers_config')
                      }
                      id="IDD input"
                      type="text"
                      defaultValue={driver_config && driver_config.idd_root}
                      onBlur={(e) =>
                        this.handleDirectoryChange('idd', e.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>IDD US</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="IDDUS"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.idd_us_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('idd_us')}
                      />
                      <Label for="IDDUS">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td>
                    <Label for="IDDUS input">{t('lbl.file', 'File')}</Label>
                    <Input
                      disabled={
                        (driver_config && !driver_config.idd_us_enabled) ||
                        this.props.accessDenied('automatic_dispensers_config')
                      }
                      id="IDDUS input"
                      type="text"
                      defaultValue={driver_config && driver_config.idd_us_path}
                      onBlur={(e) =>
                        this.handleDirectoryChange('idd_us', e.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Others</td>
                </tr>
                <tr>
                  <td>Pearls</td>
                  <td>
                    <Row>
                      <Col xs={4} style={{ margin: 0, padding: 0 }}>
                        <div style={{ display: 'flex' }}>
                          <CustomInput
                            id="pearls"
                            type="checkbox"
                            defaultChecked={
                              driver_config && driver_config.pearls_enabled
                            }
                            disabled={this.props.accessDenied(
                              'automatic_dispensers_config'
                            )}
                            onChange={() => this.handleToggleEnable('pearls')}
                          />
                          <Label for="pearls">{t('fn.enable', 'Enable')}</Label>
                        </div>
                      </Col>
                      <Col xs={8} style={{ margin: 0, padding: 0 }}>
                        <Row>
                          <Col xs={4} style={{ margin: 0, padding: 0 }}>
                            {' '}
                            {t('lbl.port', 'Port')}:
                          </Col>
                          <Col xs={8} style={{ margin: 0, padding: 0 }}>
                            <Select
                              menuPlacement="auto"
                              styles={{
                                control:
                                  customControlStyles & { width: '200px' },
                                option: (base) => ({
                                  ...base,
                                  color: 'black',
                                }),
                              }}
                              isSearchable={false}
                              options={driver_config?.ports?.map((name) => ({
                                label: name,
                                value: name,
                              }))}
                              onChange={(value) =>
                                this.handlePortChange(value.value)
                              }
                              defaultValue={
                                driver_config?.ports?.find(
                                  (p) => p === driver_config?.pearls_port
                                )
                                  ? {
                                      label: driver_config?.pearls_port,
                                      value: driver_config?.pearls_port,
                                    }
                                  : {
                                      label: '',
                                      value: '',
                                    }
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>HERO</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="Hero"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.hero_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('hero')}
                      />
                      <Label for="Hero">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>Italtinto</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="italtinto"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.italtinto_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('italtinto')}
                      />
                      <Label for="italtinto">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td>
                    <Label for="italtinto input">
                      {t('lbl.fileDirectory', 'File directory')}
                    </Label>
                    <Input
                      disabled={
                        (driver_config && !driver_config.italtinto_enabled) ||
                        this.props.accessDenied('automatic_dispensers_config')
                      }
                      id="italtinto input"
                      type="text"
                      defaultValue={
                        driver_config && driver_config.italtinto_root
                      }
                      onBlur={(e) =>
                        this.handleDirectoryChange('italtinto', e.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>LLD</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="LLD"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.lld_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('lld')}
                      />
                      <Label for="LLD">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td>
                    <Label for="LLD input">HLD root directory</Label>
                    <Input
                      disabled={
                        (driver_config && !driver_config.lld_enabled) ||
                        this.props.accessDenied('automatic_dispensers_config')
                      }
                      id="LLD input"
                      type="text"
                      defaultValue={driver_config && driver_config.lld_root}
                      onBlur={(e) =>
                        this.handleDirectoryChange('lld', e.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Santint</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="Santint"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.santint_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('santint')}
                      />
                      <Label for="Santint">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td>
                    <Row>
                      <Col xs={4} className="pl-0 text-sm-left">
                        <Label for="Device ID:">
                          {t('lbl.deviceIdColon', 'Device ID:')}
                        </Label>
                      </Col>
                      <Col xs={8}>
                        <Input
                          disabled={true}
                          id="santintDeviceId"
                          type="text"
                          defaultValue={
                            driver_config && driver_config.santint_deviceid
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xs={4} className="pl-0 text-sm-left">
                        <Label for="Registration Code:">
                          {t('lbl.registrationCodeColon', 'Registration Code:')}
                        </Label>
                      </Col>
                      <Col xs={8}>
                        <Input
                          disabled={
                            (driver_config && !driver_config.santint_enabled) ||
                            this.props.accessDenied(
                              'automatic_dispensers_config'
                            )
                          }
                          id="santintRegisterCode"
                          type="text"
                          defaultValue={
                            driver_config && driver_config.santint_registercode
                          }
                          onBlur={(e) =>
                            this.handleSantint('registercode', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <td>Alfa</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="alfa"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.alfa_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('alfa')}
                      />
                      <Label for="alfa">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>

                  <td>
                    {driver_config?.alfa_enabled && (
                      <div>
                        <Row>
                          <Col>
                            <Button
                              style={{ width: '70%' }}
                              color={
                                driver_config.alfa_dispensers.length === 1 ||
                                this.props.accessDenied(
                                  'automatic_dispensers_config'
                                )
                                  ? 'primary'
                                  : 'danger'
                              }
                              onClick={this.removeAlfaInput}
                              disabled={
                                driver_config.alfa_dispensers.length === 1 ||
                                this.props.accessDenied(
                                  'automatic_dispensers_config'
                                )
                              }
                            >
                              {t('lbl.remove')}
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              style={{ width: '50%', float: 'right' }}
                              onClick={this.addAlfaInput}
                            >
                              {t('lbl.add')}
                            </Button>
                          </Col>
                        </Row>
                        <Row className={'pt-2'}>
                          <Col>
                            {driver_config.alfa_dispensers.map(
                              (dispenser, index) => {
                                return (
                                  <div className={'pt-2'} key={index + 'div'}>
                                    {' '}
                                    <Input
                                      className={'pt-2'}
                                      disabled={
                                        (driver_config &&
                                          !driver_config.alfa_enabled) ||
                                        this.props.accessDenied(
                                          'automatic_dispensers_config'
                                        )
                                      }
                                      key={index}
                                      id={index}
                                      type="text"
                                      onChange={this.handleChange}
                                      placeholder="xxx.xxx.xxx.xxx"
                                      defaultValue={
                                        driver_config &&
                                        driver_config.alfa_dispensers.length &&
                                        driver_config.alfa_dispensers[index]
                                      }
                                      onBlur={(e) =>
                                        this.handleAlfa(e.target.value, index)
                                      }
                                      minLength="7"
                                      maxLength="15"
                                      size="15"
                                    />{' '}
                                  </div>
                                );
                              }
                            )}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>UDCP</td>
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CustomInput
                        id="UDCP"
                        type="checkbox"
                        defaultChecked={
                          driver_config && driver_config.udcp_enabled
                        }
                        disabled={this.props.accessDenied(
                          'automatic_dispensers_config'
                        )}
                        onChange={() => this.handleToggleEnable('udcp')}
                      />
                      <Label for="UDCP">{t('fn.enable', 'Enable')}</Label>
                    </div>
                  </td>
                  <td />
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </CustomScrollbars>
      </Card>
    );
  }
}

AutoDispenser.propTypes = {
  machine: PropTypes.object.isRequired,
  setDriverConfig: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  handleOpenModal: PropTypes.func,
  accessDenied: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    machine: state.machine,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDriverConfig: machineActions.setDriverConfig,
    },
    dispatch
  );
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(AutoDispenser)
);
