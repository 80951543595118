import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import orderActions, { propType as orderType } from 'js/redux/reducers/Order';
import machineActions, {
  selectors as machineSelectors,
} from 'js/redux/reducers/Machine';
import {
  COLORCODE_MAX_LENGTH,
  DISPID_FLINK_DISPENSER,
  EXTRA_INFO_DISCOUNT_EDITOR,
  FLINK_TYPE_TDF,
  FLINK_TYPE_TDF_GRAVIMETRIC,
  FORMULA_INPUT_CARD,
  INFO_CARD,
  MACHINE_VALIDATE_CNT_MORE_THAN_CANISTER,
  MACHINE_VALIDATE_CRITICAL_LEVEL,
  MATCH_SEARCH_CARD,
  ORDER_MODE_FORMULA_CORRECTION,
  ORDER_MODE_FREE_DISPENSE,
  ORDER_MODE_LOCAL_FORMULA,
  ORDER_STATUS_DONE,
  ORDER_STATUS_PREPARING,
  ORDER_STATUS_WAITING,
  SOURCE_MAIN,
  SOURCE_USER,
  ZERO_VOLUME_THRESHOLD_ML,
  offline_mode,
} from '../../../Constants';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { RGBColorToHex } from 'js/mylib/Utils';
import Formula from './Formula';
import MachineBanner from '../../dispenser/MachineBanner';
import Extrainfo from './Extrainfo';
import _ from 'lodash';
import { TOO_MUCH_CNT } from '../../../mylib/RulesChecker';
import { isSiteUser } from '../../../api/WebRequest';
import ColorThumbnail from '../ColorThumbnail';
import { selectors as cacheSelectors } from '../../../redux/reducers/Cache';
import { selectors as protectionSelectors } from '../../../redux/reducers/Protection';
import { selectors as orderQueueSelectors } from 'js/redux/reducers/OrderQueue';
import { hasPrivilege } from '../../../mylib/Privileges';
import { push } from 'connected-react-router';
import { detectMobile, rightToLeft } from '../../../mylib/Utils';
import MobileExtraInfo from './MobileExtraInfo';
import busyActions from '../../../redux/reducers/Busy';
import { RETINT_TYPE_ORDER } from '../../../redux/reducers/Order';
import { FormulaCommentModal } from './FormulaCommentModal';

// values for state.modalsToShow
const SHOW_SMALL_DOSE_MODAL = 'SMALL_DOSE';
const SHOW_FORMULA_NOTE_MODAL = 'FORMULA_NOTE';
const SHOW_IS_PREFILLED_MODAL = 'IS_PREFILLED';

class ReadyOrder extends Component {
  state = {
    modalsToShow: [],
    notes: '',
    smallDoseCnts: [],
    original_itemid: null,
    original_notes: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.order.item.itemid !== state.original_itemid ||
      props.order.item.notes !== state.original_notes
    ) {
      return {
        notes: props.order.item.notes,
        original_itemid: props.order.item.itemid,
        original_notes: props.order.item.notes,
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.keyFunction(INFO_CARD, this.handleKeyPress);
  }

  handleSetNotes = (value) => {
    this.setState({ notes: value });
  };

  handleKeyPress = (key) => {
    if (this.props.order.item.status === ORDER_STATUS_PREPARING) {
      // Under dispensing
      return;
    }

    if (key === 'Esc') {
      return this.props.moveToPrevSection();
    }
    if (key === 'Enter') {
      // Maybe dispense on enter and save on ?
      return;
    }

    // Do not change can count if extra info discount has focus
    if (
      document.activeElement ===
      document.getElementById(EXTRA_INFO_DISCOUNT_EDITOR)
    ) {
      return;
    }

    const { ncans } = this.props.order.item;
    if (key === 'up') {
      this.props.setNumberOfCans(ncans + 1);
    } else {
      if (ncans > 1) {
        this.props.setNumberOfCans(ncans - 1);
      }
    }
  };

  mapCNTtoHex = (cnt) => {
    return RGBColorToHex(cnt.rgb);
  };

  componentDidUpdate(prevProps) {
    // Focus to dispense button
    if (this.props.show && !prevProps.show) {
      this.setState({ modalsToShow: [] });
      setTimeout(() => {
        //Focus with timeout to avoid dispensing directly!
        if (this.button) this.button.focus();
      }, 300);
    }
  }

  setMatchingSections = () => {
    this.props.setOrderMode(ORDER_MODE_FORMULA_CORRECTION);
    this.props.setOpenSection(MATCH_SEARCH_CARD);
  };

  dispensePressed = () => {
    // set machine colors
    const { item, formula } = this.props.order;
    const { config } = this.props.machine || {};
    const { config_values } = this.props;

    const modalsToShow = [];

    if (
      config_values.show_formula_note_in_popup &&
      formula?.commentnotes?.trim()
    ) {
      modalsToShow.push(SHOW_FORMULA_NOTE_MODAL);
    }

    const cnts = item.cnts
      .map((cnt) => ({
        ...cnt,
        volume: item.additionOnly ? cnt.additionvolume : cnt.volume,
      }))
      .filter(({ volume }) => volume > ZERO_VOLUME_THRESHOLD_ML);

    this.props.setDropColors(cnts.map(this.mapCNTtoHex));

    const smallDoseCnts = cnts.filter(
      (cnt) => (config?.minimum_dosable_amount || 0) > cnt.volume
    );

    if (smallDoseCnts.length) {
      this.setState({ smallDoseCnts });
      modalsToShow.push(SHOW_SMALL_DOSE_MODAL);
    }

    if (
      this.props.dispID === DISPID_FLINK_DISPENSER &&
      [FLINK_TYPE_TDF, FLINK_TYPE_TDF_GRAVIMETRIC].includes(
        config_values.flink_dispenser_type
      ) &&
      config_values.flink_tdf_ask_if_prefilled
    ) {
      modalsToShow.push(SHOW_IS_PREFILLED_MODAL);
      // NOTE: this modal must be last, now that Yes button starts dispensing
    }

    if (modalsToShow.length) {
      this.setState({ modalsToShow });
    } else {
      this.dispenseApproved();
    }
  };

  nextModal = () => {
    const modalsToShow = this.state.modalsToShow.slice(1);
    this.setState({ modalsToShow });
    if (!modalsToShow.length) {
      this.dispenseApproved();
    }
  };

  dispenseApproved = () => {
    this.props.tintOrder(this.props.dispID);
  };

  dispensePrefilled = () => {
    this.setState({ modalsToShow: [] });
    this.props.tintOrder(this.props.dispID, true);
  };

  dispenseCancelled = () => {
    this.setState({ modalsToShow: [] });
  };

  newOrderPressed = () => {
    this.props.reTintOrder(RETINT_TYPE_ORDER);
  };

  savePressed = (addItem, orderStatus = ORDER_STATUS_WAITING) => {
    const navigateTo = addItem
      ? {
          pathname: '/shopping-cart',
          state: { source: 'add-item-btn' },
        }
      : '/';
    this.props.saveOrder(orderStatus, addItem, navigateTo);
  };

  getNotes = () => {
    const { t } = this.props;
    /**
     * Get the actual notes
     */
    return (
      <Card
        className="br-8 bordered ready-grey"
        style={{
          height: '100%',
        }}
      >
        <div
          className="card-header bordered ready-grey"
          style={{
            height: '15%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.5rem 0.5rem 0px 0px',
            borderBottom: '1px solid color-white',
          }}
        >
          <h5 className="mb-0">
            <div
              className="collapsed uppercase bold-size-1 color-white"
              style={{ textAlign: 'center' }}
            >
              {t('lbl.notes', 'Notes')}
            </div>
          </h5>
        </div>
        <div
          className="card-body br-8"
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <div className="p-8">
            <p>{this.props.order?.formula?.commentnotes}</p>
          </div>
          <textarea
            className="notes-comment scroll"
            name="Add comments"
            placeholder={t('lbl.addComments', 'Add comments')}
            value={this.state.notes || ''}
            onChange={(evt) => this.handleSetNotes(evt.target.value)}
            onBlur={() => this.props.setNotes(this.state.notes)}
          />
        </div>
      </Card>
    );
  };

  /**
   * This should speed up the rendering as it blocks update on the components which are not visible!
   *
   */
  shouldComponentUpdate(nextProps) {
    return nextProps.show || this.props.show;
  }

  getModals = () => {
    const { t, order, machine, shotFormatter } = this.props;

    const small = this.state.smallDoseCnts.map((cnt) => (
      <div
        key={cnt.cntcode}
        style={{ display: 'inline-flex', marginBottom: '0.5rem' }}
      >
        <ColorThumbnail hideIfNull rgb={cnt.rgb} size="1.5rem" />
        <div style={{ paddingLeft: '1rem' }}>{cnt.cntcode} -</div>
        <div>
          {order.item.additionOnly
            ? shotFormatter.format({
                volume: cnt.additionvolume,
                specificgravity: cnt.specificgravity,
              })
            : shotFormatter.format(cnt)}
        </div>
      </div>
    ));

    return (
      <>
        <FormulaCommentModal
          show={this.state.modalsToShow[0] === SHOW_FORMULA_NOTE_MODAL}
          onOk={this.nextModal}
          onCancel={this.dispenseCancelled}
        />

        <Modal
          isOpen={this.state.modalsToShow[0] === SHOW_SMALL_DOSE_MODAL}
          toggle={this.dispenseCancelled}
          centered
        >
          <ModalHeader>
            {t('lbl.small_dose_warning', 'Small dose warning')}
          </ModalHeader>
          <ModalBody>
            <p>
              {t(
                'lbl.warn.small_dose_details',
                'Following colorants in current formula are lower than small dose warning level {{level}} (ml)',
                { level: machine?.config?.minimum_dosable_amount || 0 }
              )}
            </p>
            <div style={{ display: 'inline-grid' }}>{small}</div>
          </ModalBody>
          <ModalFooter>
            <Row className="w-100">
              <Col>
                <Button
                  id="small_dose_cansel"
                  color="secondary"
                  onClick={this.dispenseCancelled}
                >
                  {t('fn.cancel', 'Cancel')}
                </Button>
              </Col>
              <Col />

              <Col>
                <Button
                  id="small_dose_ok"
                  color="warning"
                  onClick={this.nextModal}
                >
                  {t('fn.ok', 'OK')}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalsToShow[0] === SHOW_IS_PREFILLED_MODAL}
          toggle={this.dispenseCancelled}
          centered
        >
          <ModalBody>
            {t('msg.isCanPrefilled', 'Is the can prefilled?')}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.dispensePrefilled}>
              {t('fn.yes', 'Yes')}
            </Button>
            <Button onClick={this.nextModal}>{t('fn.no', 'No')}</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  getButtons = (
    fix_formula,
    allow_dispense,
    dispense_note,
    dispense_text,
    saving_allowed
  ) => {
    const { t, config_values, order, is_pro, config, local_queue, is_ist_pro } =
      this.props;

    const add_item_visible = _.get(
      config,
      'multiple_items_in_order.value',
      false
    );

    const items =
      _.find(local_queue, ['orderid', order.orderid])?.orderitems || [];

    // If the item has an itemid we know that it's saved already.
    const add_item_enabled =
      _.get(config, 'multiple_items_in_order_num.value', 6) -
        (order.item?.itemid ? 0 : 1) >
      items.length;

    // Check color code too long
    const too_long_code = order.item?.colourcode?.length > COLORCODE_MAX_LENGTH;

    if (too_long_code) {
      dispense_note.push(
        t('frm.tooLongColorCode', 'Formula too long color code')
      );
    }

    const edit_formula = _.get(config, 'fix_formula.value', true);
    const save_custom_formula =
      order.item.ncans === 0 &&
      !order.item.additionOnly &&
      (order.item.itemEdited || order.order_mode === ORDER_MODE_LOCAL_FORMULA);

    return (
      <>
        {order.item.status === ORDER_STATUS_DONE ? (
          <>
            <Button
              data-testid="btn_new_order"
              color="primary"
              onClick={this.newOrderPressed}
              data-denied={!hasPrivilege('order_retint')}
              disabled={!hasPrivilege('order_retint')}
            >
              {t('fn.newOrder', 'New order')}
            </Button>
            {fix_formula && (
              <Button
                color="primary"
                id="actionFixFormula"
                data-testid="btn_fix_formula"
                onClick={() => {
                  this.props.navigateTo('/matching');
                  this.setMatchingSections();
                }}
                disabled={
                  !hasPrivilege('order_fix_formula') ||
                  this.props.order.order_mode === ORDER_MODE_FREE_DISPENSE ||
                  !this.props.order.color?.lab
                }
                data-denied={!hasPrivilege('order_fix_formula')}
              >
                {t('cfg.fix_formula', 'Fix formula')}
              </Button>
            )}
            <Button
              className="mt-16"
              data-testid="btn_back"
              color="warning"
              onClick={() => window.history.back()}
            >
              {t('fn.back', 'Back')}
            </Button>
          </>
        ) : (
          <>
            {!is_ist_pro && (
              <Button
                className="btn-disabled-tooltips"
                id="actionDispense"
                color="primary"
                innerRef={(input) => {
                  this.button = input;
                }}
                disabled={
                  (order.item.ncans === 0
                    ? true
                    : !allow_dispense ||
                      !hasPrivilege('order_tinting_start')) || too_long_code
                }
                data-denied={!hasPrivilege('order_tinting_start')}
                onClick={this.dispensePressed}
              >
                {dispense_text}
              </Button>
            )}
            {dispense_note.length > 0 && (
              <UncontrolledTooltip
                placement="top"
                target="actionToolTipWrapper"
              >
                {dispense_note.map((n, i) => (
                  <p key={i} style={{ margin: 0 }}>
                    {n}
                  </p>
                ))}
              </UncontrolledTooltip>
            )}

            {edit_formula && (
              <Button
                color="primary"
                id="actionEditFormula"
                data-testid="btn_edit_formula"
                onClick={() => {
                  this.props.saveOrderState();
                  const { source } = order.item;
                  this.props.setOrderitemSource(SOURCE_USER);
                  this.props.setItemEdited(true);
                  if (
                    source === SOURCE_MAIN &&
                    (config_values.custom_formula_clear_color_name ?? true)
                  ) {
                    this.props.clearColournames();
                  }
                  this.props.setOpenSection(FORMULA_INPUT_CARD);
                }}
                // uses order_fix_formula for lack of specific privilege in it3
                disabled={
                  order.ncans_tinted > 0 ||
                  !hasPrivilege('order_fix_formula') ||
                  order.item.ncans === 0 ||
                  offline_mode
                }
                data-denied={!hasPrivilege('order_fix_formula')}
              >
                {t('fn.editFormula', 'Edit formula')}
              </Button>
            )}

            {saving_allowed &&
              this.props.order.order_mode !== ORDER_MODE_FREE_DISPENSE && (
                <>
                  {is_pro && add_item_visible && (
                    <Button
                      id="actionAddItem"
                      color="secondary"
                      data-denied={!hasPrivilege('order_add_orderitem')}
                      disabled={
                        !add_item_enabled ||
                        !hasPrivilege('order_add_orderitem') ||
                        too_long_code ||
                        order.item.ncans === 0
                      }
                      onClick={() => {
                        this.savePressed(true);
                      }}
                    >
                      {t('fn.add_item', 'Add item')}
                    </Button>
                  )}
                  {save_custom_formula ? (
                    <Button
                      id="actionSaveCustomFormula"
                      color="secondary"
                      data-denied={!hasPrivilege('order_save')}
                      disabled={!hasPrivilege('order_save') || too_long_code}
                      onClick={() => {
                        this.savePressed(false, ORDER_STATUS_DONE);
                      }}
                    >
                      {t('fn.save_custom_formula', 'Save custom formula')}
                    </Button>
                  ) : (
                    <Button
                      id="actionSave"
                      color="secondary"
                      data-denied={!hasPrivilege('order_save')}
                      disabled={!hasPrivilege('order_save') || too_long_code}
                      onClick={() => {
                        this.savePressed(false);
                      }}
                    >
                      {t('fn.save_order', 'Save order')}
                    </Button>
                  )}
                </>
              )}
          </>
        )}
      </>
    );
  };

  //two functions, one for computer, one for mobile, mobile does not need buttons
  normalViewFunction = (
    fix_formula,
    allow_dispense,
    dispense_note,
    dispense_text,
    saving_allowed,
    show_machine_banner
  ) => {
    const { config_values } = this.props;
    const show_notes_panel = !config_values?.hide_notes_panel;
    const show_extra_info_panel = !config_values?.hide_extra_info_panel;

    return (
      <div
        style={{
          display: 'flex',
          height: '22rem',
          justifyContent: 'space-between',
        }}
        className={show_machine_banner ? 'mySlideOutTop' : ''}
      >
        <div style={{ flexGrow: 3, maxWidth: '50%' }} className="pl-0 pr-16">
          <Formula dispID={this.props.dispID} />
        </div>
        {show_notes_panel && (
          <div style={{ flexGrow: 1, maxWidth: '20%' }} className="pl-0 pr-16">
            {this.getNotes()}
          </div>
        )}

        {show_extra_info_panel && (
          <div
            style={{ height: '100%', flexGrow: 2, minWidth: '25%' }}
            className="pl-0 pr-16"
          >
            <Extrainfo />
          </div>
        )}
        {(isSiteUser() || this.props.is_ist_pro) && (
          <div
            id="actionToolTipWrapper"
            style={{ maxWidth: '25%' }}
            className={`p-4 ${rightToLeft() ? 'pr-16' : ''} `}
          >
            {this.getButtons(
              fix_formula,
              allow_dispense,
              dispense_note,
              dispense_text,
              saving_allowed
            )}
          </div>
        )}
      </div>
    );
  };

  //customize css for columns
  mobileViewFunction = (
    fix_formula,
    allow_dispense,
    dispense_note,
    dispense_text,
    saving_allowed
  ) => {
    return (
      <>
        <Col style={{ marginBottom: '2rem' }}>
          <Formula dispID={this.props.dispID} />
        </Col>
        <Col style={{ marginBottom: '2rem' }}>{this.getNotes()}</Col>
        <Col style={{ height: '100%' }}>
          <MobileExtraInfo />
        </Col>
        {this.props.is_ist_pro && (
          <Col className="mt-3" id="actionToolTipWrapper">
            {this.getButtons(
              fix_formula,
              allow_dispense,
              dispense_note,
              dispense_text,
              saving_allowed
            )}
          </Col>
        )}
      </>
    );
  };

  //renders the last page of ordering
  render() {
    const {
      t,
      config,
      order,
      dispID,
      machine,
      allow_matching,
      show,
      priority_init_pending,
    } = this.props;

    if (!show || priority_init_pending) {
      return null;
    }

    // validate formula
    let dispense_note = [];
    let allow_dispense = false;
    if (dispID != null && order.engine_validation != null) {
      if (
        [
          MACHINE_VALIDATE_CNT_MORE_THAN_CANISTER,
          MACHINE_VALIDATE_CRITICAL_LEVEL,
        ].includes(order.engine_validation)
      ) {
        if (_.get(machine, 'config.formula_splitting', false)) {
          allow_dispense = true;
        } else {
          allow_dispense = false;
          if (order.base_check?.warn === order.engine_validation)
            dispense_note.push(
              t(
                'msg.formulaMachineNotEnoughtBase',
                'Formula requires too much base'
              )
            );
          else
            dispense_note.push(
              t(
                'msg.formulaMachineNotEnoughtColorant',
                'Formula requires too much colorant'
              )
            );
        }
      } else {
        allow_dispense =
          order.engine_validation < MACHINE_VALIDATE_CRITICAL_LEVEL ||
          machine?.config?.remove_incompatible_colorants;
        if (!allow_dispense)
          dispense_note.push(
            t(
              'msg.formulaMachineIncompatible',
              'Formula is invalid for the tinting machine'
            )
          );
      }
    }
    // Checking if customer data is given and allow dispense
    if (_.get(config, 'force_customer_data.value', false)) {
      allow_dispense = allow_dispense && order.customer !== null;
      if (!order.customer)
        dispense_note.push(
          t('msg.customerInfoMissing', 'Please add customer information')
        );
    }

    if (_.get(config, 'enable_overfill_checking.value', false)) {
      const error_overfill =
        order.formula &&
        order.formula.rules &&
        _.includes(order.formula.rules.violations, TOO_MUCH_CNT);

      allow_dispense = allow_dispense && !error_overfill;
      if (error_overfill)
        dispense_note.push(t('frm.overfill', 'Over fill detected'));
    }

    if (_.get(config, 'prevent_formulas_breaking_rules.value', false)) {
      const break_rules =
        order.formula &&
        order.formula.rules &&
        (order.formula.rules.violations.length > 0 ||
          order.formula.rules.cnt_violations.length > 0);
      allow_dispense = allow_dispense && !break_rules;
      if (break_rules)
        dispense_note.push(t('frm.break_rules', 'Formula breaks rules'));
    }

    // Check colorants valid.
    if (
      machine &&
      !machine.config?.remove_incompatible_colorants &&
      _.difference(
        order?.formula?.cntinformula.map((x) => x.cntcode),
        machine.colorants?.map((y) => y.code)
      ).length > 0
    ) {
      allow_dispense = false;
      dispense_note.push(
        t(
          'frm.colorants_not_in_machine',
          'Formula colorants are not in tinting machine'
        )
      );
    }

    if (!machine) {
      allow_dispense = false;
      dispense_note.push(
        this.props.isMachineMissing == null
          ? t(
              'msg.waitingForMachineInit',
              'Waiting for tinting machine initialization'
            )
          : t('lbl.dispenserNotConnected')
      );
    }

    const dispense_text = window.qtside
      ? t('fn.dispense', 'Dispense')
      : t('fn.simulateDispensing', 'Simulate dispensing');

    const show_machine_banner = order.item.status === ORDER_STATUS_PREPARING;

    const saving_allowed = _.get(config, 'max_waiting_order.value', 20) > 0;
    const fix_formula =
      allow_matching && _.get(config, 'fix_formula.value', true);
    return (
      <Col style={{ display: this.props.show ? '' : 'none' }}>
        {this.getModals()}
        {/*if mobile, it uses different function for render*/}
        {detectMobile()
          ? this.mobileViewFunction(
              fix_formula,
              allow_dispense,
              dispense_note,
              dispense_text,
              saving_allowed
            )
          : this.normalViewFunction(
              fix_formula,
              allow_dispense,
              dispense_note,
              dispense_text,
              saving_allowed,
              show_machine_banner
            )}
        {dispID != null && (
          <Card
            className={
              (show_machine_banner ? 'mySlideInTop' : 'mySlideOutTop') +
              ' machine-banner-position'
            }
          >
            <CardBody>
              <Row className="no-gutters m-16">
                <MachineBanner dispID={this.props.dispID} />
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    );
  }
}

ReadyOrder.propTypes = {
  formula: PropTypes.object,
  setNotes: PropTypes.func.isRequired,
  setNumberOfCans: PropTypes.func.isRequired,
  keyFunction: PropTypes.func.isRequired,
  tintOrder: PropTypes.func.isRequired,
  saveOrder: PropTypes.func.isRequired,
  saveOrderState: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  clearOrderItem: PropTypes.func.isRequired,
  reTintOrder: PropTypes.func.isRequired,
  order: orderType,
  dispID: PropTypes.string,
  t: PropTypes.func.isRequired,
  moveToPrevSection: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  priority_init_pending: PropTypes.bool,
  setDropColors: PropTypes.func.isRequired,
  setOpenSection: PropTypes.func.isRequired,
  clearColournames: PropTypes.func.isRequired,
  setItemEdited: PropTypes.func.isRequired,
  setOrderNotes: PropTypes.func.isRequired,
  config: PropTypes.shape().isRequired,
  config_values: PropTypes.object,
  machine: PropTypes.object,
  isMachineMissing: PropTypes.bool,
  allow_matching: PropTypes.bool.isRequired,
  is_pro: PropTypes.bool,
  is_ist_pro: PropTypes.bool,
  shotFormatter: PropTypes.object,
  local_queue: PropTypes.array,
  setOrderMode: PropTypes.func,
  navigateTo: PropTypes.func.isRequired,
  setOrderitemSource: PropTypes.func.isRequired,
  setBusy: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    order: store.order,
    machine: machineSelectors.current_machine(store),
    isMachineMissing: machineSelectors.isMachineMissing(store),
    local_queue: orderQueueSelectors.local_queue(store),
    formula: store.formula,
    dispID: machineSelectors.current_dispID(store),
    config: store.configurations.config,
    config_values: store.configurations.config_values,
    shotFormatter: store.configurations.shotFormatter,
    priority_init_pending: cacheSelectors.priority_init_pending(store),
    allow_matching: protectionSelectors.allow_matching(store),
    is_pro: protectionSelectors.is_pro(store),
    is_ist_pro: protectionSelectors.is_ist_pro(store),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setNotes: orderActions.setItemNotes,
      tintOrder: orderActions.tintOrderitem,
      reTintOrder: orderActions.reTintOrder,
      clearOrder: orderActions.clearOrder,
      saveOrder: orderActions.saveOrderitem,
      saveOrderState: orderActions.saveState,
      clearOrderItem: orderActions.clearOrderItem,
      setNumberOfCans: orderActions.setNumberOfCans,
      setDropColors: machineActions.setDropColors,
      moveToPrevSection: orderActions.moveToPrevSection,
      setOpenSection: orderActions.setOpenSection,
      clearColournames: orderActions.clearColournames,
      setItemEdited: orderActions.setItemEdited,
      setOrderMode: orderActions.setOrderMode,
      setOrderNotes: orderActions.setOrderNotes,
      setOrderitemSource: orderActions.setOrderitemSource,
      navigateTo: push,
      setBusy: busyActions.setBusy,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ReadyOrder)
);
