import _ from 'lodash';
import store from '../redux/Store.js';

export const hasPrivilege = (privilege) => {
  const state = store.getState();
  const privileges = state.user?.current_user?.privileges;
  return _.includes(privileges, privilege);
};

export const hasOneofPrivileges = (privileges) => {
  for (let x in privileges) {
    if (hasPrivilege(privileges[x])) {
      return true;
    }
  }
  return false;
};
