import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { eula } from './Eula';
import PropTypes from 'prop-types';
import { CURRENT_VERSION } from '../../../../version';
import { connect } from 'react-redux';

class About extends Component {
  render() {
    const { t, version_details } = this.props;
    return (
      <Card style={{ height: 'calc((100vh - 102px) - 6rem)' }}>
        <CardHeader>{t('cfg.h_about')}</CardHeader>
        <CardBody className="scroll">
          <FormGroup row>
            <Label sm={5}>
              {t('lbl.versionNumber.colon', 'Version number:')}
            </Label>
            <Col
              sm={7}
              style={{
                paddingTop: '0.5rem',
                zIndex: '0',
                display: 'inline-flex',
              }}
            >
              <Input type="text" defaultValue={CURRENT_VERSION} disabled />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={5}>
              {t('lbl.databaseVersionNumber.colon', 'Database Version number:')}
            </Label>
            <Col
              sm={7}
              style={{
                paddingTop: '0.5rem',
                zIndex: '0',
                display: 'inline-flex',
              }}
            >
              <Input
                type="text"
                data-testid="db-version"
                defaultValue={version_details?.database_version_number}
                disabled
              />
            </Col>
          </FormGroup>

          <div className="row col-xs-12">{eula}</div>
        </CardBody>
      </Card>
    );
  }
}

About.propTypes = {
  t: PropTypes.func.isRequired,
  version_details: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    version_details: state.update_checker.version_details,
  };
}

/**
 * Wrapped with translations and connect to store.
 */
export default withTranslation('translations')(
  connect(mapStateToProps, null)(About)
);
