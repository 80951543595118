import React, { ReactNode } from 'react';

interface ModalProps {
  children?: ReactNode;
  isOpen: boolean;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        display: 'block',
        height: '100%',
        left: 0,
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: '1050',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          position: 'fixed',
          top: 50,
          left: '50%',
          margin: '1.75rem auto',
          maxWidth: 500,
          minWidth: 500,
          transform: 'translate(-50%, 0)',
          transition: 'transform .3s ease-out',
          borderRadius: '1rem',
          zIndex: 1051,
        }}
      >
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isOpen: false,
};
