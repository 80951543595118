import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { push } from 'connected-react-router';

import { PreviewBox } from '../../shared/order/ColorPreview';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import spectroActions, { selectors } from '../../../redux/reducers/Spectro';
import orderActions, {
  selectors as orderSelectors,
} from '../../../redux/reducers/Order';
import formulaActions from '../../../redux/reducers/Formula';
import { selectors as protectionSelectors } from 'js/redux/reducers/Protection';

import { intToR_G_B, RGBColorToHex } from '../../../mylib/Utils';
import './styles.scss';
import {
  COLOR_SEARCH_CARD,
  INFO_CARD,
  MATCH_SEARCH_CARD,
  ORDER_MODE_FORMULA_CORRECTION,
  ORDER_MODE_NORMAL,
} from '../../../Constants';
import ColorDiffPanel from './ColorDiffPanel';

class Measure extends Component {
  state = {
    colourcode: '',
    download_clicked: false,
  };

  componentDidMount() {
    this.props.fetchSpectroStatus();
  }

  colourCodeChangeHandler = ({ target }) => {
    this.setState({
      colourcode: target.value,
    });
  };

  continueHandler = () => {
    const { lab, rgb, xyz, refl, coldataclass, wlInterval } =
      this.props.spectro_measurement;
    const reflectance = {
      rinf: refl,
      wl0: 400, // TODO: Spectro to return start wl?
      wlstep: wlInterval,
    };

    this.props.setColor({
      colourcode: this.state.colourcode,
      lab,
      rgb,
      xyz,
      reflectance,
    });

    this.props.setColorDetails({
      colourcode: this.state.colourcode,
      lab,
      rgb,
      xyz,
      reflectance,
      coldataclass,
    });
    this.props.moveToNextSection();
  };

  correctFormula = () => {
    this.props.correctFormula();
    this.props.moveToNextSection();
  };

  backHandler = () => {
    // back to home page
    //this.props.navigateTo('/');
    // Back to orderpage
    window.history.back();
    this.setOrderSections();
  };

  setOrderSections = () => {
    this.props.setOrderMode(ORDER_MODE_NORMAL, COLOR_SEARCH_CARD);
    this.props.setOpenSection(INFO_CARD);
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.open_section !== MATCH_SEARCH_CARD
        ? this.continueHandler()
        : this.correctFormula();
    }
  };

  render() {
    const { colourcode } = this.state;
    const {
      t,
      show,
      spectro_status,
      spectro_busy,
      spectro_error,
      spectro_measurement,
      spectro_measurement_batch,
      calibrate,
      download,
      measureMain,
      measureBatch,
      allow_matching,
      spectro_downloaded_measurements,
      clear,
    } = this.props;

    const columns = [
      {},
      { text: t('lbl.colorName') },
      { text: t('lbl.color') },
    ];
    const matchSearch = this.props.open_section === MATCH_SEARCH_CARD;
    const columnWidth = spectro_status?.download ? 4 : 6;

    const show_calibration_error =
      spectro_error || (!spectro_busy && !this.state.download_clicked);

    return (
      show && (
        <div className="measure__container">
          <Row className="color-search-container">
            <Col className="color-search-panel height-100" md="6">
              <Row>
                <Col>
                  <Button
                    data-testid="btn_measure"
                    disabled={
                      (spectro_status && spectro_status.calibrationNeeded) ||
                      spectro_busy ||
                      !spectro_status
                    }
                    onClick={() => {
                      this.setState(() => ({
                        download_clicked: false,
                      }));

                      matchSearch ? measureBatch() : measureMain();
                    }}
                  >
                    {spectro_busy ? (
                      <FontAwesomeIcon icon="spinner" spin />
                    ) : matchSearch ? (
                      t('fn.measureBatch', 'Measure Batch')
                    ) : (
                      t('fn.measure')
                    )}
                  </Button>
                </Col>
                <Col md={columnWidth}>
                  {spectro_status?.calibrationSequence?.length && (
                    <Button
                      disabled={spectro_busy || !spectro_status}
                      onClick={() => {
                        this.setState(() => ({ download_clicked: false }));
                        calibrate();
                      }}
                    >
                      {spectro_busy ? (
                        <FontAwesomeIcon
                          icon="spinner"
                          style={{ display: 'inline-block' }}
                          spin
                        />
                      ) : (
                        t('fn.calibrate')
                      )}
                    </Button>
                  )}
                </Col>
                {spectro_status?.download && (
                  <Col md={columnWidth}>
                    <Button
                      data-testid="btn_memory"
                      disabled={
                        spectro_busy ||
                        !spectro_status ||
                        !spectro_status.storedCount
                      }
                      onClick={() => {
                        this.setState(() => ({ download_clicked: true }));
                        download();
                      }}
                    >
                      {spectro_busy ? (
                        <FontAwesomeIcon
                          icon="spinner"
                          style={{ display: 'inline-block' }}
                          spin
                        />
                      ) : (
                        t('fn.memory_of_spectro', 'Memory')
                      )}
                    </Button>
                  </Col>
                )}
              </Row>
              {this.state.download_clicked && !spectro_busy && (
                <div
                  className={'scroll mt-2'}
                  style={{ height: 'calc(100% - 3rem)' }}
                >
                  <Table size="md">
                    <thead style={{ textAlign: 'center' }}>
                      <tr className={'memory-color-item'}>
                        {columns.map((name, index) => (
                          <th key={name.text + index}>{name.text}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {spectro_downloaded_measurements.map(
                        (measurement, index) => (
                          <tr
                            className={'memory-color-item'}
                            key={measurement.name + index}
                            onClick={() => {
                              if (
                                this.props.order_mode ===
                                ORDER_MODE_FORMULA_CORRECTION
                              ) {
                                this.props.receiveMeasureBatch(measurement);
                              } else this.props.receiveMeasureMain(measurement);
                            }}
                          >
                            <td> {index + 1}</td>
                            <td> {measurement.name}</td>
                            <td>
                              <PreviewBox
                                color={measurement.rgb}
                                height="3.5vh"
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <div
                    className={'mt-1'}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    {!!spectro_downloaded_measurements.length && (
                      <Button
                        color="danger"
                        disabled={
                          spectro_busy ||
                          !spectro_status ||
                          !spectro_status.storedCount
                        }
                        onClick={() => {
                          this.setState(() => ({
                            download_clicked: false,
                          }));
                          clear();
                        }}
                        style={{ width: 'unset' }}
                      >
                        {spectro_busy ? (
                          <FontAwesomeIcon
                            icon="spinner"
                            style={{ display: 'inline-block' }}
                            spin
                          />
                        ) : (
                          t('fn.clearMemory_of_spectro', 'Clear memory')
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {show_calibration_error && (
                <Row className="align-items-center">
                  {spectro_error && (
                    <>
                      <FontAwesomeIcon icon="exclamation-triangle" />
                      <p className="calibrate__status">
                        {spectro_error.message}
                      </p>
                    </>
                  )}
                  <p className="calibrate__status">
                    {!spectro_busy &&
                      !this.state.download_clicked &&
                      (spectro_status
                        ? spectro_status.calibrationNeeded
                          ? t('lbl.calibrationNeeded')
                          : !spectro_measurement && t('msg.spectroMeasure')
                        : t('msg.spectroNotFound'))}
                  </p>
                </Row>
              )}
            </Col>
            <Col md="6" style={{ paddingLeft: '1.5rem', height: '100%' }}>
              <Card className="height-100">
                <CardHeader style={{ padding: '0.4rem 1.25rem' }}>
                  {t('lbl.colorDetails')}
                </CardHeader>
                {!matchSearch ? (
                  <CardBody style={{ margin: '0.75rem 1.5rem' }}>
                    {spectro_measurement && (
                      <>
                        <div className="color__preview">
                          <PreviewBox
                            color={
                              spectro_measurement
                                ? spectro_measurement.rgb
                                : null
                            }
                          />
                        </div>
                        <div className="table-responsive table-borderless float-right color-preview-table">
                          <table className="table table-borderless table-sm">
                            <thead>
                              <tr>
                                {/* <th>{color ? color.colourcode : ''}</th> */}
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {spectro_measurement && allow_matching && (
                                  <td colSpan="2">
                                    <Input
                                      data-testid="colorCodeInput"
                                      autoFocus={true}
                                      placeholder={t('prompt.typeColorCode')}
                                      bsSize="md"
                                      value={colourcode}
                                      onChange={this.colourCodeChangeHandler}
                                      onKeyDown={this.handleKeyDown}
                                    />
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td className="color__details">
                                  {t('extraInfo.rgb')}
                                </td>
                                <td>
                                  {spectro_measurement
                                    ? intToR_G_B(spectro_measurement.rgb).join(
                                        ', '
                                      )
                                    : null}
                                </td>
                              </tr>
                              <tr>
                                <td className="color__details">
                                  {t('extraInfo.rgbHex')}
                                </td>
                                <td>
                                  {spectro_measurement
                                    ? RGBColorToHex(spectro_measurement.rgb)
                                    : ''}
                                </td>
                              </tr>
                              <tr>
                                <td />
                                <td>
                                  <Button
                                    id="matching_continue"
                                    disabled={!spectro_measurement}
                                    className="color__btn--continue"
                                    onClick={this.continueHandler}
                                  >
                                    {t('fn.continue', 'Continue')}
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </CardBody>
                ) : (
                  <CardBody style={{ marginLeft: '20px' }}>
                    {this.props.color?.lab &&
                      spectro_measurement_batch?.lab &&
                      !spectro_busy && (
                        <>
                          <ColorDiffPanel
                            title={t('lbl.before_formulaCorrection', 'Before')}
                            desired_color={this.props.color}
                            actual_color={spectro_measurement_batch}
                          />
                          <Row className="pt-3">
                            <Col xs={6} className="text-center">
                              <Button
                                color="warning"
                                id="matching_back"
                                onClick={this.backHandler}
                              >
                                {t('fn.back', 'Back')}
                              </Button>
                            </Col>
                            <Col xs={6} className="text-center">
                              <Button
                                id="formula_correct_continue"
                                onClick={this.correctFormula}
                              >
                                {t('fn.continue')}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

Measure.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  spectro_busy: PropTypes.bool.isRequired,
  spectro_status: PropTypes.object,
  spectro_error: PropTypes.object,
  spectro_measurement: PropTypes.object,
  spectro_measurement_batch: PropTypes.object,
  spectro_downloaded_measurements: PropTypes.array,
  calibrate: PropTypes.func,
  download: PropTypes.func,
  clear: PropTypes.func,
  measureMain: PropTypes.func,
  measureBatch: PropTypes.func,
  fetchSpectroStatus: PropTypes.func,
  setColorDetails: PropTypes.func.isRequired,
  setColor: PropTypes.func.isRequired,
  correctFormula: PropTypes.func.isRequired,
  open_section: PropTypes.string,
  order_mode: PropTypes.string,
  moveToNextSection: PropTypes.func.isRequired,
  allow_matching: PropTypes.bool.isRequired,
  receiveMeasureMain: PropTypes.func,
  receiveMeasureBatch: PropTypes.func,
  formula: PropTypes.object,
  setOrderMode: PropTypes.func,
  setOpenSection: PropTypes.func,
  navigateTo: PropTypes.func.isRequired,
  color: PropTypes.object,
};

const mapStateToProps = (state) => ({
  spectro_measurement: selectors.measurement(state),
  spectro_measurement_batch: selectors.measurement_batch(state),
  spectro_downloaded_measurements: selectors.downloaded_measurements(state),
  spectro_busy: selectors.busy(state),
  spectro_status: selectors.status(state),
  spectro_error: selectors.error(state),
  open_section: orderSelectors.open_section(state),
  order_mode: orderSelectors.order_mode(state),
  color: orderSelectors.color(state),
  allow_matching: protectionSelectors.allow_matching(state),
  formula: state.formula,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      calibrate: spectroActions.calibrate,
      fetchSpectroStatus: spectroActions.fetchStatus,
      measureMain: spectroActions.measureMain,
      measureBatch: spectroActions.measureBatch,
      download: spectroActions.fetchDownload,
      clear: spectroActions.clearStoredMeasurements,
      receiveMeasureMain: spectroActions.receiveMeasureMain,
      receiveMeasureBatch: spectroActions.receiveMeasureBatch,
      setColorDetails: formulaActions.setColorDetails,
      correctFormula: formulaActions.correctFormula,
      setColor: orderActions.setColor,
      moveToNextSection: orderActions.moveToNextSection,
      setOrderMode: orderActions.setOrderMode,
      setOpenSection: orderActions.setOpenSection,
      navigateTo: push,
    },
    dispatch
  );
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(Measure)
);
