import JSONRPC from 'jsonrpc-dispatch';

export class TokenApi {
  // dummy dispatcher that resolves all calls to null
  constructor() {
    // dummy dispatcher that resolves all calls to null
    this.jsonrpc = { request: () => new Promise((resolve) => resolve(null)) };
  }

  connect(qtobject) {
    // connecting all machine actions here
    try {
      this.jsonrpc = new JSONRPC((message) =>
        qtobject.dispatch(JSON.stringify(message))
      );
      qtobject.response.connect((str) =>
        this.jsonrpc.handleResponse(JSON.parse(str))
      );
    } catch (e) {
      console.log(e);
      this.jsonrpc = { request: () => new Promise((resolve) => resolve(null)) };
    }
  }

  getToken() {
    return this.jsonrpc.request('get_token');
  }

  setToken(token) {
    return this.jsonrpc.request('set_token', [token]);
  }

  getRefreshToken() {
    return this.jsonrpc.request('get_refresh_token');
  }

  setRefreshToken(token) {
    return this.jsonrpc.request('set_refresh_token', [token]);
  }
}
const api = new TokenApi();

export { api as default };
