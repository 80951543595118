import React, { ReactNode, CSSProperties } from 'react';

interface ModalHeaderProps {
  children?: ReactNode;
  style?: CSSProperties;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  style = {},
}) => {
  return (
    <div
      style={{
        ...style,
        alignItems: 'flex-start',
        borderBottom: '1px solid #dee2e6',
        borderTopLeftRadius: 'calc(1rem - 1px)',
        borderTopRightRadius: 'calc(1rem - 1px)',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',
        lineHeight: 1.5,
        marginBottom: 0,
        fontWeight: 1000,
        fontSize: '1.25rem',
      }}
    >
      <h5 style={{ margin: 0 }}>{children}</h5>
    </div>
  );
};

ModalHeader.defaultProps = {
  style: {},
};
