import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import machineActions, {
  propType as machineType,
  selectors as machineSelectors,
} from 'js/redux/reducers/Machine';
import barcodeActions from 'js/redux/reducers/BarcodeAction';
import ColorantBarcode from './ColorantBarcode';
import { ArrayFindOneOrNull } from '../../../mylib/Utils';
import { selectors as userSelectors } from '../../../redux/reducers/User';
import BatchNumber from './BatchNumber';
import ColorThumbnail from '../../shared/ColorThumbnail';
import inventoryActions from '../../../redux/reducers/Inventory';
import Spinner from '../../shared/Spinner';
import { validate_machine_barcode } from './BarcodeValidator';

const NO_NEED_TO_CHECK = 'NO_NEED';

class MachineRefillModal extends React.Component {
  constructor(props) {
    super(props);

    this.batchNumber = React.createRef();
    this.state = {
      original_barcode_refill_action: null,
      original_barcode_canister_action: null,
      canister_barcode: '',
      colorant_barcode: '',
      batch_number: '',
      batch_note: '',
    };
    this.timeout = 0;
  }

  handleBatchNumberChange = (e) => {
    this.setState({ batch_number: e.target.value });
  };

  handleBatchNoteChange = (e) => {
    this.setState({ batch_note: e.target.value });
  };

  static getDerivedStateFromProps(props, state) {
    let new_state = { ...state };
    if (props.barcode_refill_action) {
      if (
        !_.isEqual(
          props.barcode_refill_action,
          state.original_barcode_refill_action
        )
      ) {
        new_state = {
          ...new_state,
          original_barcode_refill_action: _.cloneDeep(
            props.barcode_refill_action
          ),
        };
      }
    }

    if (props.barcode_canister_action) {
      if (
        !_.isEqual(
          props.barcode_canister_action,
          state.original_barcode_canister_action
        )
      ) {
        new_state = {
          ...new_state,

          original_barcode_canister_action: _.cloneDeep(
            props.barcode_canister_action
          ),
        };
      }
    }
    if (props.barcode_canister_action) {
      if (
        !_.isEqual(
          props.barcode_canister_action,
          state.original_barcode_canister_action
        )
      ) {
        new_state = {
          ...new_state,
          canister_barcode: props.barcode_canister_action.barcode || '',
          original_barcode_canister_action: _.cloneDeep(
            props.barcode_canister_action
          ),
        };
      }
    }

    if (props.barcode_refill_action) {
      if (
        !_.isEqual(
          props.barcode_refill_action,
          state.original_barcode_refill_action
        )
      ) {
        new_state = {
          ...new_state,
          colorant_barcode: props.barcode_refill_action.barcode || '',
          original_barcode_refill_action: _.cloneDeep(
            props.barcode_refill_action
          ),
        };
      }
    }

    return new_state;
  }

  clear_actions = () => {
    this.props.clearBarcodeActions(this.props.dispID);
    this.props.setMachineRefillModalVisible(this.props.dispID, false);

    // Small workaround for hiding the modal.
    this.setState({
      original_barcode_refill_action: null,
      original_barcode_canister_action: null,
      canister_barcode: '',
      colorant_barcode: '',
      batch_number: '',
      batch_note: '',
    });
  };

  get_amount_to_add = (get_cansize = false) => {
    const { cache, machine, config, barcode_refill_action, barcode_details } =
      this.props;

    // Initial values
    let to_add =
      _.get(config, 'refill_custom_refill_amount.value', 1000) *
      _.get(config, 'refill_factor.value', 1);
    let machine_cnt = ArrayFindOneOrNull(
      machine.colorants,
      'cntid',
      barcode_refill_action?.cntid
    );

    /**
     * Barcode validation
     */
    let cansize = null;
    if (
      machine?.config?.colorant_barcode_confirmation ||
      _.get(config, 'enable_warehousing_v4.value', false)
    ) {
      machine_cnt = ArrayFindOneOrNull(
        machine.colorants,
        'barcode',
        this.state.canister_barcode
      );
      cansize = barcode_refill_action
        ? ArrayFindOneOrNull(
            cache.cansizes,
            'cansizeid',
            barcode_refill_action.cansizeid
          )
        : null;

      if (!cansize && barcode_details) {
        cansize = ArrayFindOneOrNull(
          cache.cansizes,
          'cansizeid',
          barcode_details.cansizeid
        );
      }
      if (cansize) {
        to_add =
          cansize.nominalamount * _.get(config, 'refill_factor.value', 1);
      }
      if (machine_cnt) {
        if (machine_cnt.currLevel + to_add > machine_cnt.maxLevel) {
          to_add = 0;
        }
      }
    }
    if (get_cansize) {
      return { cansize, to_add };
    }
    return to_add;
  };

  save_changes = () => {
    const { machine, dispID, barcode_refill_action, current_user, config } =
      this.props;

    // Initial values
    const { cansize, to_add } = this.get_amount_to_add(true);

    let machine_cnt = ArrayFindOneOrNull(
      machine.colorants,
      'cntid',
      barcode_refill_action?.cntid
    );

    /**
     * Barcode validation
     */
    if (machine?.config?.colorant_barcode_confirmation) {
      machine_cnt =
        ArrayFindOneOrNull(
          machine.colorants,
          'barcode',
          this.state.canister_barcode
        ) || machine_cnt;
    }

    if (!machine_cnt) {
      machine_cnt = ArrayFindOneOrNull(
        machine.colorants,
        'barcode',
        this.state.canister_barcode
      );
    }
    /**
     * Batch number saving
     */
    if (
      machine?.config?.batch_refill ||
      _.get(config, 'enable_warehousing_v4.value', false)
    ) {
      const colorantRefillCans = machine_cnt?.refillCans || [];
      colorantRefillCans[colorantRefillCans.length - 1]
        ? (colorantRefillCans[colorantRefillCans.length - 1] = {
            dispID,
            canisterIndex: machine_cnt.id,
            volume: to_add,
            machineid: machine.dbinfo.machineid,
            date: new Date().toISOString(),
            note: this.state.batch_note,
            user: current_user?.username ? current_user?.username : 'Guest',
            batch: this.state.batch_number,
          })
        : (colorantRefillCans[0] = {
            dispID,
            canisterIndex: machine_cnt.id,
            volume: to_add,
            machineid: machine.dbinfo.machineid,
            date: new Date().toISOString(),
            note: this.state.batch_note,
            user: current_user?.username ? current_user?.username : 'Guest',
            batch: this.state.batch_number,
          });
      if (!machine_cnt.refillCans) {
        machine_cnt.refillCans = colorantRefillCans;
      }
    }

    machine_cnt.currLevel += to_add;

    this.props.setMachineColorantsQT(dispID, machine.colorants);

    // Remove one canister from inventory
    if (_.get(config, 'enable_warehousing_v4.value', false) && cansize) {
      this.props.reportConsumption({
        cntcode: machine_cnt.code,
        cansizecode: cansize.cansizecode,
      });
    }

    this.clear_actions();
  };

  searchColorantBarcode = (evt) => {
    let searchText = evt.target.value; // this is the search text
    this.setState({ colorant_barcode: searchText });

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search
      this.props.barcodeDetails(searchText);
    }, 300);
  };

  searchCanisterBarcode = (evt) => {
    let searchText = evt.target.value; // this is the search text
    this.setState({ canister_barcode: searchText });
  };

  getCanisterBarcode = () => {
    const { selectedCnt, machine } = this.props;
    const cnt_index = selectedCnt
      ? machine.colorants.findIndex((cnt) => cnt.code === selectedCnt.code)
      : null;

    return cnt_index ? machine.config.canister_barcodes[cnt_index] : null;
  };

  render() {
    const {
      t,
      machine,
      barcode_refill_action,
      selectedCnt,
      barcode_details,
      barcode_details_pending,
      barcode_canister_action,

      config,
      cache,
    } = this.props;

    let machine_cnt = selectedCnt
      ? selectedCnt
      : ArrayFindOneOrNull(
          machine.colorants,
          'cntid',
          barcode_refill_action?.cntid
        );

    /**
     * Barcode validation
     */
    if (
      machine?.config?.colorant_barcode_confirmation &&
      this.state.canister_barcode &&
      !selectedCnt
    ) {
      machine_cnt =
        ArrayFindOneOrNull(
          machine.colorants,
          'barcode',
          this.state.canister_barcode
        ) || machine_cnt;
    }

    /**
     * Barcode validation
     *
     */
    let valid_style = '';
    let info_1st_line = t('lbl.pleaseInput', 'Please fill in the fields');
    let info_txt = [];

    let barcode_validation_ok =
      machine?.config?.colorant_barcode_confirmation ||
      this.state.canister_barcode ||
      this.state.colorant_barcode ||
      _.get(config, 'enable_warehousing_v4.value', false)
        ? false
        : NO_NEED_TO_CHECK;

    const machine_canister_barcode = this.getCanisterBarcode();

    const barcode_rs = validate_machine_barcode(
      this.state.colorant_barcode.trim(),
      this.state.canister_barcode.trim(),
      barcode_refill_action?.barcode === this.state.colorant_barcode
        ? barcode_refill_action
        : null,
      barcode_details,
      barcode_canister_action?.barcode === this.state.canister_barcode
        ? barcode_canister_action
        : null,
      config,
      cache,
      machine,
      t,
      selectedCnt,
      machine_canister_barcode
    );

    valid_style = barcode_rs?.valid_style || valid_style;
    info_1st_line = barcode_rs?.info_1st_line || info_1st_line;
    info_txt = barcode_rs?.info_txt || info_txt;
    barcode_validation_ok = barcode_rs
      ? barcode_rs.barcode_validation_ok
      : barcode_validation_ok;

    /**
     * Batch validation
     */
    let batch_validation_ok = machine?.config?.batch_refill
      ? false
      : NO_NEED_TO_CHECK;

    const batch_rs = this.batchNumber?.current?.validate(
      this.state.batch_number
    );

    batch_validation_ok = batch_rs
      ? batch_rs.batch_validation_ok
      : batch_validation_ok;

    if (!batch_validation_ok && batch_rs) {
      valid_style = batch_rs?.valid_style || valid_style;
      info_1st_line = batch_rs?.info_1st_line || info_1st_line;
      info_txt.push(batch_rs.info_txt);
    }

    const ok_to_save =
      (batch_validation_ok || batch_validation_ok === NO_NEED_TO_CHECK) &&
      (barcode_validation_ok || barcode_validation_ok === NO_NEED_TO_CHECK);

    return (
      <Modal
        className="customer-width"
        isOpen={this.props.visible}
        toggle={this.clear_actions}
        centered
        fade={true}
        size="lg"
      >
        <ModalHeader>
          <div style={{ display: 'inline-flex' }}>
            <div>{t('fn.refillColorant', 'Refill colorant')}</div>
            <div style={{ display: 'inline-flex', marginLeft: '3rem' }}>
              <div style={{ width: '32px' }} className="pl-4">
                <ColorThumbnail rgb={machine_cnt?.rgb} size="24px" />
              </div>
              {machine_cnt?.code}
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          {barcode_details_pending ? (
            <Row className={'mb-8 br-8'} style={{ minHeight: '6rem' }}>
              <Col
                className="m-auto align-text-center"
                style={{ fontSize: '2rem' }}
              >
                <Spinner />
              </Col>
            </Row>
          ) : (
            <Row
              className={valid_style + '  mb-8 br-8'}
              style={{ minHeight: '6rem' }}
            >
              <Col className="m-auto">
                <h5 className="align-text-center" data-testid="info_1st_line">
                  {info_1st_line}
                </h5>
                <div className="align-text-center" data-testid="info_2nd_line">
                  {info_txt.map((info, i) => (
                    <p className={'m-0 p-0'} key={i}>
                      {info}
                    </p>
                  ))}
                </div>
              </Col>
            </Row>
          )}

          {(machine?.config?.colorant_barcode_confirmation ||
            machine?.config?.canister_barcode_confirmation ||
            this.state.canister_barcode ||
            this.state.colorant_barcode ||
            machine_cnt ||
            _.get(config, 'enable_warehousing_v4.value', false)) && (
            <ColorantBarcode
              require_machine_canister_barcode={
                machine?.config?.canister_barcode_confirmation
              }
              machine_cnt={barcode_rs?.machine_cnt}
              cansize={barcode_rs?.cansize}
              searchColorantBarcode={this.searchColorantBarcode}
              searchCanisterBarcode={this.searchCanisterBarcode}
              canister_barcode={this.state.canister_barcode}
              colorant_barcode={this.state.colorant_barcode}
            />
          )}
          {machine?.config?.batch_refill && (
            <BatchNumber
              ref={this.batchNumber}
              cnt={machine_cnt}
              handleBatchNumberChange={this.handleBatchNumberChange}
              handleBatchNoteChange={this.handleBatchNoteChange}
              batch_number={this.state.batch_number}
              batch_note={this.state.batch_note}
              selectedCnt={selectedCnt}
              to_add_volume={this.get_amount_to_add(false)}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <Row className="w-100">
            <Col>
              <Button
                color="danger"
                id="actionCancel"
                onClick={this.clear_actions}
              >
                {t('fn.cancel', 'Cancel')}
              </Button>
            </Col>
            <Col />
            <Col>
              <Button
                color="primary"
                id="actionOk"
                disabled={!ok_to_save}
                onClick={this.save_changes}
              >
                {t('fn.ok', 'OK')}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

MachineRefillModal.propTypes = {
  t: PropTypes.func.isRequired,
  clearBarcodeActions: PropTypes.func.isRequired,
  setMachineColorantsQT: PropTypes.func.isRequired,
  machine: machineType,
  cache: PropTypes.shape({
    cnts: PropTypes.array,
    cansizes: PropTypes.array,
  }),
  barcode_refill_action: PropTypes.shape({
    cntid: PropTypes.number,
    cansizeid: PropTypes.number,
    barcode: PropTypes.string,
  }),
  barcode_details: PropTypes.shape({
    cansizeid: PropTypes.number,
  }),
  barcode_details_pending: PropTypes.bool,
  current_user: PropTypes.shape({
    username: PropTypes.string,
  }),
  barcodeDetails: PropTypes.func.isRequired,
  dispID: PropTypes.string,
  config: PropTypes.object,
  barcode_canister_action: PropTypes.shape({
    barcode: PropTypes.string,
  }),
  selectedCnt: PropTypes.shape({
    code: PropTypes.string,
    cntid: PropTypes.number,
    barcode: PropTypes.string,
  }),
  barcodeaction: PropTypes.shape({
    action: PropTypes.string,
  }),
  visible: PropTypes.bool,
  setMachineRefillModalVisible: PropTypes.func.isRequired,
  reportConsumption: PropTypes.func.isRequired,
};

MachineRefillModal.defaultProps = {};

function mapStateToProps(store, ownProps) {
  return {
    cache: store.cache,
    config: store.configurations.config,
    barcode_details: store.barcodeaction.barcode_details,
    barcode_details_pending: store.barcodeaction.barcode_details_pending,
    barcodeaction: store.barcodeaction.barcodeaction,
    current_user: userSelectors.current_user(store),
    visible: machineSelectors.refillModalVisible(store, ownProps.dispID),
    inventory: store.inventory.colorants,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearBarcodeActions: machineActions.clearBarcodeActions,
      setMachineColorantsQT: machineActions.setMachineColorantsQT,
      setMachineRefillModalVisible: machineActions.setMachineRefillModalVisible,
      barcodeDetails: barcodeActions.barcodeDetails,
      reportConsumption: inventoryActions.reportConsumption,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(MachineRefillModal)
);
