import { authenticated, authenticated_cached } from './WebRequest';
import { omitNil } from 'js/mylib/Utils';
// endpoint root

export default class FormulaAPI {
  static colorByCode(colourcode) {
    return authenticated.get('/rest/colour/', {
      params: { colourcode, '.limit': 1 },
    });
  }

  static searchColors(
    zoneid,
    colourcardid,
    search,
    search_by_both_color_codes
  ) {
    // NOTE: must use omitNil with cached queries
    const params = omitNil({
      search,
      colourcardid,
      search_by_both_color_codes,
    });
    return authenticated_cached.get('/query/zone/' + zoneid + '/colours/', {
      params,
    });
  }
  static searchColorsForProduct(
    systemgroupid,
    zoneid,
    productid,
    colourcardid,
    search,
    search_by_both_color_codes
  ) {
    const params = omitNil({
      search,
      colourcardid,
      search_by_both_color_codes,
    });
    return authenticated_cached.get(
      `/query/systemgroup/${systemgroupid}/zone/${zoneid}/colours_for_product/` +
        (productid || ''),
      { params }
    );
  }
  static cardsForProduct(systemgroupid, zoneid, productid) {
    return authenticated_cached.get(
      `/query/systemgroup/${systemgroupid}/zone/${zoneid}/cards_for_product/` +
        productid
    );
  }
  static searchColorsForMatching(
    systemgroupid,
    zoneid,
    productid,
    colourcardid,
    search,
    search_by_both_color_codes
  ) {
    const params = omitNil({
      search,
      colourcardid,
      search_by_both_color_codes,
    });
    return authenticated_cached.get(
      `/query/systemgroup/${systemgroupid}/zone/${zoneid}/colours_for_matching/` +
        productid,
      { params }
    );
  }
  static searchMeasuredColors(
    zoneid,
    colourcardid,
    search,
    search_by_both_color_codes
  ) {
    const params = omitNil({
      search,
      colourcardid,
      search_by_both_color_codes,
    });
    return authenticated_cached.get(
      '/query/zone/' + zoneid + '/measured_colours/',
      { params }
    );
  }
  static searchProducts(systemgroupid, zoneid, colourid) {
    // NOTE: must use omitNil with cached queries
    //'/query/zone/<int:zoneid>/products_for_colour/<int:colourid>'
    return authenticated_cached.get(
      `/query/systemgroup/${systemgroupid}/zone/${zoneid}/products_for_colour/` +
        (colourid || '')
    );
  }

  static loadFormula(systemgroupid, payload) {
    return authenticated.get(
      `/query/systemgroup/${systemgroupid}/formula_versions/${payload.colourid}/${payload.productid}`
    );
  }

  static formulaAsOrderitem(
    systemgroupid,
    zoneid,
    colourid,
    productid,
    cansizeid
  ) {
    return authenticated.get(
      `/query/systemgroup/${systemgroupid}/zone/${zoneid}/formula_as_orderitem/` +
        `${colourid}/${productid}/${cansizeid}`
    );
  }

  static matchingSearchClosest(
    zoneid,
    siteid,
    refl,
    lab,
    productid,
    cansizeid,
    nominalamount,
    gravimetric,
    colourid,
    coldataclass,
    max_de,
    maxresults
  ) {
    return authenticated.post(`/matching/zone/${zoneid}/search_closest`, {
      siteid,
      refl,
      lab,
      productid,
      cansizeid,
      nominalamount,
      gravimetric,
      colourid,
      coldataclass,
      max_de,
      maxresults,
    });
  }

  static matchingMatch(
    zoneid,
    siteid,
    refl,
    productid,
    cansizeid,
    nominalamount,
    gravimetric,
    msname,
    baseids,
    cntids,
    maxresults,
    max_de
  ) {
    return authenticated.post(`/matching/zone/${zoneid}/match`, {
      siteid,
      refl,
      productid,
      cansizeid,
      nominalamount,
      gravimetric,
      msname,
      baseids,
      cntids,
      maxresults,
      max_de,
    });
  }

  static matchingCorrectFormula(
    zoneid,
    siteid,
    productid,
    cansizeid,
    nominalamount,
    gravimetric,
    formula,
    stdrefl,
    batchrefl,
    msname
  ) {
    return authenticated.post(`/matching/zone/${zoneid}/correct_formula`, {
      siteid,
      productid,
      cansizeid,
      nominalamount,
      gravimetric,
      formula,
      stdrefl,
      batchrefl,
      msname,
    });
  }

  static matchingSynthesizeRgb(zoneid, productid, formula) {
    return authenticated.post(`/matching/zone/${zoneid}/synthesize_rgb`, {
      productid,
      formula,
    });
  }

  static fetchColorDetails(zoneid, colourid) {
    return authenticated.get(
      '/query/zone/' + zoneid + '/colour_details/' + colourid
    );
  }

  static searchLocalFormulas(zoneid, siteid, search) {
    const params = omitNil({ search });
    return authenticated.get(
      `/query/zone/${zoneid}/site/${siteid}/local_formulas/`,
      { params }
    );
  }

  static hideLocalFormula(siteid, itemid) {
    return authenticated.post(
      `/query/site/${siteid}/hide_local_formula/item/${itemid}`
    );
  }

  static loadCompatibleLocalFormula(zoneid, itemid, baseid) {
    return authenticated.get(
      `/query/zone/${zoneid}/compatible_local_formula/itemid/${itemid}/baseid/${baseid} `
    );
  }
}
