import _ from 'lodash';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EIValues from 'js/redux/selectors/EIValues';
import { EI_VARIABLES_EXC_INC_TAX } from 'js/mylib/EIVariables';
import orderActions from 'js/redux/reducers/Order';
import {
  EXTRA_INFO_DISCOUNT,
  EXTRA_INFO_DISCOUNT_EDITOR,
} from '../../../Constants';
import CurrencySelect from './CurrencySelect';
import NumberInput from '../../../mylib/NumberInput';
import { rightToLeft } from '../../../mylib/Utils';
import log from '../../../api/Logger';

const propTypes = {
  t: PropTypes.func.isRequired,
  configurations: PropTypes.object,
  config_values: PropTypes.object,
  order: PropTypes.object,
  setDiscount: PropTypes.func.isRequired,
  forcePriceoptionLot: PropTypes.bool,
};

const defaultProps = {};

function ExtrainfoTable(props) {
  const { configurations, config_values, order, t, forcePriceoptionLot } =
    props;

  if (order.price_pending) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <FontAwesomeIcon icon="spinner" spin style={{ fontSize: '4rem' }} />
      </div>
    );
  }

  const { enabled_pricetypes } = config_values;
  if (!enabled_pricetypes) {
    return null;
  }

  const names = _.fromPairs(
    EI_VARIABLES_EXC_INC_TAX().map((x) => [x.code, x.name])
  );
  const values = EIValues(order, configurations, true, forcePriceoptionLot);

  const alternative_values =
    order.item.alternative_price &&
    EIValues(
      {
        ...order,
        item: { ...order.item, price: order.item.alternative_price },
      },
      configurations,
      true,
      forcePriceoptionLot
    );
  try {
    let codes = enabled_pricetypes
      .split(';')
      .filter((x) => Object.prototype.hasOwnProperty.call(names, x));

    let discountInput = null;

    if (
      codes.includes(EXTRA_INFO_DISCOUNT) &&
      config_values.priceoption_discount
    ) {
      // Add discount input field

      discountInput = (
        <tr style={{ textAlign: rightToLeft() ? 'right' : '' }}>
          <td>{t('extraInfo.discount', 'Discount')}</td>
          <td colSpan={100}>
            <InputGroup style={{ minWidth: '80px' }}>
              <NumberInput
                className="height-1-75rem discount-input form-control"
                id={EXTRA_INFO_DISCOUNT_EDITOR}
                value={props.order.item.discount * 100}
                min={0}
                max={100}
                decimals={2}
                onChange={(value) => props.setDiscount(value / 100)}
                style={{
                  borderRadius: rightToLeft() ? '0 8px 8px 0' : '8px 0 0 8px',
                }}
              />

              <InputGroupAddon addonType="append">
                <InputGroupText
                  className="height-1-75rem"
                  style={{
                    borderRadius: rightToLeft() ? '8px 0 0 8px' : '0 8px 8px 0',
                  }}
                >
                  %
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </td>
        </tr>
      );
    }

    const extra_info = codes.map((code) => {
      return (
        <React.Fragment key={code}>
          {code === EXTRA_INFO_DISCOUNT && discountInput}
          <tr style={{ textAlign: rightToLeft() ? 'right' : '' }}>
            <td>{names[code]}</td>
            <td data-testid={code + '_value'}>{values[code]}</td>
            {alternative_values && (
              <td data-testid={code + '_alt_value'}>
                {alternative_values[code]}
              </td>
            )}
          </tr>
        </React.Fragment>
      );
    });

    return (
      <Table
        className="table table-ready-grey"
        style={{
          width: '95%',
          margin: 'auto',
          whiteSpace: 'pre-wrap',
        }}
      >
        <tbody>
          <CurrencySelect />

          {extra_info}
        </tbody>
      </Table>
    );
  } catch (e) {
    log.error(e);
    return <div>Error</div>;
  }
}

ExtrainfoTable.propTypes = propTypes;
ExtrainfoTable.defaultProps = defaultProps;

function mapStateToProps(store) {
  return {
    order: store.order,
    configurations: store.configurations,
    config_values: store.configurations.config_values,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDiscount: orderActions.setDiscount,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(ExtrainfoTable)
);
