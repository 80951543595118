import React from 'react';
import { useTranslation } from 'react-i18next';

import { MailTemplateToHtml } from '../../functions';

import { Modal } from './Modal';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';

type TypeEIValue = {
  key: string;
  value: string;
};

type Props = {
  template: string;
  ei_values: TypeEIValue[];
  toggleModal: () => void;
  isOpen: boolean;
};

export const PreviewModal = ({
  template,
  ei_values,
  toggleModal,
  isOpen,
}: Props): JSX.Element => {
  const preview = MailTemplateToHtml(template, ei_values);
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('lbl.preview', 'Preview')}</ModalHeader>
      <ModalBody>
        <div
          style={{
            border: '2px solid black',
            backgroundColor: 'white',
            color: 'black',
            zoom: '60%',
          }}
          dangerouslySetInnerHTML={{ __html: preview }}
        ></div>
      </ModalBody>
      <ModalFooter>
        <button
          className={'em-cancel-button'}
          onClick={toggleModal}
          data-testid="cancel"
        >
          {t('fn.cancel', 'Cancel')}
        </button>
      </ModalFooter>
    </Modal>
  );
};
