import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputNumberFormatter } from './NumberFormatter';
import log from '../api/Logger';

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_value: {}, // forces derived state
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (value !== state.old_value) {
      const formatter = new InputNumberFormatter(props.decimals);
      const strValue = (props.emptyIsNull ? value != null : value)
        ? formatter.format(value)
        : '';
      return {
        strValue,
        old_value: value,
        formatter,
      };
    }
    return null;
  }

  value() {
    return this.state.formatter.parse(this.state.strValue);
  }

  setValue(numericValue) {
    this.setState({ strValue: this.state.formatter.format(numericValue) });
  }

  limit(numericValue) {
    const { min, max } = this.props;
    let value = numericValue;
    if (min != null) value = Math.max(value, min);
    if (max != null) value = Math.min(value, max);
    return value;
  }

  add(increment) {
    this.setValue(this.limit(this.value() + increment));
  }

  _onChange = (e) => {
    try {
      const strValue = e.target.value;
      if (this.state.formatter.validate(strValue)) {
        this.setState({ strValue });
      }
    } catch (e) {
      log.error(e);
    }
  };

  _onBlur = () => {
    let limited = null;
    if (!this.props.emptyIsNull || this.state.strValue) {
      const value = this.value();
      limited = this.limit(value);
      if (value !== limited) {
        this.setValue(limited);
      }
    }

    this.props.onChange(limited);
  };

  _onKeyUp = (e) => {
    if (e.key === 'Enter') this._onBlur();
  };

  _onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault(); // prevents moving caret to front
        this.add(this.props.step);
        break;
      case 'ArrowDown':
        this.add(-this.props.step);
        break;
    }
  };

  render() {
    const { className, style, disabled, readOnly, emptyIsNull } = this.props;

    return (
      <input
        id={this.props.id}
        data-testid={this.props.dataTestid}
        type="text"
        autoComplete="off"
        className={className}
        style={style}
        onKeyUp={this._onKeyUp}
        onKeyDown={this._onKeyDown}
        placeholder={emptyIsNull ? undefined : '0'}
        value={this.state.strValue}
        onChange={this._onChange}
        onBlur={this._onBlur}
        disabled={disabled}
        readOnly={readOnly}
      />
    );
  }
}

NumberInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.any,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.number,
  emptyIsNull: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  decimals: PropTypes.number,
  dataTestid: PropTypes.string,
};

NumberInput.defaultProps = {
  step: 1,
  decimals: 6,
};

export default NumberInput;
