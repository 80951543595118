import React, { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from './Modal';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';

type Props = {
  barcode_types: string[];
  data_key: string;
  toggleModal: () => void;
  isOpen: boolean;
  editorRef: MutableRefObject<null>;
};

export const AddBarcodeModal = ({
  barcode_types,
  data_key,
  toggleModal,
  isOpen,
  editorRef,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [size, setSize] = useState(200);
  const [type, setType] = useState(barcode_types[0]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('lbl.add_barcode', 'Add barcode')}</ModalHeader>
      <ModalBody>
        <div className={'em-flex-div'}>
          <p
            className={'em-paragraph'}
            style={{ color: 'black', width: '8rem' }}
          >
            {t('lbl.barcodefield.colon', 'Barcode field:')}
          </p>
          <input
            className={'em-input-field'}
            value={data_key}
            type="text"
            readOnly
          ></input>
        </div>
        <div className={'em-flex-div'}>
          <p
            className={'em-paragraph'}
            style={{ color: 'black', width: '8rem' }}
          >
            {t('lbl.barcode_type.colon', 'Barcode type:')}
          </p>
          <select
            className={'em-select'}
            value={type}
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => setType(e.target.value)}
          >
            {barcode_types.map((e, index) => (
              <option key={index} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div className={'em-flex-div'}>
          <p
            className={'em-paragraph'}
            style={{ color: 'black', width: '8rem' }}
          >
            {t('lbl.name.colon', 'Name:')}
          </p>
          <input
            className={'em-input-field'}
            value={size}
            type="number"
            data-testid="TemplateName"
            onChange={(e: { target: { value: string } }) =>
              setSize(parseInt(e.target.value))
            }
          ></input>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className={'em-cancel-button'}
          onClick={toggleModal}
          data-testid="cancel"
        >
          {t('fn.cancel', 'Cancel')}
        </button>

        <button
          onClick={() => {
            // @ts-ignore
            editorRef.current.insertContent(`[[${data_key}|${type}|${size}]]`);
            toggleModal();
          }}
          className="em-save-button"
        >
          {t('fn.ok', 'Ok')}
        </button>
      </ModalFooter>
    </Modal>
  );
};
