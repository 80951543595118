import {
  ILLUMINANTS10,
  ILLUMINANTS20,
  WHITEPOINTS,
  WHITEPOINTS1931,
} from './ColorConsts';

function dot_product(a, b) {
  return a.map((x, i) => x * b[i]).reduce((acc, x) => acc + x, 0);
}

/**Computes XYZ values from reflectance.
 *
 * @param {array} refl 16 or 31 reflectance values [0..100.0]
 * @param {number} illumIdx illuminant index [0..2]
 * @returns {array} 3 numbers
 **/
export function refl_to_xyz(refl, illumIdx = 0) {
  let ill;
  if (refl.length === 16) ill = ILLUMINANTS20[illumIdx];
  else if (refl.length === 31) ill = ILLUMINANTS10[illumIdx];
  else return null;

  return ill.map(
    // each of X,Y,Z is a weighted sum of refl
    (weights) => dot_product(refl, weights) / 100
  );
}

/** Computes CIELAB values from XYZ values.
 *
 * @param {array} xyz
 * @param {number} illumIdx illuminant index [0..2]
 * @returns {array} 3 numbers
 */
export function xyz_to_lab(xyz, illumIdx = 0) {
  const [x, y, z] = xyz;
  const [x0, y0, z0] = WHITEPOINTS[illumIdx];
  const f = (v) => (v > 0.008856 ? v ** 0.333333 : 7.787 * v + 16 / 116);

  const yr = f(y / y0);

  const L = 116 * yr - 16;
  const a = 500 * (f(x / x0) - yr);
  const b = 200 * (yr - f(z / z0));
  return [L, a, b];
}

/** Convert from XYZ to RGB
 *
 * @param {array} xyz X,Y,Z for D65/10°
 * @returns {number} rgb as an int
 */
export function xyz_to_rgb(xyz) {
  return rgb_to_int(xyz1931_to_rgb(cat02_transform(xyz, 0)));
}

function _srgb_gamma(x) {
  if (x > 0.0031308) {
    return 1.055 * x ** (1 / 2.4) - 0.055;
  }
  return x * 12.92;
}

function _clip_and_round(x) {
  const r = Math.round(x);
  if (r < 0) return 0;
  if (r > 255) return 255;
  return r;
}

/** Convert from XYZ  to RGB.
 * returns a tuple of float scaled to *BUT NOT LIMITED TO* range [0..255.]
 *
 * @param {array} xyz
 * @returns {array} 3 numbers
 */
function xyz1931_to_rgb(xyz) {
  const [X, Y, Z] = xyz;

  const var_X = X / 100;
  const var_Y = Y / 100;
  const var_Z = Z / 100;

  const var_R = _srgb_gamma(var_X * 3.2406 + var_Y * -1.5372 + var_Z * -0.4986);
  const var_G = _srgb_gamma(var_X * -0.9689 + var_Y * 1.8758 + var_Z * 0.0415);
  const var_B = _srgb_gamma(var_X * 0.0557 + var_Y * -0.204 + var_Z * 1.057);

  const R = var_R * 255;
  const G = var_G * 255;
  const B = var_B * 255;

  return [R, G, B];
}

function rgb_to_int(rgb) {
  const [r, g, b] = rgb;
  return (
    (_clip_and_round(r) << 16) | (_clip_and_round(g) << 8) | _clip_and_round(b)
  );
}

function _xyz_to_cat02_rgb(xyz) {
  const [X, Y, Z] = xyz;
  const R = 0.7328 * X + 0.4296 * Y - 0.1624 * Z;
  const G = -0.7036 * X + 1.6975 * Y + 0.0061 * Z;
  const B = 0.003 * X + 0.0136 * Y + 0.9834 * Z;
  return [R, G, B];
}

function _cat02_rgb_to_xyz(rgb) {
  const [R, G, B] = rgb;
  const X = 1.096124 * R - 0.278869 * G + 0.182745 * B;
  const Y = 0.454369 * R + 0.473533 * G + 0.072098 * B;
  const Z = -0.009628 * R - 0.005698 * G + 1.015326 * B;
  return [X, Y, Z];
}

function _cat02_scaling(illumIdx) {
  const b = _xyz_to_cat02_rgb(WHITEPOINTS[illumIdx]);
  return _xyz_to_cat02_rgb(WHITEPOINTS1931[0]).map((a, i) => a / b[i]);
}

/**  CAT02 transform from CIECAM02
 * 
  Converts XYZ values from other illuminant, 10 degree (1964) observer
  to corresponding XYZ in D65, 2 degree (1931) observer
 * 
 * @param {array} xyz 
 * @param {number} illumIdx 
 * @returns {array}
 */
function cat02_transform(xyz, illumIdx) {
  const rgb = _xyz_to_cat02_rgb(xyz);
  const adjusted = _cat02_scaling(illumIdx).map((x, i) => x * rgb[i]);
  return _cat02_rgb_to_xyz(adjusted);
}

export function isDarkColor(rgb) {
  const [r, g, b] = rgb;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma < 80;
}
