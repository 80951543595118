import React from 'react';
import { TopBar, TopBarLeft } from '../layout/Containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { propType as customerType } from 'js/redux/reducers/Customer';
import { t } from 'i18next';
import PropTypes from 'prop-types';

class CustomerInfo extends React.Component {
  render() {
    const { customer, goBack } = this.props;
    return (
      <>
        <TopBar>
          <TopBarLeft>
            <h2
              id="backToHomePage"
              className={
                (goBack ? 'clickable-text' : '') + ' directly-over-bg mt-8'
              }
              onClick={goBack ? this.props.goBack : null}
            >
              {goBack && (
                <FontAwesomeIcon
                  icon="arrow-left"
                  style={{ fontSize: '1.7rem' }}
                />
              )}{' '}
              {customer.customername}
            </h2>
          </TopBarLeft>
        </TopBar>
        <div
          style={{
            color: 'white',
            wordWrap: 'break-word',
            marginLeft: '1rem',
            display: 'grid',
            rowGap: '0.5rem',
          }}
        >
          <Row>
            <Col xs={5} className={'p-0 m-0'}>
              <Row>
                <Col xs={5} className={'pr-0'}>
                  {t('lbl.companyName.colon', 'Company name:')}
                </Col>
                <Col xs={7} className={'pr-0'}>
                  {customer?.companyname ? customer.companyname : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={5} className={'pr-0'}>
                  {t('lbl.email.colon', 'Email:')}
                </Col>
                <Col xs={7} className={'pr-0'}>
                  {customer?.email ? customer.email : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={5} className={'pr-0'}>
                  {t('lbl.address.colon', 'Address:')}
                </Col>
                <Col xs={7} className={'pr-0'}>
                  {customer?.address1 ? customer.address1 : ''}
                </Col>
              </Row>
            </Col>
            <Col xs={4} className={'p-0 m-0'}>
              <Row>
                <Col xs={6} className={'pr-0'}>
                  {t('lbl.phone.colon', 'Phone number:')}
                </Col>
                <Col xs={6} className={'pr-0'}>
                  {customer?.phone ? customer.phone : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={'pr-0'}>
                  {t('lbl.city.colon', 'City:')}
                </Col>
                <Col xs={6} className={'pr-0'}>
                  {customer?.address2 ? customer.address2 : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={'pr-0'}>
                  {t('lbl.zipCode.colon', 'Zip:')}
                </Col>
                <Col xs={6} className={'pr-0'}>
                  {customer?.postcode ? customer.postcode : ''}
                </Col>
              </Row>
            </Col>
            <Col className={'p-0 m-0'}>
              <Row>
                <Col xs={6} className={'pr-0'}>
                  {' '}
                  {t('lbl.country.colon', 'Country:')}
                </Col>
                <Col xs={6} className={'pr-0'}>
                  {customer?.country}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
CustomerInfo.propTypes = {
  customer: customerType,
  goBack: PropTypes.func,
};
export default withTranslation('translations')(
  connect(null, null)(CustomerInfo)
);
