import React, { ReactNode, CSSProperties } from 'react';

interface ModalBodyProps {
  children?: ReactNode;
  style?: CSSProperties;
}

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  style = {},
}) => {
  return (
    <div
      style={{
        ...style,
        flex: '1 1 auto',
        padding: '1rem',
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
};

ModalBody.defaultProps = {
  style: {},
};
