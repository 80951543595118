/**
 * Expand abbreviations in formula comments.
 *
 * @param {string} comment For example state.order.formula.fcomment
 * @param {object} commentTexts state.cache.commentTexts
 */
export function expandComments(comment, commentTexts) {
  const split = (comment || '').split(',').map((x) => x.trim());
  const expanded = split.map((code) => {
    const text = commentTexts[code.toLowerCase()];
    return text != null ? text : code;
  });
  return expanded.join(', ');
}

/**
 * Expand abbreviations in formula comments.
 *
 * @param {string} comment For example state.order.formula.fcomment
 * @param {object} commentTexts state.cache.commentTexts
 * @param {object} commentImageIds state.cache.commentImageIds
 */
export function expandCommentsAndImages(
  comment,
  commentTexts,
  commentImageIds
) {
  const split = (comment || '').split(',').map((x) => x.trim());
  return split.map((code) => {
    const text = commentTexts[code.toLowerCase()] || code;
    const imageId = commentImageIds[code.toLowerCase()];
    return { text, imageId };
  });
}
