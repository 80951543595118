import { offline_mode } from '../Constants';
import { unauthenticated, authenticated, refresh_token } from './WebRequest';

export class LoginAPI {
  login(payload) {
    return unauthenticated.post('/auth', payload);
  }

  logout() {
    return authenticated.get('/logout');
  }

  refresh() {
    if (offline_mode) {
      // Not possible to send in fallback mode
      return null;
    }
    return refresh_token.post('/refresh');
  }
}

const api = new LoginAPI();

export { api as default };
