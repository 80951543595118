import _ from 'lodash';
import { call, put } from 'redux-saga/effects';
import WebRequest from './sagas/WebRequest';
import { fulfilled, rejected } from './factories/ApiCall';
import errorActions from './reducers/Errors';
import i18n from '../localization/i18n';
import { DB_NAME } from '../Constants';

export function* general_request(apifunc, action) {
  try {
    const params = { ...action.payload };
    const { data } = yield call(WebRequest, apifunc(params));
    yield put({ type: fulfilled(action.type), payload: data });
  } catch (e) {
    yield put({ type: rejected(action.type), payload: e });
    yield put(
      errorActions.showCriticalError(
        i18n.t(
          'msg.ErrorInServerConnection',
          'Unable to load data. Check connection to server.'
        ),
        e.response?.data?.error || e.response || e
      )
    );
  }
}

export function globalizeSelectors(selectors, mountPath) {
  const result = {};

  // eslint-disable-next-line
  for (const funcName in selectors) {
    const func = selectors[funcName];
    result[funcName] = (state, ...args) =>
      func(_.get(state, mountPath), ...args);
  }
  return result;
}

export function handle_db_name(url) {
  // Adds database name to query URL
  return DB_NAME ? `/db/${DB_NAME}${url}` : url;
}
