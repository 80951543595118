import React, { Component } from 'react';
import { Container } from 'reactstrap';
import ProductSearch from '../shared/order/ProductSearch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import orderActions, { propType as orderType } from 'js/redux/reducers/Order';
import {
  COLOR_SEARCH_CARD,
  INFO_CARD,
  PRODUCT_SEARCH_CARD,
  AMOUNT_CARD,
  FORMULA_SEARCH_CARD,
  PRODUCT_SEARCH_MODE_MATCH,
  FORMULA_INPUT_CARD,
  MATCH_SEARCH_CARD,
  ORDER_MODE_FORMULA_CORRECTION,
} from '../../Constants';
import { configure, GlobalHotKeys } from 'react-hotkeys';
import { selectors as machineSelectors } from 'js/redux/reducers/Machine';
import TopActionButtons from '../shared/TopActions';
import Ready from '../shared/order/Ready';
import FormulaInput from '../shared/order/FormulaInput';
import PropTypes from 'prop-types';
import ReadyCircles, {
  ColorCircle,
  ProductCircle,
  BaseCircle,
  CansizeCircle,
  NCansCircle,
  SwitchMachineCircle,
} from '../shared/order/ReadyCircles';
import Amounts from '../shared/order/Amounts';
import Measure from './measure/Measure';
import { MDBAnimation } from 'mdbreact';
import MatchingResults from './MatchingResults';
import CorrectionResults from './CorrectionResults';
import { isSiteUser } from '../../api/WebRequest';

class MatchingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorNameEdit: false,
    };
    this.keyEventFunctions = {};
    configure({
      //https://github.com/greena13/react-hotkeys#Configuration
      ignoreTags: ['select', 'textarea'],
    });
  }

  toggleEditMode = () => {
    this.setState((prevState) => ({
      colorNameEdit: !prevState.colorNameEdit,
    }));
  };

  handleChangeEditMode = (value) => {
    this.setState({
      colorNameEdit: value,
    });
  };

  addKeyEventFunction = (key, func) => {
    this.keyEventFunctions[key] = func;
  };

  keyPressedInOpenSection = (key) => {
    // Ignore in case when customer dialog is open
    const { order } = this.props;
    if (order.customer_modal_open) return;

    // Calling all keyboard actions from single place
    if (this.keyEventFunctions[order.open_section])
      this.keyEventFunctions[order.open_section](key);
  };

  componentDidMount() {
    this.props.setProductSearchMode(PRODUCT_SEARCH_MODE_MATCH);
  }

  render() {
    const keyMap = {
      UP: 'ArrowUp',
      DOWN: 'ArrowDown',
      ENTER: 'Enter',
    };

    const handlers = {
      UP: () => this.keyPressedInOpenSection('up'),
      DOWN: () => this.keyPressedInOpenSection('down'),
      ENTER: () => this.keyPressedInOpenSection('Enter'),
    };

    const { order } = this.props;

    return (
      <MDBAnimation type="zoomIn" duration="200ms">
        <TopActionButtons />
        <div className="d-flex flex-column">
          <Container className="justify-content-center">
            <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
            <ReadyCircles
              colorNameEdit={this.state.colorNameEdit}
              toggleEditMode={this.toggleEditMode}
              handleChangeEditMode={this.handleChangeEditMode}
              inMatching
              moveProductFirst={false}
              animationClass="mySlideInLeft"
              dispID={this.props.dispID}
            >
              <ColorCircle
                disabled={order.order_mode === ORDER_MODE_FORMULA_CORRECTION}
              />
              <ProductCircle
                disabled={
                  !order.color ||
                  order.order_mode === ORDER_MODE_FORMULA_CORRECTION
                }
              />
              <CansizeCircle
                disabled={
                  !order.product ||
                  order.order_mode === ORDER_MODE_FORMULA_CORRECTION ||
                  order.open_section === FORMULA_INPUT_CARD
                }
              />
              <BaseCircle
                disabled={
                  !order.can ||
                  order.order_mode === ORDER_MODE_FORMULA_CORRECTION
                }
                goToSection={FORMULA_SEARCH_CARD}
              />
              <NCansCircle
                disabled={
                  !order.formula ||
                  !order.can ||
                  !order.base ||
                  order.order_mode === ORDER_MODE_FORMULA_CORRECTION
                }
              />
              {Object.keys(this.props.machines).length > 2 && isSiteUser() && (
                <SwitchMachineCircle
                  disabled={!order.formula || !order.can || !order.base}
                />
              )}
            </ReadyCircles>

            <Measure
              keyFunction={this.addKeyEventFunction}
              show={
                order.open_section === COLOR_SEARCH_CARD ||
                order.open_section === MATCH_SEARCH_CARD
              }
            />
            {order.open_section === PRODUCT_SEARCH_CARD && (
              <ProductSearch keyFunction={this.addKeyEventFunction} />
            )}
            <Amounts
              keyFunction={this.addKeyEventFunction}
              show={order.open_section === AMOUNT_CARD}
            />
            <MatchingResults
              keyFunction={this.addKeyEventFunction}
              show={
                order.open_section === FORMULA_SEARCH_CARD &&
                order.order_mode !== ORDER_MODE_FORMULA_CORRECTION
              }
            />
            <CorrectionResults
              keyFunction={this.addKeyEventFunction}
              show={
                order.open_section === FORMULA_SEARCH_CARD &&
                order.order_mode === ORDER_MODE_FORMULA_CORRECTION
              }
            />
            <Ready
              keyFunction={this.addKeyEventFunction}
              show={order.open_section === INFO_CARD}
            />
            <FormulaInput
              keyFunction={this.addKeyEventFunction}
              freeDispense={false}
              show={order.open_section === FORMULA_INPUT_CARD}
            />
          </Container>
        </div>
      </MDBAnimation>
    );
  }
}

MatchingPage.propTypes = {
  order: orderType,

  cache: PropTypes.shape({}),

  login: PropTypes.shape({
    site_logged_in: PropTypes.bool,
  }),

  setNumberOfCans: PropTypes.func.isRequired,
  moveToNextSection: PropTypes.func.isRequired,
  setProductSearchMode: PropTypes.func,
  dispID: PropTypes.string,
  machines: PropTypes.object,
};

function mapStateToProps(store) {
  return {
    order: store.order,
    cache: store.cache,
    login: store.login,
    dispID: machineSelectors.current_dispID(store),
    machines: machineSelectors.machines(store),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setNumberOfCans: orderActions.setNumberOfCans,
      moveToNextSection: orderActions.moveToNextSection,
      setProductSearchMode: orderActions.setProductSearchMode,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchingPage);
