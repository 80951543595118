import 'lodash.combinations';
import _ from 'lodash';
import { FIXED_ABASEAMOUNT } from '../Constants';
import log from '../api/Logger';

// violations
export const TOO_MUCH_CNT = 'TOO_MUCH_CNT';
export const TOO_LITTLE_CNT = 'TOO_LITTLE_CNT';
export const NOT_IN_SYSTEM = 'NOT_IN_SYSTEM';
export const BAD_COMBINATION = 'BAD_COMBINATION';
export const TOO_MUCH_VOC = 'TOO_MUCH_VOC';
export const BAD_CNT = 'BAD_CNT';
export const BAD_BASE = 'BAD_BASE';
export const EMPTY_FORMULA = 'EMPTY_FORMULA'; // in blended base

// These constants are used to adjust rules to allow for rounding error
const GT1 = 1.0000000001;
const LT1 = 0.9999999999;

export function CheckRules(product, base, formula) {
  /**
         * Checks formula and returns detailed results in dict:

        {'cnt_violations': {cntid: set of violation codes where colorant is to blame,
                            ...},
         'violations': set of violation codes where whole formula is to blame,
         'warnings': set of allowed violations (TOO_MUCH_CNT when overfill enabled),
         'voc': VOC level of formula (float, g/l)
         'fill': fill level of formula, volumetric or gravimetric,
             relative to nominal fill (float)
        }*/

  let result = {
    cnt_violations: [],
    violations: [],
    warnings: [],
    voc: null,
    fill: null,
  };

  const basecnts = formula.cntinformula.filter((x) => x.abaseid !== null);
  const cntset = formula.cntinformula.map((x) => x.cntid);
  const basecntset = _.intersection(cntset, basecnts);
  const cntcntset = _.difference(cntset, basecnts);

  // Check cnts in system
  cntcntset.forEach((x) => {
    if (!_.includes(product.cntinsystem, x)) {
      result.cnt_violations.push({ cntid: x, rule: NOT_IN_SYSTEM });
    }
  });

  // Check combinations
  _.combinations(cntset, 2).forEach((x) => {
    if (
      product.deniedcntpairs.filter(
        (p) =>
          (p[0] === x[0] && p[1] === x[1]) || (p[0] === x[1] && p[1] === x[0])
      ).length > 0
    ) {
      result.cnt_violations.push({ cntid: x[0], rule: BAD_COMBINATION });
      result.cnt_violations.push({ cntid: x[1], rule: BAD_COMBINATION });
    }
  });

  /** Blended base case. TODO: Implement at some point!
        if self.prodinfo[prodid]['blended_abaseid'] is not None:  # blended base

            return self._check_blended(result, formula, prodid, basecntset,
                                            check_base, check_basecnt, check_voc)

  */

  basecntset.forEach((x) => {
    result.cnt_violations.push({ cntid: x, rule: BAD_CNT });
  });

  // Fill calculation
  const fill =
    (_.sum(
      formula.cntinformula.map(
        (x) =>
          x.volume *
          (base.gravimetricfill ? x.specificgravity : 1) *
          (x.wetvolume ?? 1)
      )
    ) *
      base.coefficient) /
    (FIXED_ABASEAMOUNT * (base.gravimetricfill ? base.specificgravity : 1));

  if (base.minfill && fill < base.minfill * LT1) {
    result.violations.push(TOO_LITTLE_CNT);
  }
  if (base.maxfill && fill > base.maxfill * GT1) {
    if (product.overfill) {
      result.warnings.push(TOO_MUCH_CNT);
    } else {
      result.violations.push(TOO_MUCH_CNT);
    }
  }

  result.fill = fill;

  // Voc calculation
  if (product.voclimit && base.voc) {
    let cntvoc = 0;
    try {
      cntvoc = _.sum(formula.cntinformula.map((x) => x.volume * x.voc));
    } catch (e) {
      log.error(e);
    }
    const voc =
      (FIXED_ABASEAMOUNT * base.voc + base.coefficient * cntvoc) /
      (FIXED_ABASEAMOUNT +
        base.coefficient * _.sum(formula.cntinformula.map((x) => x.volume)));
    if (voc > product.voclimit * GT1) {
      result['violations'].push(TOO_MUCH_VOC);
    }
    result['voc'] = voc;
  }

  // Basecnt rule checking
  if (base.basecntrule) {
    const valid_rules = base.basecntrule.filter((x) =>
      _.includes(cntset, x.cntid)
    );
    for (let rule of valid_rules) {
      const vol =
        _.sum(
          formula.cntinformula
            .filter((c) => c.cntid === rule.cntid)
            .map((x) => x.volume * (x.wetvolume ?? 1))
        ) * base.coefficient;

      if (
        rule.minamount &&
        vol < (rule.minamount * LT1 * FIXED_ABASEAMOUNT) / 100
      ) {
        result.cnt_violations.push({
          cntid: rule.cntid,
          rule: TOO_LITTLE_CNT,
        });
      }
      if (
        rule.maxamount != null &&
        vol > (rule.maxamount * GT1 * FIXED_ABASEAMOUNT) / 100
      ) {
        result.cnt_violations.push({ cntid: rule.cntid, rule: TOO_MUCH_CNT });
      }
    }
  }

  return result;
}

/*
function _check_blended( result, formula, prodid, basecntset,
                            check_base=True, check_basecnt=True, check_voc=True):
        '''Check rules for blended products. Control is transferred here when
        product is blended.
        '''
        # require base colorants in product
        for c in basecntset:
            self._load_baseinfo(self.basecnts[c], {prodid})
            if prodid not in self.baseinfo[self.basecnts[c]]:
                result['cnt_violations'][c].add(BAD_BASE)

        if check_base or check_basecnt or check_voc:
            baseinfo = self.baseinfo[formula['ABASEID']]
            #filter products by MINFILL and MAXFILL
            basevolumes = dict((c['CNTID'], c['AMOUNT'])
                                   for c in formula['CNTINFORMULA']
                                   if c['CNTID'] in basecntset)
            basevolume = sum(basevolumes.itervalues())

            cntvolumes = dict((c['CNTID'], c['AMOUNT'])
                                   for c in formula['CNTINFORMULA']
                                   if c['CNTID'] not in basecntset)
            cntvolume = sum(cntvolumes.itervalues())

            if not (basevolume + cntvolume):
                result['violations'].add(EMPTY_FORMULA)
                return result

            pbinfo = baseinfo[prodid]
            pbinfos = dict((c, self.baseinfo[self.basecnts[c]][prodid]) for c in basecntset)
            if check_base:
                overfill = self.prodinfo[prodid]['overfill']
                gravimetric = pbinfo['GRAVIMETRIC']
                if gravimetric:
                    cntmass = sum(am * self.cntinfo[cid]['SG']
                                  for cid, am in cntvolumes.iteritems())
                    basemass = sum(am * pbinfos[c]['SG'] for c, am in basevolumes.iteritems())
                    fill = cntmass / (basemass + cntmass)
                else:
                    fill = cntvolume / (basevolume + cntvolume)
                if fill < pbinfo['MIN']:
                    result['violations'].add(TOO_LITTLE_CNT)
                if pbinfo['MAX'] is not None and fill > pbinfo['MAX']:
                    result['warnings' if overfill else 'violations'].add(TOO_MUCH_CNT)
                result['fill'] = fill

            voclimit = self.prodinfo[prodid]['voclimit']
            if check_voc and voclimit is not None:
                try:
                    cntvoc = sum(v * self.cntinfo[k]['VOC']
                                 for k,v in cntvolumes.iteritems())
                    basevoc = sum(v * pbinfos[k]['VOC']
                                 for k,v in basevolumes.iteritems())
                except TypeError:   # occurs if one of the VOC rates is None
                    pass
                else:
                    voc = ((basevoc + cntvoc) /
                           (basevolume + cntvolume))
                    if voc > voclimit:
                        result['violations'].add(TOO_MUCH_VOC)
                    result['voc'] = voc

            basecntrule = pbinfo['BASECNTRULE']
            if check_basecnt and basecntrule:
                volmul = 1.0 / (basevolume + cntvolume)
                for cntid, am in cntvolumes.items()+basevolumes.items():
                    rule = basecntrule.get(cntid)
                    if rule:
                        mulam = volmul * am
                        if mulam < rule['MIN']:
                            result['cnt_violations'][cntid].add(TOO_LITTLE_CNT)
                        if rule['MAX'] is not None and mulam > rule['MAX']:
                            result['cnt_violations'][cntid].add(TOO_MUCH_CNT)

        return result
*/
