import React from 'react';
import PropTypes from 'prop-types';
import { MACHINE_CAP_CLOSE, MACHINE_CAP_OPEN } from '../../../Constants';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from 'reactstrap';
import initializeImg from 'img/actions/initialization.svg';
import resetImg from 'img/actions/reset.svg';
import stirImg from 'img/actions/stir.svg';
import stirAllImg from 'img/actions/stirAll.svg';
import cleanImg from 'img/actions/clean.svg';
import cleanAllImg from 'img/actions/cleanAll.svg';
import capOpenImg from 'img/actions/autoCapOpen.svg';
import capCloseImg from 'img/actions/autoCapClose.svg';
import { propType as machineType } from 'js/redux/reducers/Machine';
import _ from 'lodash';
import { CustomScrollbars } from '../../../mylib/Utils';

const propTypes = {
  t: PropTypes.func.isRequired,
  machine: machineType,
  dispID: PropTypes.string.isRequired,
  config: PropTypes.object,
  stir: PropTypes.func.isRequired,
  stirAll: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  cap: PropTypes.func.isRequired,
  clean: PropTypes.func,
  cleanAll: PropTypes.func,
  resetHard: PropTypes.func,
  privileges: PropTypes.arrayOf(PropTypes.string).isRequired,
  commands: PropTypes.object,
};

const defaultProps = {};
const DEFAULT_STIRRING_DURATION = 30;

export default class MachineActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canId: 1,
      show_stirring_modal: false,
      stirring_func: undefined,
      stirring_func_params: [],
      stirring_duration: DEFAULT_STIRRING_DURATION,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      show_stirring_modal: !prevState.show_stirring_modal,
    }));
  };

  checkAllCnts = () => {
    this.setState((prevState) => {
      if (prevState.all)
        return {
          ...prevState,
          cnts_to_purge: [],
          all: !prevState.all,
        };
      return {
        ...prevState,
        cnts_to_purge: _.cloneDeep(
          this.props.machine.colorants.filter((cnt) => cnt.enabled)
        ),
        all: !prevState.all,
      };
    });
  };

  accessDenied = (key) => {
    const { privileges } = this.props;
    return !_.includes(privileges, key);
  };

  stirCanister = () => {
    this.props.stir(
      this.props.dispID,
      this.state.canId,
      this.state.stirring_duration
    );
  };

  selectCanId = (e) => {
    this.setState({ canId: parseInt(e.target.value) });
  };

  getInitReset = () => {
    const { t, commands } = this.props;
    if (!commands.reset && !commands.reset_hard && !commands.initialize) {
      return null;
    } else {
      return (
        <Card style={{ marginRight: '0.5rem' }}>
          <CardHeader>
            {t('fn.machineInitializationReset', 'Initialization & reset')}
          </CardHeader>
          <CardBody>
            <Row>
              {commands.initialize ? (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_init')}
                    data-denied={this.accessDenied('machine_command_init')}
                    className={'box-shadow btn btn-action btn-machine-action '}
                    onClick={() => this.props.initialize(this.props.dispID)}
                  >
                    <img src={initializeImg} alt="Init" className="img-fit" />
                  </button>
                  <p style={{ marginBottom: 0, height: '1.5rem' }}>
                    {t('fn.machineInitialization', 'Initialization')}
                  </p>
                </Col>
              ) : null}
              {commands.reset ? (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_reset')}
                    data-denied={this.accessDenied('machine_command_reset')}
                    className="box-shadow btn btn-action btn-machine-action"
                    onClick={() => this.props.reset(this.props.dispID)}
                  >
                    <img src={resetImg} alt="Reset" className="img-fit" />
                  </button>
                  <p style={{ marginBottom: 0, height: '1.5rem' }}>
                    {t('fn.machineReset', 'Reset')}
                  </p>
                </Col>
              ) : null}
              {commands.reset_hard ? (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_reset_hard')}
                    data-denied={this.accessDenied(
                      'machine_command_reset_hard'
                    )}
                    className="box-shadow btn btn-action btn-machine-action"
                    onClick={() => this.props.resetHard(this.props.dispID)}
                  >
                    <img src={resetImg} alt="Reset" className="img-fit" />
                  </button>
                  <p style={{ marginBottom: 0, height: '1.5rem' }}>
                    Reset hard
                  </p>
                </Col>
              ) : null}
            </Row>
          </CardBody>
        </Card>
      );
    }
  };

  showStirringTimeModal = () => {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.state.show_stirring_modal}
        toggle={this.toggle}
        size="md"
        centered={true}
      >
        <ModalHeader>
          {t('lbl.stirringConfiguration', 'Stirring configuration')}
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label xs={4}>
              {' '}
              {t('lbl.stirringDuration', 'Stirring duration')}
            </Label>
            <Col sm={7}>
              <InputGroup>
                <Input
                  id={'stirring_duration'}
                  type="number"
                  min={0}
                  max={120}
                  className={'form-control-tools'}
                  value={this.state.stirring_duration}
                  onChange={(event) =>
                    this.setState(() => ({
                      stirring_duration: parseFloat(event.target.value),
                    }))
                  }
                />

                <InputGroupAddon addonType="append">
                  <InputGroupText>{'s'}</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Row style={{ width: '100%' }}>
            <Col>
              <Button color="secondary" onClick={this.toggle}>
                {t('fn.cancel', 'Cancel')}
              </Button>
            </Col>
            <Col />
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  this.state.stirring_func(
                    this.props.dispID,
                    this.state.stirring_duration
                  );
                  this.toggle();
                }}
              >
                {t('fn.ok', 'OK')}
              </Button>{' '}
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  };

  getStirCanisters = () => {
    const { t, commands } = this.props;
    const cans = (this.props.machine.colorants || []).map((cnt) => (
      <option key={cnt.id} value={cnt.id}>
        {cnt.id}
      </option>
    ));
    if (!commands.stir && !commands.stir_all) {
      return null;
    } else {
      return (
        <Card style={{ marginLeft: '0.5rem' }}>
          <CardHeader>{t('fn.stirCanisters', 'Stir canisters')}</CardHeader>
          <CardBody>
            <Row>
              {commands.stir ? (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_stir')}
                    data-denied={this.accessDenied('machine_command_stir')}
                    className="box-shadow btn btn-action btn-machine-action"
                    onClick={() =>
                      this.setState({
                        show_stirring_modal: true,
                        stirring_func: this.stirCanister,
                      })
                    }
                  >
                    <img src={stirImg} alt="Stir" className="img-fit" />
                  </button>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <span style={{ marginBottom: 0 }}>
                      {t('fn.stirCanisterr', 'Stir canister')}
                    </span>

                    <Input
                      type="select"
                      value={this.state.canId}
                      name="select"
                      style={{ padding: 0, height: '1.5rem', width: 'auto' }}
                      onChange={this.selectCanId}
                    >
                      {cans}
                    </Input>
                  </div>
                </Col>
              ) : null}
              {commands.stir_all ? (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_stir_all')}
                    data-denied={this.accessDenied('machine_command_stir_all')}
                    className="box-shadow btn btn-action btn-machine-action"
                    onClick={() =>
                      this.setState({
                        show_stirring_modal: true,
                        stirring_func: this.props.stirAll,
                      })
                    }
                  >
                    <img src={stirAllImg} alt="Stir all" className="img-fit" />
                  </button>
                  <p style={{ marginBottom: 0, height: '1.5rem' }}>
                    {t('fn.stirAllCanisters', 'Stir all canisters')}
                  </p>
                </Col>
              ) : null}
            </Row>
          </CardBody>
        </Card>
      );
    }
  };

  getCleanCircuits = () => {
    const { t, commands } = this.props;
    const cans = (this.props.machine.colorants || []).map((cnt) => (
      <option key={cnt.id} value={cnt.id}>
        {`can: ${cnt.id}, circuits: ${cnt.circuits}`}
      </option>
    ));
    if (!commands.clean && !commands.clean_all) {
      return null;
    } else {
      return (
        <Card style={{ marginLeft: '0.5rem' }}>
          <CardHeader>
            {t('cfg.machine_command_clean_circuits', 'Clean circuits')}
          </CardHeader>
          <CardBody>
            <Row>
              {commands.clean && (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_clean')}
                    data-denied={this.accessDenied('machine_command_clean')}
                    className="box-shadow btn btn-action btn-machine-action"
                    onClick={() =>
                      this.props.clean(this.props.dispID, this.state.canId)
                    }
                  >
                    <img src={cleanImg} alt="Clean" className="img-fit" />
                  </button>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <span style={{ marginBottom: 0 }}>Clean circuit</span>

                    <Input
                      type="select"
                      name="select"
                      style={{ padding: 0, height: '1.5rem', width: 'auto' }}
                      onChange={(e) => this.selectCanId(e)}
                    >
                      {cans}
                    </Input>
                  </div>
                </Col>
              )}
              {commands.clean_all && (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_clean_all')}
                    data-denied={this.accessDenied('machine_command_clean_all')}
                    className="box-shadow btn btn-action btn-machine-action"
                    onClick={() => this.props.cleanAll(this.props.dispID)}
                  >
                    <img
                      src={cleanAllImg}
                      alt="Clean all"
                      className="img-fit"
                    />
                  </button>
                  <p style={{ marginBottom: 0, height: '1.5rem' }}>
                    {t('cfg.machine_command_clean_all', 'Clean all circuits')}
                  </p>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      );
    }
  };

  getCapOpenClose = () => {
    const { t, commands } = this.props;
    if (!commands.cap) {
      return null;
    } else {
      return (
        <Card style={{ marginRight: '0.5rem' }}>
          <CardHeader>{t('fn.autocap', 'Autocap')}</CardHeader>
          <CardBody>
            <Row>
              {commands.cap ? (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_cap')}
                    data-denied={this.accessDenied('machine_command_cap')}
                    className={'box-shadow btn btn-action btn-machine-action '}
                    onClick={() =>
                      this.props.cap(this.props.dispID, MACHINE_CAP_OPEN)
                    }
                  >
                    <img src={capOpenImg} alt="CapOpen" className="img-fit" />
                  </button>
                  <p style={{ marginBottom: 0, height: '1.5rem' }}>
                    {t('fn.capOpen', 'Cap Open')}
                  </p>
                </Col>
              ) : null}
              {commands.cap ? (
                <Col style={{ textAlign: 'center' }}>
                  <button
                    disabled={this.accessDenied('machine_command_cap')}
                    data-denied={this.accessDenied('machine_command_cap')}
                    className="box-shadow btn btn-action btn-machine-action"
                    onClick={() =>
                      this.props.cap(this.props.dispID, MACHINE_CAP_CLOSE)
                    }
                  >
                    <img src={capCloseImg} alt="CapClose" className="img-fit" />
                  </button>
                  <p style={{ marginBottom: 0, height: '1.5rem' }}>
                    {t('fn.capClose', 'Cap Close')}
                  </p>
                </Col>
              ) : null}
            </Row>
          </CardBody>
        </Card>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <CustomScrollbars
          className="bordered br-8"
          style={{ height: 'calc(100vh - 13.5rem)', width: '100%' }}
        >
          {this.showStirringTimeModal()}
          <Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
            <Col xs={4} style={{ padding: 0 }}>
              {this.getInitReset()}
            </Col>
            <Col xs={4} style={{ padding: 0 }}>
              {this.getStirCanisters()}
            </Col>
            <Col xs={4} style={{ padding: 0 }}>
              {this.getCleanCircuits()}
            </Col>
          </Row>

          <Row>
            <Col xs={4} style={{ padding: 0 }}>
              {this.getCapOpenClose()}
            </Col>
          </Row>
        </CustomScrollbars>
      </React.Fragment>
    );
  }
}

MachineActions.propTypes = propTypes;
MachineActions.defaultProps = defaultProps;
