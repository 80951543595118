import React, { Component } from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import printActions, {
  propType as previewType,
} from 'js/redux/reducers/PrintPreview';
import Select from 'react-select';
import { customControlStyles, omitNil } from 'js/mylib/Utils';
import ErrorBoundary from './ErrorBoundaryModal';
import { Preview } from 'e-p';
import { INITIAL_EP_LAYOUT, DEFAULT_PRINTER } from 'js/Constants';
import EPValues from 'js/redux/selectors/EPValues';
import { check_conditions } from '../../redux/sagas/PrintTask';
import { selectors as configSelectors } from '../../redux/reducers/Configuration';

//import loader from 'img/loading-hourglass.gif';

const propTypes = {
  t: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  setCopies: PropTypes.func.isRequired,
  setPrinter: PropTypes.func.isRequired,

  className: PropTypes.object,
  configurations: PropTypes.object,
  user: PropTypes.object,
  print_labels: PropTypes.array,
  print_preview: previewType,
  cache: PropTypes.object,
  localPrintTasks: PropTypes.array,
};

const defaultProps = {};

class PrintPreviewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clearOrderModal: false,
      printLabelModal: false,
      selectedLabel: 0,
      copies: 1,
      printer: DEFAULT_PRINTER,
      pre_visible: false,
    };
    this.previewRef = React.createRef();

    this.printers = [
      {
        label: '<' + props.t('lbl.systemDefault', 'System default') + '>',
        value: DEFAULT_PRINTER,
      },
    ];
    if (window.qtside) {
      // Get printers available on the system
      window.qtside.printer.availablePrinters.connect(this.updatePrintersList);
      window.qtside.printer.listAvailablePrinters();
    }
  }

  static getDerivedStateFromProps(props, state) {
    let printer = props.print_preview.printer;
    let selectedLabel = state.selectedLabel;
    if (state.pre_visible === false && props.print_preview.visible) {
      for (const task of props.localPrintTasks) {
        if (check_conditions(task, props.print_preview.order)) {
          // set selected label from first task that matches the rules.
          const indx = props.print_labels.findIndex(
            (label) => label.name === task.template
          );
          printer = task.printer;
          selectedLabel = indx;
          break;
        }
      }
    }

    return { pre_visible: props.print_preview.visible, printer, selectedLabel };
  }

  updatePrintersList = (str) => {
    const { t } = this.props;
    let printers = JSON.parse(str);

    this.printers = printers.available.map((x) => ({ label: x, value: x }));
    this.printers.unshift({
      label: '<' + t('lbl.systemDefault', 'System default') + '>',
      value: null,
    });
  };

  toggle = () => {
    this.props.setVisible(false);
  };

  printLabel = () => {
    this.previewRef.current.printRequested(this.props.print_preview.printer);
    this.toggle();
  };

  getPreviewData = () => {
    const { print_preview, cache, configurations, user } = this.props;
    return EPValues(
      {
        order: omitNil(print_preview.order),
        configurations,
        cache,
        user,
      },
      print_preview.canNumber
    );
  };

  render() {
    const { t, print_preview } = this.props;

    // list available templates here
    // Layout forced by print task
    let label;
    let selectable = [];
    if (print_preview.layout) {
      label = print_preview.layout;
    } else {
      const labels = this.props.print_labels; // why cloneDeep?

      selectable = labels.map((x, i) => ({
        label: x.zoneid ? x.name + ' \uD83C\uDF10' : x.name,
        value: i,
      }));

      label = labels[this.state.selectedLabel]
        ? labels[this.state.selectedLabel].data
        : INITIAL_EP_LAYOUT;
    }

    if (!print_preview.visible) {
      return null;
    }

    return (
      <Modal
        isOpen={true}
        toggle={this.toggle}
        centered
        fade={false}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle}>
          {t('lbl.previewLabel', 'Preview label')}
        </ModalHeader>
        <ModalBody>
          <div className="mb-8">
            {selectable.length > 0 ? (
              <Select
                menuPlacement="auto"
                styles={{
                  control: customControlStyles,
                  option: (base) => ({ ...base, color: 'black' }),
                }}
                value={selectable[this.state.selectedLabel]}
                isSearchable={false}
                onChange={(value) =>
                  this.setState({ selectedLabel: value.value })
                }
                options={selectable}
              />
            ) : (
              <div data-testid="selected_label_template">
                {t('lbl.taskname.colon', 'Taskname:')} {label.taskname}{' '}
                {t('lbl.label.colon', 'Label:')} {label.name}
              </div>
            )}
          </div>
          <ErrorBoundary>
            <div
              style={{
                border: '2px solid black',
                width: 'fit-content',
                margin: 'auto',
              }}
            >
              <Preview
                standAlone
                useDummyData={false}
                zIndex={'1050'}
                layout={label}
                pWidth={460}
                pHeight={200}
                orderItem={this.getPreviewData()}
                ref={this.previewRef}
                copies={this.props.print_preview.copies}
              />
            </div>
          </ErrorBoundary>
          <Row className="mt-8">
            <Col xs={8} className="p-0 pr-8">
              {t('lbl.printer', 'Printer')}
            </Col>
            <Col>{t('lbl.copies', 'Copies')}</Col>
          </Row>
          <Row>
            <Col xs={8} className="p-0 pr-8">
              <Select
                menuPlacement="auto"
                styles={{
                  control: customControlStyles,
                  option: (base) => ({ ...base, color: 'black' }),
                }}
                isSearchable={false}
                value={this.printers.find(
                  (p) => p.value === this.state.printer
                )}
                onChange={(value) => this.props.setPrinter(value.value)}
                options={this.printers}
              />
            </Col>
            <Col>
              <Input
                type="number"
                value={this.props.print_preview.copies}
                onChange={(e) => this.props.setCopies(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row style={{ width: '100%' }}>
            <Col className="m-16">
              <Button
                data-testid="btn_cancel_print"
                color="secondary"
                onClick={this.toggle}
              >
                {t('fn.cancel', 'Cancel')}
              </Button>
            </Col>
            <Col className="m-16">
              <Button
                data-testid="btn_print_label"
                color="primary"
                onClick={this.printLabel}
              >
                {t('fn.print', 'Print')}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

PrintPreviewModal.propTypes = propTypes;
PrintPreviewModal.defaultProps = defaultProps;

function mapStateToProps(store) {
  return {
    print_preview: store.print_preview,
    print_labels: store.configurations.print_labels,
    configurations: store.configurations,
    user: store.user,
    cache: store.cache,
    localPrintTasks: configSelectors.localPrintTasks(store),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setVisible: printActions.setVisible,
      setPrinter: printActions.setPrinter,
      setCopies: printActions.setCopies,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(PrintPreviewModal)
);
