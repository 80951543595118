import React from 'react';
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';
import { exportXLSX } from './Export';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { MDBAnimation } from 'mdbreact';
import { TopBar, TopBarLeft } from 'js/components/layout/Containers';

import actions, { propType as customerType } from 'js/redux/reducers/Customer';

import { hasPrivilege } from '../../mylib/Privileges';
import SearchInput from '../shared/SearchInput';
import CustomerSearchTable from './CustomerSearchTable';

const propTypes = {
  t: PropTypes.func.isRequired,
  customer: customerType,
  searchCustomers: PropTypes.func.isRequired,
  location: PropTypes.object,
};

const defaultProps = {};

class CustomerSearchPage extends React.Component {
  initialState = { search: '' };

  constructor(props) {
    super(props);

    this.state = this.initialState;
  }

  selectCustomer = () => {
    // this.setState({ customer: customer });
    // // Search customer order history
    // this.startOrderSearch(this.state.formControls, customer);
  };

  componentDidMount() {
    this.startSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.state;
    if (search !== prevState.search) {
      this.startSearch();
    }
  }

  goBack = () => {
    window.history.back();
  };

  startSearch() {
    const { search } = this.state;
    this.props.searchCustomers(search, 0);
  }

  reset = () => {
    this.setState(this.initialState);
  };

  exportHandle = () => {
    const { t, customer } = this.props;
    const data = customer.search_results.data.customers.map((customer) => ({
      [`${t('lbl.customerName', 'Customer name')}`]: customer.customername,
      [`${t('lbl.companyName', 'Company name')}`]: customer.companyname,
      [`${t('lbl.address', 'Address')}`]: customer.address1,
      [`${t('lbl.email', 'Email')}`]: customer.email,
      [`${t('lbl.phone', 'Phone')}`]: customer.phone,
      [`${t('lbl.zipCode', 'Zip code')}`]: customer.postcode,
    }));
    exportXLSX(data, 'customers.xlsx');
  };

  render() {
    const { t } = this.props;

    return (
      <MDBAnimation type="zoomIn" duration="200ms">
        <>
          <TopBar>
            <TopBarLeft>
              <h2
                id="backToHomePage"
                className="clickable-text directly-over-bg mt-8"
                onClick={this.goBack}
              >
                <FontAwesomeIcon
                  icon="arrow-left"
                  style={{ fontSize: '1.7rem' }}
                />{' '}
                {t('lbl.customers', 'Customers')}
              </h2>
            </TopBarLeft>
          </TopBar>
          <Container className="justify-content-center">
            <Row>
              <Col xs="3" className="p-0 mb-40">
                {/* Search field */}
                <InputGroup className="p-0 pr-16">
                  <InputGroupAddon addonType="prepend">
                    <span className="input-small-text">
                      <FontAwesomeIcon icon="search" />
                    </span>
                  </InputGroupAddon>

                  <SearchInput
                    id="search"
                    className="form-control input-small"
                    use_default_style={false}
                    value={this.state.search}
                    onChange={(e) =>
                      this.setState({
                        search: e.target.value,
                      })
                    }
                    placeholder={t('prompt.search', 'Search')}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="2">
                <Button
                  data-testid="export"
                  data-denied={!hasPrivilege('export')}
                  disabled={!hasPrivilege('export')}
                  onClick={this.exportHandle}
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  {t('fn.export')}
                </Button>
              </Col>
            </Row>
            <Row style={{ height: 'calc(100vh - 20.375rem)' }}>
              <CustomerSearchTable />
            </Row>
          </Container>
        </>
      </MDBAnimation>
    );
  }
}

CustomerSearchPage.propTypes = propTypes;
CustomerSearchPage.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    customer: state.customer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchCustomers: actions.searchCustomers,
    },
    dispatch
  );
}

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(CustomerSearchPage)
);
