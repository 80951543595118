import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import { propType as machineType } from 'js/redux/reducers/Machine';
import _ from 'lodash';
import { CustomScrollbars } from '../../../mylib/Utils';
import { ShotFormatter } from '../../../mylib/ShotFormatter';
import AmountInput from '../../shared/AmountInput';
import ColorThumbnail from '../../shared/ColorThumbnail';

const propTypes = {
  t: PropTypes.func.isRequired,
  machine: machineType,
  dispID: PropTypes.string.isRequired,
  config: PropTypes.object,
  purge: PropTypes.func.isRequired,
  purgeAll: PropTypes.func.isRequired,
  purgeAutomaticPrepare: PropTypes.func.isRequired,
  privileges: PropTypes.arrayOf(PropTypes.string).isRequired,
  commands: PropTypes.object,
};

const defaultProps = {};

export default class PurgeActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cnts: [],
      sequential: false,
      purgeAmount: 1,
    };
    this._purgeAmount = this.state.purgeAmount;
    this._nodes = new Map();
  }

  componentDidMount() {
    this.loadColorants();
  }

  componentDidUpdate() {
    this.syncColorants();
  }

  // If canister is disabled from other tab then reflect the change into state
  syncColorants = () => {
    if (this.state.cnts.length !== this.props.machine.colorants.length) {
      this.loadColorants();
      return;
    }
    for (let i = 0; i < this.state.cnts.length; i++) {
      if (
        this.state.cnts[i].enabled !== this.props.machine.colorants[i].enabled
      ) {
        this.loadColorants();
        return;
      }
    }
  };

  loadColorants = () => {
    const colorants = this.props.machine.colorants.map((colorant) => {
      return {
        id: colorant.id,
        code: colorant.code,
        cntid: colorant.cntid,
        rgb: colorant.rgb,
        enabled: colorant.enabled,
        selected: false,
        volume: 0,
        circuits: [...colorant.circuits],
      };
    });

    if (colorants.length > 0) {
      this.setState({
        cnts: colorants,
      });
    }
  };

  handleApplyAll = () => {
    this.setState({
      purgeAmount: this._purgeAmount,
      cnts: this.state.cnts.map((colorant) => {
        if (colorant.enabled) colorant.volume = parseFloat(this._purgeAmount);
        return colorant;
      }),
    });
  };

  accessDenied = (key) => {
    const { privileges } = this.props;
    return !_.includes(privileges, key);
  };

  purgeAll = () => {
    /*
    this.props.purgeAll(
      this.props.dispID,
      parseFloat(this.state.purgeAmount),
      this.state.sequential
    );
     */
    let cnts = (this.state.cnts ? this.state.cnts : []).filter(
      (x) => x.enabled && x.volume !== 0
    );

    if (cnts.length > 0) {
      let purge_formula = [];
      cnts.forEach((c) =>
        c.circuits.forEach((circuit) =>
          purge_formula.push({
            id: circuit,
            volume: c.volume,
          })
        )
      );
      this.props.purge(this.props.dispID, purge_formula, this.state.sequential);
    }
  };

  purgeAutomaticPrepare = () => {
    this.props.purgeAutomaticPrepare(this.props.dispID);
  };

  purge = () => {
    let cnts = (this.state.cnts ? this.state.cnts : []).filter(
      (x) => x.enabled && x.selected && x.volume !== 0
    );

    if (cnts.length > 0) {
      let purge_formula = [];
      cnts.forEach((c) =>
        c.circuits.forEach((circuit) =>
          purge_formula.push({
            id: circuit,
            volume: c.volume,
          })
        )
      );
      this.props.purge(this.props.dispID, purge_formula, this.state.sequential);
    }
  };

  onAmountChange = (volume, cnt) => {
    this.setState({
      cnts: this.state.cnts.map((colorant) => {
        if (colorant.id === cnt.id) {
          colorant.volume = parseFloat(volume);
        }
        return colorant;
      }),
    });
  };
  getPurgeValue = (cnt) => {
    let c = _.find(this.state.cnts, (x) => {
      return x.id === cnt.id;
    });
    return parseFloat(c.volume);
  };

  handleCntSelection = (cnt) => {
    this.setState({
      cnts: this.state.cnts.map((c) => {
        if (cnt.id === c.id) c.selected = !cnt.selected;
        return c;
      }),
    });
  };

  getCNT = (cnt, i, disabled, shotFormatter) => {
    const block_input = disabled;

    const element_id = 'formula_cnt_violation_' + String(cnt.id) + '_input';

    return (
      <Row className={'ml-4 mr-4 mt-4 mb-4 pl-4 pr-4 pt-4 pb-4'}>
        <Col xs={1}>
          <CustomInput
            type="checkbox"
            id={cnt.id}
            checked={cnt.selected}
            disabled={!cnt.enabled}
            onChange={() => {
              this.handleCntSelection(cnt);
            }}
          />
        </Col>
        <Col
          xs={2}
          style={{
            minWidth: '2.5rem',
            paddingTop: '0.25rem',
          }}
          id={element_id}
        >
          <ColorThumbnail hideIfNull rgb={cnt.rgb} size="1.5rem" />
        </Col>
        <Col style={{ margin: 'auto 0' }}>{cnt.code}</Col>
        <Col className={'p-0'}>
          <AmountInput
            className={
              block_input
                ? 'amount-input-blocked'
                : cnt.volume
                ? 'amount-input'
                : 'amount-input-zero'
            }
            shotFormatter={shotFormatter}
            cnt={cnt}
            ref={(c) => this._nodes.set(i, c)}
            value={this.getPurgeValue(cnt)}
            onChange={(volume) =>
              volume !== null &&
              volume !== undefined &&
              this.onAmountChange(volume, cnt)
            }
            disabled={block_input}
          />
        </Col>
      </Row>
    );
  };

  getColorantsForPurge = () => {
    let cnts = this.state.cnts;
    const mlFormatter = new ShotFormatter({ decimals: 1 });
    let arr = [];

    let startsAt = 0;
    let endsAt = 4;
    for (let r = 0; r < Math.ceil(cnts.length / 4); r++) {
      let row = [];
      let smallCntsArray = cnts.slice(
        startsAt,
        endsAt > cnts.length ? cnts.length : endsAt
      );
      for (let c = 0; c < smallCntsArray.length; c++) {
        row.push(
          <Col
            className="purge_formula_input_cell"
            key={smallCntsArray[c] && smallCntsArray[c].id}
          >
            {this.getCNT(
              smallCntsArray[c] && smallCntsArray[c],
              smallCntsArray[c] && smallCntsArray[c].id,
              !smallCntsArray[c].enabled,
              mlFormatter
            )}
          </Col>
        );
      }
      let j = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (j + smallCntsArray.length < 4) {
          row.push(
            <Col key={'_add_' + j} className="purge_formula_input_cell" />
          );
          j += 1;
        } else {
          break;
        }
      }
      startsAt = endsAt;
      endsAt += 4;

      arr.push(<Row key={r}>{row}</Row>);
    }
    return arr;
  };

  getPurge = () => {
    const { t, machine } = this.props;
    //let cnts = this.props.machine.colorants || [];

    return (
      <Card style={{ padding: '0.5rem 0.5rem' }}>
        <CardHeader>{t('lbl.purgeOptions', 'Purge Options')}</CardHeader>
        <CardBody style={{ padding: '1rem 0.5rem' }}>
          <Row>
            <Col sm={1}>
              <Label className="mt-2">{t('lbl.amount.colon', 'Amount:')}</Label>
            </Col>
            <Col sm={3}>
              <InputGroup>
                <Input
                  type="number"
                  min={0}
                  defaultValue={this.state.purgeAmount}
                  onChange={(e) => {
                    this._purgeAmount = e.target.value;
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>{t('symbol.ml')}</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col sm={3}>
              <Button
                onClick={this.handleApplyAll}
                disabled={this.accessDenied('machine_command_purge')}
                data-denied={this.accessDenied('machine_command_purge')}
              >
                {t('fn.applyToAll', 'Apply to all')}
              </Button>
            </Col>
            <Col style={{ marginLeft: '0.5rem' }}>
              <CustomInput
                className="mt-2"
                type="checkbox"
                id="sequential"
                label={t('cfg.machine.purge_sequential', 'Sequential')}
                checked={this.state.sequential}
                onChange={() =>
                  this.setState((st) => ({
                    sequential: !st.sequential,
                  }))
                }
              />
            </Col>
          </Row>
          {machine.info?.purgeAmountSupported === false && (
            <Row style={{ fontSize: 'smaller', marginTop: '0.2rem' }}>
              {t('msg.cannotSetPurgeAmounts')}
            </Row>
          )}
          <Row style={{ marginTop: '2rem' }}>
            <Col style={{ marginLeft: '2.5rem' }}>
              <Button
                onClick={this.purge}
                disabled={this.accessDenied('machine_command_purge')}
                data-denied={this.accessDenied('machine_command_purge')}
              >
                {t('fn.PurgeSelected', 'Purge selected')}
              </Button>
            </Col>

            <Col style={{ marginLeft: '2.5rem' }}>
              <Button
                onClick={this.purgeAll}
                disabled={this.accessDenied('machine_command_purge')}
                data-denied={this.accessDenied('machine_command_purge')}
              >
                {t('fn.PurgeAll', 'Purge All')}
              </Button>
            </Col>

            <Col style={{ marginLeft: '2.5rem', minWidth: 'fit-content' }}>
              <Button
                data-testid="btn_automatic_purge"
                onClick={this.purgeAutomaticPrepare}
                disabled={this.accessDenied('machine_command_purge')}
                data-denied={this.accessDenied('machine_command_purge')}
              >
                {t('fn.automaticPurge', 'Automatic purge')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  render() {
    return (
      <React.Fragment>
        <CustomScrollbars
          className="bordered br-8"
          style={{ height: 'calc(100vh - 13.5rem)', width: '100%' }}
        >
          <Row style={{ marginBottom: '0.5rem' }}>
            <Col>
              {this.props.config.hide_purge_actions &&
                !this.props.config.hide_purge_actions.value &&
                this.getPurge()}
            </Col>
          </Row>
          <Row style={{ marginBottom: '0.5rem' }}>
            <Col>
              {this.props.config.hide_purge_actions &&
                !this.props.config.hide_purge_actions.value &&
                this.state.cnts && (
                  <Card>
                    <CardBody> {this.getColorantsForPurge()}</CardBody>
                  </Card>
                )}
            </Col>
          </Row>
        </CustomScrollbars>
      </React.Fragment>
    );
  }
}

PurgeActions.propTypes = propTypes;
PurgeActions.defaultProps = defaultProps;
