export function resizableGrid(table, parent) {
  var row = table.getElementsByTagName('tr')[0],
    cols = row ? row.children : undefined;
  if (!cols) return;

  var tableHeight = Math.max(parent.offsetHeight, 300);

  for (var i = 0; i < cols.length; i++) {
    if (!check_already_has(cols[i])) {
      var div = createDiv(tableHeight);
      cols[i].appendChild(div);
      cols[i].style.position = 'relative';
      setListeners(div);
    }
  }

  function setListeners(div) {
    var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

    div.addEventListener('mousedown', function (e) {
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;

      var padding = paddingDiff(curCol);

      curColWidth = curCol.offsetWidth - padding;
      if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
    });

    div.addEventListener('mouseover', function (e) {
      e.target.style.borderRight = '2px solid #0000ff';
    });

    div.addEventListener('mouseout', function (e) {
      e.target.style.borderRight = '';
    });

    document.addEventListener('mousemove', function (e) {
      if (curCol) {
        var diffX = e.pageX - pageX;

        if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + 'px';

        curCol.style.width = Math.max(curColWidth + diffX, 80) + 'px';
      }
    });

    document.addEventListener('mouseup', function (e) {
      curCol = undefined;
      nxtCol = undefined;
      pageX = undefined;
      nxtColWidth = undefined;
      curColWidth = undefined;
      e.stopPropagation();
    });
    document.addEventListener('click', function (e) {
      e.stopPropagation();
    });
  }

  function check_already_has(parent) {
    let elem = parent.querySelector('div[name]');
    if (elem && elem.getAttribute('name') === 'resizetable') {
      return true;
    }
    return false;
  }

  function createDiv(height) {
    var div = document.createElement('div');
    div.style.top = 0;
    div.style.right = 0;
    div.style.width = '5px';
    div.style.position = 'absolute';
    div.style.cursor = 'col-resize';
    div.style.userSelect = 'none';
    div.style.height = height + 'px';
    div.setAttribute('name', 'resizetable');
    return div;
  }

  function paddingDiff(col) {
    if (getStyleVal(col, 'box-sizing') === 'border-box') {
      return 0;
    }

    var padLeft = getStyleVal(col, 'padding-left');
    var padRight = getStyleVal(col, 'padding-right');
    return parseInt(padLeft) + parseInt(padRight);
  }

  function getStyleVal(elm, css) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
  }
}
