import { call, delay, put, race, select, take } from 'redux-saga/effects';
import loginActions, {
  actionTypes as loginActionTypes,
  selectors as loginSelectors,
} from '../reducers/Login';
import offlineActions, {
  actionTypes as offlineActionTypes,
  selectors as offlineSelectors,
} from '../reducers/Offline';
import { fulfilled, rejected } from '../factories/ApiCall';

import _ from 'lodash';
import { MAX_REQUEST_TRIALS, offline_mode } from '../../Constants';
import { tryLogins } from './Login';
import log from '../../api/Logger';

export default function* webRequest(apifunc) {
  let trial_count = 0;

  while (true) {
    const logged_in = yield select(loginSelectors.logged_in);
    if (logged_in) {
      try {
        trial_count += 1;
        const response = yield call(apifunc);
        // reset after successfull response
        const tried_qt_login = yield select(loginSelectors.tried_qt_login);
        if (tried_qt_login) {
          yield put(loginActions.setTriedQTLogin(false));
        }
        return response;
      } catch (error) {
        if (error?.response?.status === 409) {
          throw error;
        }

        // If more than MAX_REQUEST_TRIALS then throw error
        if (trial_count > MAX_REQUEST_TRIALS) {
          throw error;
        }

        if (offline_mode) {
          // Re try after 1 sec
          if (error.response && error.response.status === 404) {
            throw error;
          }

          yield delay(1000);
          continue;
        }
        // ######################################################
        // Offline mode handling
        // ######################################################
        const offline_mode_available = yield select(
          offlineSelectors.is_offline_mode_available
        );

        if (error.response) {
          const status = error.response.status;
          // ######################################################
          // Offline mode handling
          // ######################################################

          if (
            offline_mode_available &&
            !_.includes([401, 400, 403, 404, 409, 422, 500, 503], status) &&
            !offline_mode
          ) {
            yield put(offlineActions.setOnlineAvailable(false));
            // wait for user to make decision what to do and then retry the call
            yield take(offlineActionTypes.RE_TRY_REQUEST);
            // restart the action
            continue;
          }

          // ######################################################
          // Refresh  handling
          // ######################################################
          if (
            status === 401 || // expired token
            status === 422 // unprocessable entity
          ) {
            // invalid token
            // Try to refresh token
            yield put(loginActions.refreshToken());
            // wait for successful login
            yield take(loginActionTypes.LOGIN_SITE_FULFILLED);
            continue;
          }
        } else if (error.request) {
          // The request was made but no response was received.
          // Retry after 1 s.
          yield delay(1000);
        } else {
          // ######################################################
          // Offline mode handling
          // ######################################################
          if (offline_mode_available && !offline_mode) {
            yield put(offlineActions.setOnlineAvailable(false));
            // wait for user to make decision what to do and then retry the call
            yield take(offlineActionTypes.RE_TRY_REQUEST);
            // restart the action
            continue;
          }
        }
      }
    } else {
      yield call(() => tryLogins());

      log.info('Waiting login...');
      const { logged_in } = yield race({
        logged_in: take(loginActionTypes.LOGIN_SITE_FULFILLED),
        retry: delay(10000),
      });
      log.info(logged_in ? 'Logged in' : 'Retry login');
    }
  }
}

export function* fetchAndPut(apifunc, action) {
  try {
    const { data } = yield call(webRequest, apifunc(action.payload));
    yield put({ type: fulfilled(action.type), payload: data });
  } catch (e) {
    yield put({ type: rejected(action.type), payload: e });
  }
}
