import {
  call,
  put,
  all,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects';
import FormulaAPI from 'js/api/Formula';
import { selectors as configSelectors } from '../reducers/Configuration';
import {
  actionTypes,
  selectors as formulaSelectors,
} from '../reducers/Formula';
import { selectors as orderSelectors } from '../reducers/Order';
import orderActions, {
  actionTypes as orderActionTypes,
} from '../reducers/Order';
import { selectors as spectroSelectors } from '../reducers/Spectro';
import {
  waitForConfig,
  waitForConfigValues,
  waitForSite,
  waitForZone,
  waitForIsPro,
} from './Configuration';
import WebRequest from './WebRequest';
import { MATCH_SEARCH, INFO_CARD } from 'js/Constants';
import i18n from 'js/localization/i18n';
import errorActions from 'js/redux/reducers/Errors';
import _ from 'lodash';
import busyActions from '../reducers/Busy';
import { offline_mode } from '../../Constants';

function* searchColors(action) {
  yield put({ type: actionTypes.FETCH_COLORS_PENDING });

  const zone = yield call(waitForZone);
  const card = yield select(orderSelectors.card);
  const site = yield select(configSelectors.site);
  const product = yield select(orderSelectors.product);
  const productid = product ? product.productid : null;
  const colourcardid = card ? card.colourcardid : null;
  const colorSearchMode = yield select(orderSelectors.color_search_mode);

  const config = yield call(waitForConfig);
  const search_by_both_color_codes = _.get(
    config,
    'search_by_both_color_codes.value',
    false
  );

  const unique_color_codes = _.get(config, 'unique_color_codes.value', true);

  let search = action.payload;
  if (search === null) {
    search = yield select(formulaSelectors.color_search_str);
  }

  try {
    const response = yield call(
      WebRequest,
      productid == null
        ? // any colors
          FormulaAPI.searchColors(
            zone.zoneid,
            colourcardid,
            search,
            search_by_both_color_codes
          )
        : colorSearchMode === MATCH_SEARCH
        ? FormulaAPI.searchColorsForMatching(
            site.systemgroupid,
            zone.zoneid,
            productid,
            colourcardid,
            search,
            search_by_both_color_codes
          )
        : // colors having formulas
          FormulaAPI.searchColorsForProduct(
            site.systemgroupid,
            zone.zoneid,
            productid,
            colourcardid,
            search,
            search_by_both_color_codes
          )
    );
    let colors = response ? response.data : [];
    // Do the swap for code and name here
    const swap = _.get(config, 'color_code_name_swap.value', false);

    // If not unique color codes then show the alternatives if available
    if (!unique_color_codes) {
      colors = colors.map((color) => ({
        ...color,
        colourcode: color.altcolourcode || color.colourcode,
      }));
    }

    if (swap) {
      colors = colors.map((color) => ({
        ...color,
        colourcode:
          color.colournames.length > 0
            ? color.colournames[0]
            : color.colourcode,
        colournames: color.colournames.length > 0 ? [color.colourcode] : [],
      }));
    }

    sortColors(colors, search);

    // send returned object back to reducer as payload:
    yield put({
      type: actionTypes.FETCH_COLORS_FULFILLED,
      payload: { data: colors, search: search },
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_COLORS_REJECTED, payload: e });
  }
}

/** Sort colors in-place based on the start with. First sorted based on color code then based on color name
 * #6938
 *
 * @param {array} colors
 * @param {string} search
 */
function sortColors(colors, search) {
  if (search) {
    const collator = new Intl.Collator('en', { sensitivity: 'base' }); // trying to simulate MariaDb generic collation
    const prefixMatch = (s) =>
      collator.compare(search, s.slice(0, search.length)) === 0;
    const sortKey = (color) => {
      if (prefixMatch(color.colourcode)) {
        return '1'; // 1st group in results, already sorted by code
      }
      for (const name of color.colournames) {
        if (prefixMatch(name)) {
          return '2' + name; // 2nd group, sort by the matching name
        }
      }
      return '3'; // 3rd group, already sorted by code
    };
    colors.sort((a, b) => collator.compare(sortKey(a), sortKey(b)));
  }
}

function* fetchCardsForProduct(action) {
  const zone = yield call(waitForZone);
  const site = yield select(configSelectors.site);
  const productid = action.payload;
  try {
    if (productid) {
      yield put({ type: actionTypes.FETCH_PRODUCT_CARDS_PENDING });
      const response = yield call(
        WebRequest,
        FormulaAPI.cardsForProduct(site.systemgroupid, zone.zoneid, productid)
      );

      // send returned object back to reducer as payload:
      yield put({
        type: actionTypes.FETCH_PRODUCT_CARDS_FULFILLED,
        payload: response.data,
      });
    } else {
      yield put({
        type: actionTypes.FETCH_PRODUCT_CARDS_FULFILLED,
        payload: null,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_PRODUCT_CARDS_REJECTED, payload: e });
  }
}

function* fetchProductsWithFormulas() {
  yield put({ type: actionTypes.FETCH_PROD_WITH_FORMULA_PENDING });

  const zone = yield call(waitForZone);
  const site = yield select(configSelectors.site);
  const color = yield select(orderSelectors.color);
  const colourid = color ? color.colourid : null;
  try {
    let payload = null;
    if (colourid != null) {
      const response = yield call(
        WebRequest,
        FormulaAPI.searchProducts(site.systemgroupid, zone.zoneid, colourid)
      );
      payload = response ? response.data : {};
    }
    // send returned object back to reducer as payload:
    yield put({
      type: actionTypes.FETCH_PROD_WITH_FORMULA_FULFILLED,
      payload,
    });
  } catch (e) {
    yield put({
      type: actionTypes.FETCH_PROD_WITH_FORMULA_REJECTED,
      payload: e,
    });
  }
}

function* loadFormula(action) {
  try {
    yield put({ type: actionTypes.FETCH_FORMULA_PENDING });
    const site = yield select(configSelectors.site);
    const response = yield call(
      WebRequest,
      FormulaAPI.loadFormula(site.systemgroupid, action.payload)
    );
    // Add productid and colourid to formulas
    const { productid, colourid } = action.payload;
    const frms = (response?.data || []).map((x) => ({
      ...x,
      productid,
      colourid,
    }));

    // send returned object back to reducer as payload:
    yield put({ type: actionTypes.FETCH_FORMULA_FULFILLED, payload: frms });
    // Selecting first formula to be shown by the order

    // ensure that selected formula is not from history
    if (frms.length > 0 && !frms[0].ishistory) {
      yield put(orderActions.setFormula(frms[0]));
      yield put({
        type: orderActionTypes.SET_ORIGINAL_FORMULA,
        payload: frms[0],
      });
      // In case of being in INFO page load the can selectd for order
      const open_section = yield select(orderSelectors.open_section);
      if (open_section === INFO_CARD) {
        const can = yield select(orderSelectors.can);
        // Load the can selected for order
        yield put(orderActions.setCan(can));
      }
    } else {
      yield put(orderActions.clearFormula());
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_FORMULA_REJECTED, payload: e });
  }
}

function* searchLocalFormulas(action) {
  try {
    yield put({ type: actionTypes.FETCH_LOCAL_FORMULA_PENDING });

    const zone = yield call(waitForZone);
    const site = yield call(waitForSite);
    const response = yield call(
      WebRequest,
      FormulaAPI.searchLocalFormulas(zone.zoneid, site.siteid, action.payload)
    );

    const frms = response ? response.data : [];

    yield put({
      type: actionTypes.FETCH_LOCAL_FORMULA_FULFILLED,
      payload: frms,
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_LOCAL_FORMULA_REJECTED, payload: e });
  }
}

function* hideLocalFormula(action) {
  try {
    const site = yield call(waitForSite);
    yield call(
      WebRequest,
      FormulaAPI.hideLocalFormula(site.siteid, action.payload)
    );
  } catch (e) {
    yield put(
      errorActions.showCriticalError(
        i18n.t(
          'msg.unableToDeleteLocalFormula',
          'Unable to delete local formula'
        ),
        e
      )
    );
  }
}

function* synthesizeRgb(action) {
  try {
    const is_pro = yield call(waitForIsPro);
    const product = yield select(orderSelectors.product);
    const formula = action.payload?.formula
      ? action.payload?.formula
      : yield select(orderSelectors.formula);
    let rgb = null;
    if (!offline_mode && is_pro && product?.has_matching && formula) {
      const { zoneid } = yield call(waitForZone);
      const response = yield call(
        WebRequest,
        FormulaAPI.matchingSynthesizeRgb(zoneid, product.productid, formula)
      );
      rgb = response.data;
    }
    if (action.payload.before) {
      yield put({ type: orderActionTypes.SET_RGB_BEFORE_EDIT, payload: rgb });
    }
    yield put({ type: orderActionTypes.SET_RGB, payload: rgb });
  } catch (e) {
    yield put(errorActions.showCriticalError(e.message, e));
  }
}

function* searchClosest(action) {
  try {
    yield put({ type: actionTypes.SEARCH_CLOSEST_PENDING });
    const zone = yield call(waitForZone);
    const site = yield call(waitForSite);
    const config_values = yield call(waitForConfigValues);
    const { lab, productid, can, colourid, coldataclass, refl } =
      action.payload;
    const { cansizeid, nominalamount, gravimetric } = can;
    const response = yield call(
      WebRequest,
      FormulaAPI.matchingSearchClosest(
        zone.zoneid,
        site.siteid,
        refl,
        lab,
        productid,
        cansizeid,
        nominalamount,
        gravimetric,
        colourid,
        coldataclass,
        config_values.closest_color_max_de,
        config_values.closest_color_max_results
      )
    );

    let { data } = response;
    let { matches } = data;
    if (config_values.color_code_name_swap) {
      matches = data.matches.map((color) => ({
        ...color,
        colourcode:
          color.colournames.length > 0
            ? color.colournames[0]
            : color.colourcode,
        colournames: color.colournames.length > 0 ? [color.colourcode] : [],
      }));
    }
    // Add ids to formulas
    data = {
      ...data,
      matches: matches.map((x) => ({
        ...x,
        formula: { ...x.formula, colourid: x.colourid, productid },
      })),
    };

    yield put({
      type: actionTypes.SEARCH_CLOSEST_FULFILLED,
      payload: data,
    });
  } catch (e) {
    yield put({ type: actionTypes.SEARCH_CLOSEST_REJECTED, payload: e });
  }
}

function* matchFormula(action) {
  try {
    yield put({ type: actionTypes.MATCH_FORMULA_PENDING });
    const zone = yield call(waitForZone);
    const site = yield call(waitForSite);
    const config = yield select(configSelectors.config_values);

    const { refl, productid, can, baseids, template, cntids } = action.payload;
    const { cansizeid, nominalamount, gravimetric } = can;
    const response = yield call(
      WebRequest,
      FormulaAPI.matchingMatch(
        zone.zoneid,
        site.siteid,
        refl,
        productid,
        cansizeid,
        nominalamount,
        gravimetric,
        template || config.default_matching_template,
        baseids,
        cntids, // cntids array, or null,
        config.color_match_max_results,
        config.color_match_max_de
      )
    );

    yield put({
      type: actionTypes.MATCH_FORMULA_FULFILLED,
      payload: response.data,
    });
  } catch (e) {
    yield put({ type: actionTypes.MATCH_FORMULA_REJECTED, payload: e });
    if (e?.response?.status !== 409) {
      yield put(
        errorActions.showCriticalError(
          i18n.t('msg.unableToMatchFormulas', 'Unable to match formulas'),
          e
        )
      );
    }
  }
}

function* correctFormula() {
  try {
    yield put(busyActions.setBusy(true));
    yield put({ type: actionTypes.CORRECT_FORMULA_PENDING });
    const { zoneid } = yield call(waitForZone);
    const { siteid } = yield call(waitForSite);
    const {
      can: { cansizeid, nominalamount, gravimetric },
      color: { reflectance },
      formula,
      product: { productid },
    } = yield select(orderSelectors.order);
    const batchrefl = (yield select(spectroSelectors.measurement_batch)).refl;
    const stdrefl = reflectance?.rinf || reflectance?.rw;
    const { default_matching_template } = yield select(
      configSelectors.config_values
    );
    const response = yield call(
      WebRequest,
      FormulaAPI.matchingCorrectFormula(
        zoneid,
        siteid,
        productid,
        cansizeid,
        nominalamount,
        gravimetric,
        formula,
        stdrefl,
        batchrefl,
        default_matching_template
      )
    );
    yield put({
      type: actionTypes.CORRECT_FORMULA_FULFILLED,
      payload: response.data,
    });
    yield put(busyActions.setBusy(false));
  } catch (e) {
    yield put({ type: actionTypes.CORRECT_FORMULA_REJECTED, payload: e });
    yield put(busyActions.setBusy(false));
  }
}

function* fetchColorDetails(action) {
  try {
    yield put({ type: actionTypes.FETCH_COLOR_DETAILS_PENDING });

    const zone = yield call(waitForZone);
    const response = yield call(
      WebRequest,
      FormulaAPI.fetchColorDetails(zone.zoneid, action.payload.colourid)
    );

    let data = response.data;
    const config = yield call(waitForConfig);
    const swap = _.get(config, 'color_code_name_swap.value', false);
    const unique_color_codes = _.get(config, 'unique_color_codes.value', true);

    // If not unique color codes then show the alternatives if available
    if (!unique_color_codes) {
      data = {
        ...data,
        colourcode: data.altcolourcode || data.colourcode,
      };
    }

    if (swap) {
      data = {
        ...data,

        colourcode:
          data.colournames.length > 0 ? data.colournames[0] : data.colourcode,
        colournames: data.colournames.length > 0 ? [data.colourcode] : [],
      };
    }

    yield put({
      type: actionTypes.FETCH_COLOR_DETAILS_FULFILLED,
      payload: data,
    });

    yield put({
      type: orderActionTypes.SET_COLOR_DETAILS,
      payload: data,
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_COLOR_DETAILS_REJECTED, payload: e });
    yield put(
      errorActions.showCriticalError(
        i18n.t(
          'msg.unableToFetchColorDetails',
          'Unable to fetch color details'
        ),
        e
      )
    );
  }
}

function* fetchLocalFormulaCompatibleProducts(action) {
  try {
    yield put({ type: actionTypes.FETCH_COMPATIBLE_LOCALFRM_PROD_PENDING });

    if (action.payload.itemid) {
      const zone = yield call(waitForZone);
      const response = yield call(
        WebRequest,
        FormulaAPI.loadCompatibleLocalFormula(
          zone.zoneid,
          action.payload.itemid,
          action.payload.baseid
        )
      );
      yield put({
        type: actionTypes.FETCH_COMPATIBLE_LOCALFRM_PROD_FULFILLED,
        payload: response.data,
      });
    } else {
      // No itemid available --> completely new local formula
      yield put({
        type: actionTypes.FETCH_COMPATIBLE_LOCALFRM_PROD_FULFILLED,
        payload: [],
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.FETCH_COMPATIBLE_LOCALFRM_PROD_REJECTED,
      payload: e,
    });
    yield put(
      errorActions.showCriticalError(
        i18n.t(
          'msg.unableToLoadCompatibleLocalFormulas',
          'Unable to load compatible local formulas'
        ),
        e
      )
    );
  }
}

export default function* saga() {
  yield all([
    takeLatest(actionTypes.FETCH_COLORS, searchColors),
    takeLatest(actionTypes.FETCH_PRODUCT_CARDS, fetchCardsForProduct),

    takeLatest(actionTypes.FETCH_FORMULA, loadFormula),
    takeLatest(actionTypes.FETCH_PROD_WITH_FORMULA, fetchProductsWithFormulas),
    takeLatest(
      actionTypes.FETCH_COMPATIBLE_LOCALFRM_PROD,
      fetchLocalFormulaCompatibleProducts
    ),
    takeEvery(actionTypes.SYNTHESIZE_RGB_BEFORE_EDIT, synthesizeRgb),
    takeLatest(actionTypes.SYNTHESIZE_RGB, synthesizeRgb),
    takeLatest(actionTypes.SEARCH_CLOSEST, searchClosest),
    takeLatest(actionTypes.MATCH_FORMULA, matchFormula),
    takeLatest(actionTypes.CORRECT_FORMULA, correctFormula),
    takeLatest(actionTypes.FETCH_COLOR_DETAILS, fetchColorDetails),
    takeLatest(actionTypes.FETCH_LOCAL_FORMULA, searchLocalFormulas),
    takeEvery(actionTypes.HIDE_LOCAL_FORMULA, hideLocalFormula),
  ]);
}
