import React from 'react';
import PropTypes from 'prop-types';
import { ShotFormatter } from 'js/mylib/ShotFormatter';
import _ from 'lodash';
import NumberInput from '../../mylib/NumberInput';

const propTypes = {
  className: PropTypes.string,
  shotFormatter: PropTypes.object,
  cnt: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

const defaultProps = {
  shotFormatter: new ShotFormatter(),
  cnt: {},
  value: null,
  style: {},
  onChange: (v) => v,
  disabled: false,
};

/**
 * Handles user input of amount and conversion to volume if input is gravimetric.
 */

export default class AmountInput extends React.Component {
  getValue = () => {
    const { shotFormatter, cnt } = this.props;

    let data = [this.div1.value()];
    if (this.div2) {
      data.push(this.div2.value());
    }
    if (this.div3) {
      data.push(this.div3.value());
    }
    const has_data = _.difference(data, [NaN, null]);
    if (has_data.length === 0) {
      return { ...cnt, volume: 0 };
    }

    let rs = shotFormatter.ListToVol(data);

    if (shotFormatter.unit.gravimetric) {
      rs /= cnt.specificgravity || 1;
    }

    return { ...cnt, volume: rs };
  };

  onChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.getValue().volume);
    }
  };

  render() {
    const { shotFormatter, cnt, disabled } = this.props;

    const bg = { backgroundColor: disabled ? '#6c757d' : '#FFF' };

    let tmp = {
      width: 100 / shotFormatter.divisors.length + '%',
      ...bg,
      color: disabled ? '#a5a5a5' : '#000',
      transition: 'unset',
    };

    let { value } = this.props;

    if (shotFormatter.unit.gravimetric) {
      value *= cnt.specificgravity || 1;
    }

    if (!value || isNaN(value)) {
      value = '';
    }

    const values = shotFormatter.VolToList(value);

    return (
      <div
        className={this.props.className}
        style={{ ...this.props.style, ...bg }}
      >
        <NumberInput
          dataTestid={cnt.cntcode + '0'}
          min={0}
          value={values[0]}
          ref={(node) => (this.div1 = node)}
          decimals={
            shotFormatter.divisors.length === 1 ? shotFormatter.decimals : 0
          }
          style={tmp}
          onChange={this.onChange}
          disabled={disabled}
        />
        {shotFormatter.divisors.length > 1 ? (
          <>
            -
            <NumberInput
              dataTestid={cnt.cntcode + '1'}
              min={0}
              value={values[1]}
              decimals={
                shotFormatter.divisors.length === 2 ? shotFormatter.decimals : 0
              }
              ref={(node) => (this.div2 = node)}
              style={tmp}
              onChange={this.onChange}
              disabled={disabled}
            />
          </>
        ) : null}
        {shotFormatter.divisors.length > 2 ? (
          <>
            -
            <NumberInput
              dataTestid={cnt.cntcode + '2'}
              min={0}
              decimals={shotFormatter.decimals}
              value={values[2]}
              ref={(node) => (this.div3 = node)}
              style={tmp}
              onChange={this.onChange}
              disabled={disabled}
            />
          </>
        ) : null}
      </div>
    );
  }
}

AmountInput.propTypes = propTypes;
AmountInput.defaultProps = defaultProps;
