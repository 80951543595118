import _ from 'lodash';
import {
  put,
  take,
  all,
  takeLatest,
  call,
  select,
  takeEvery,
} from 'redux-saga/effects';
import actions, {
  actionTypes as orderActionTypes,
  actionTypes,
  selectors as orderSelectors,
  RETINT_TYPE_FORMULA,
} from '../reducers/Order';

import { selectors as hoverSelectors } from '../reducers/HoverColor';
import formulaActions, {
  actionTypes as formulaTypes,
} from '../reducers/Formula';
import queueActions, {
  selectors as orderQueueSelectors,
} from '../reducers/OrderQueue';
import machineActions, {
  selectors as machineSelectors,
  actionTypes as machineActionTypes,
} from '../reducers/Machine';
import busyActions from '../reducers/Busy';
import { actionTypes as configActionTypes } from '../reducers/Configuration';
import { selectors as cacheSelectors } from '../reducers/Cache';
import { selectors as configSelectors } from '../reducers/Configuration';
import { base_addition_scaling_factor } from 'js/mylib/Formula';
import {
  save_customer,
  delete_customer,
  save_localorder,
  save_orderitem,
  post_dispense,
  hide_orderitem,
} from 'js/api/Order';
import { actionTypes as customerTypes } from 'js/redux/reducers/Customer';

import {
  waitForZone,
  saveWithSiteid,
  deleteWithSiteid,
  waitForConfigValues,
} from './Configuration';
import webRequest from './WebRequest';
import { rejected, fulfilled } from '../factories/ApiCall';
import {
  INFO_CARD,
  NUMERIC_ORDER_STATUS,
  ORDER_STATUS_WAITING,
  ORDER_STATUS_DONE,
  COLOR_SEARCH_CARD,
  PRODUCT_SEARCH_CARD,
  AMOUNT_CARD,
  ORDER_MODE_FREE_DISPENSE,
  ORDER_MODE_NORMAL,
  /*COLOR_SEARCH,
  CARD_SEARCH,
  PRODUCT_SEARCH_MODE_TREE,
  PRODUCT_SEARCH_MODE_LIST,*/
  ORDER_MODE_MATCHING,
  FORMULA_SEARCH_CARD,
  FORMULA_INPUT_CARD,
  SOURCE_USER,
  ORDER_MODE_LOCAL_FORMULA,
  ORDER_MODE_FORMULA_CORRECTION,
  FORMULA_CORRECTION_TYPE_ADD_BASE,
  FORMULA_CORRECTION_TYPE_REFORMULATE,
  SOURCE_ADDITION,
  ARTICLE_SEARCH_CARD,
  MATCH_SEARCH,
  PRODUCT_SEARCH_MODE_MATCH,
  MATCH_SEARCH_CARD,
  UAC_AUTO_LOGOUT_AFTER_ORDER,
} from '../../Constants';
import { push } from 'connected-react-router';
// import transferActions from 'js/redux/reducers/Transfer';
import errorActions from 'js/redux/reducers/Errors';
import i18n from 'js/localization/i18n';
import FormulaAPI from 'js/api/Formula';
import { selectors as userSelectors } from '../reducers/User';
import { isAlmostEqual, isSubset } from '../../mylib/Utils';
import {
  can_scaling_factor,
  update_extender_volume_from_redux,
} from 'js/mylib/Formula';
import { t } from 'i18next';
import historyActions from '../reducers/History';
import customersActions from '../reducers/Customer';
import { actionTypes as queueActionTypes } from '../reducers/OrderQueue';
import userActions from '../reducers/User';
import { dateTimeToLocaleString } from '../../mylib/DateUtils';
import log from '../../api/Logger';

function* setColor(action) {
  const { color } = action.payload;
  if (color && color.colourid != null) {
    const zone = yield call(waitForZone);
    try {
      yield put({ type: formulaTypes.FETCH_COLOR_DETAILS_PENDING });
      const response = yield call(
        webRequest,
        FormulaAPI.fetchColorDetails(zone.zoneid, color.colourid)
      );
      let data = response.data;
      let { colourcode, colournames } = data;
      const { color_code_name_swap } = yield call(waitForConfigValues);
      const { unique_color_codes } = yield call(waitForConfigValues);
      // If not unique color codes then show the alternatives if available
      if (!unique_color_codes) {
        colourcode = data.altcolourcode || colourcode;
      }

      if (color_code_name_swap && colournames?.length) {
        [colourcode, colournames] = [colournames[0], [colourcode]];
      }

      if (color.colournames && !isSubset(color.colournames, colournames)) {
        // preserve edited color name when loading order
        colournames = color.colournames;
      }

      data = { ...data, colourcode, colournames };
      // Fetch clicked color name from hover color
      const selected_name = yield select(hoverSelectors.name);

      yield put({
        type: formulaTypes.FETCH_COLOR_DETAILS_FULFILLED,
        payload: data,
      });
      yield put({
        type: actionTypes.SET_COLOR_DETAILS,
        payload: {
          ...data,
          selected_name: colournames.includes(selected_name)
            ? selected_name
            : undefined,
        },
      });
    } catch (e) {
      yield put({
        type: formulaTypes.FETCH_COLOR_DETAILS_REJECTED,
        payload: e,
      });
    }
  }
}

function* setScaledCnts(action) {
  const section = yield select(orderSelectors.open_section);
  const cnts = action.payload;
  const orderMode = yield select(orderSelectors.order_mode);
  if (cnts != null) {
    // call extra info calculation
    if (
      (orderMode === ORDER_MODE_NORMAL && section !== FORMULA_INPUT_CARD) ||
      (orderMode === ORDER_MODE_MATCHING && section !== INFO_CARD) ||
      (orderMode === ORDER_MODE_FORMULA_CORRECTION && section !== INFO_CARD) ||
      orderMode === ORDER_MODE_LOCAL_FORMULA // && section !== INFO_CARD)
    ) {
      yield put({ type: actionTypes.FETCH_ORDERITEM_PRICE });
    }
  }
}

function* validateFormula() {
  const cnts = yield select(orderSelectors.itemCnts);
  const dispID = yield select(machineSelectors.current_dispID);
  if (cnts && dispID != null) {
    const base_dosing = yield select(configSelectors.base_dosing);
    const machine_order = yield select(
      orderSelectors.machineFormat,
      base_dosing
    );
    if (machine_order?.formula.cnts.length) {
      yield put(
        machineActions.checkFormula(dispID, machine_order.formula.cnts)
      );
    } else {
      yield put({ type: actionTypes.CHECK_FORMULA_FULFILLED, payload: [] });
    }
  }
}

function* saveOrderitem(action) {
  let { navigate_to, itemstatus } = action.payload;
  if (navigate_to || itemstatus === ORDER_STATUS_DONE) {
    yield put(busyActions.setBusy(true));
  }
  const { siteid, localclient } = yield select(configSelectors.site);
  const { zoneid } = yield select(configSelectors.zone);
  const localuserid = yield select(userSelectors.localuserid);
  const { color_code_name_swap } = yield call(waitForConfigValues);
  try {
    if (siteid == null) throw new Error('Site not set');

    // wait for prices
    if (yield select(orderSelectors.price_pending)) {
      const price_action = yield take([
        orderActionTypes.FETCH_ORDERITEM_PRICE_FULFILLED,
        orderActionTypes.FETCH_ORDERITEM_PRICE_REJECTED,
      ]);
      if (
        price_action.type === orderActionTypes.FETCH_ORDERITEM_PRICE_REJECTED
      ) {
        throw new Error(price_action.payload);
      }
    }

    const { clear_item_after_saving } = action.payload;
    if (itemstatus)
      yield put({ type: actionTypes.SET_ITEM_STATUS, payload: itemstatus });
    else itemstatus = yield select(orderSelectors.itemstatus);

    // save localorder
    const order = yield select(orderSelectors.order);
    const localorder = _.pick(order, 'orderid', 'customerid', 'notes');
    localorder.siteid = siteid;
    localorder.zoneid = zoneid;
    localorder.clientid = (localclient || {}).clientid;
    localorder.localuserid = localuserid;
    // Need to check other items related to this order.
    if (localorder.orderid) {
      // Multi item case and order saved
      const local_queue = yield select(orderQueueSelectors.local_queue);

      const items =
        _.find(local_queue, ['orderid', localorder.orderid])?.orderitems || [];
      let status_min = items
        .filter((x) => x.itemid !== order?.item?.itemid)
        .map((x) => x.status);

      status_min.push(NUMERIC_ORDER_STATUS[itemstatus]);
      localorder.orderstatus = Math.min(...status_min);
    } else {
      localorder.orderstatus = NUMERIC_ORDER_STATUS[itemstatus];
    }

    const now = new Date(Date.now()).toISOString();
    localorder.orderdate = order.orderdate || now;
    localorder.modificationdate = now;
    yield put({
      type: actionTypes.SET_ORDER_DATE,
      payload: localorder.orderdate,
    });

    const {
      data: { orderid },
    } = yield call(webRequest, save_localorder(localorder));

    // save orderitem
    const {
      manually_set_colorcode,
      cnts,
      notes,
      additionOnly,
      source,
      formulaCorrectionScalingFactor,
      ...item
    } = order.item;
    let { basevolume, nominalamount } = order.can;

    if (additionOnly && formulaCorrectionScalingFactor > 1) {
      // correction with base addition: save as custom can
      basevolume *= formulaCorrectionScalingFactor;
      nominalamount *= formulaCorrectionScalingFactor;
      item.cansizecode = null;
    }
    item.orderitemprice = yield select(orderSelectors.orderitemprice);

    // Save originalvolume only when different from volume.
    // Save additionvolume as volume if addition.
    const cntsinitem = cnts
      ? cnts.map((x) => {
          const volume = additionOnly ? x.additionvolume : x.volume;
          const cumulativevolume = additionOnly ? x.volume : null;
          return {
            ...x,
            originalvolume:
              isAlmostEqual(x.originalvolume, x.volume) ||
              manually_set_colorcode
                ? null
                : x.originalvolume,
            volume,
            cumulativevolume,
          };
        })
      : null;
    const orderitem = {
      ...item,
      orderid,
      siteid,
      cntsinitem,
      originalcode: order.item.originalCode || order.color.colourcode,
      itemnotes: notes,
      fcomment: order.formula?.fcomment,
      source: additionOnly ? SOURCE_ADDITION : source,
      status: NUMERIC_ORDER_STATUS[itemstatus],
      basevolume,
      nominalamount,
      gravimetricnominal: order.can ? order.can.gravimetric : null,
      reflectance: order.color?.reflectance,
    };
    if (color_code_name_swap && orderitem.colourname) {
      const name = orderitem.colourname;
      orderitem.colourname = orderitem.colourcode;
      orderitem.colourcode = name;
      orderitem.originalcode =
        order.color.colournames.length > 0
          ? order.color.colournames[0]
          : order.color.colourcode;
    }

    const {
      data: { itemid },
    } = yield call(webRequest, save_orderitem(orderitem));

    yield put({ type: actionTypes.SET_IDS, payload: { itemid, orderid } });

    if (clear_item_after_saving) {
      yield put({ type: actionTypes.CLEAR_ORDER_ITEM });
    }

    yield put(queueActions.fetchLocalQueue());
    yield take(fulfilled(queueActionTypes.FETCH_LOCAL_QUEUE));

    yield put({ type: fulfilled(action.type), payload: null });
    if (navigate_to) {
      yield put(push(navigate_to));
    }
  } catch (e) {
    yield put({ type: rejected(action.type), payload: e });
  }
  yield put(busyActions.setBusy(false));
}

function* retintOrderitem(action) {
  const isAdditionOnly = yield select(orderSelectors.isAdditionOnly);
  if (isAdditionOnly) {
    const itemEdited = yield select(orderSelectors.itemEdited);
    // if true, we have original volumes and can regenerate color code if user makes further edit
    if (action.payload === RETINT_TYPE_FORMULA || !itemEdited) {
      // use cumulative formula as starting point
      yield put(actions.clearAddition());
    }
    yield put(actions.setAdditionOnly(false));
  }
  const root = yield select();
  const cnts = update_extender_volume_from_redux(root.order.item.cnts, root);
  yield put({ type: actionTypes.SET_SCALED_CNTS, payload: cnts });
}

function* closeOrderItems(action) {
  try {
    let items = action.payload;
    const order = yield select(orderSelectors.order);
    const local_queue = yield select(orderQueueSelectors.local_queue);

    if (!items) {
      // Removing order item only if not already done!
      const orderitem = order.item;
      if (orderitem.status !== ORDER_STATUS_DONE && orderitem.itemid != null) {
        items = [orderitem.itemid];
      } else {
        items = [];
      }
    }

    if (items.length) {
      yield put(queueActions.closeItems(items));
    }

    let item_count = (
      _.find(local_queue, ['orderid', order.orderid])?.orderitems || []
    ).length;

    if (item_count === 1) {
      // Clear selected order
      yield put({ type: actionTypes.CLEAR_ORDER });
    } else {
      yield put({ type: actionTypes.CLEAR_ORDER_ITEM });
    }

    yield put({ type: fulfilled(action.type), payload: null });
  } catch (e) {
    yield put({ type: rejected(action.type), payload: e });
  }
}

function* hideOrderitem(action) {
  try {
    const itemid = action.payload;
    const { siteid } = yield select(configSelectors.site);

    yield call(webRequest, hide_orderitem({ itemid, siteid }));

    yield put({ type: fulfilled(action.type), payload: null });
  } catch (e) {
    yield put({ type: rejected(action.type), payload: e });
  }
}

function* tintOrderitem(action) {
  const itemid = yield select(orderSelectors.itemid);
  if (itemid == null) {
    // orderitem must be saved before tinting because we need the itemid to record cans tinted
    yield put(actions.saveOrderitem(ORDER_STATUS_WAITING));
    const res = yield take([
      actionTypes.SAVE_ORDERITEM_FULFILLED,
      actionTypes.SAVE_ORDERITEM_REJECTED,
    ]);
    if (res.type === actionTypes.SAVE_ORDERITEM_REJECTED) {
      // TODO: handle error
      return;
    }
  }
  const { dispID, skip_tdf_base } = action.payload;
  const base_dosing = yield select(configSelectors.base_dosing);
  const machine_order = yield select(orderSelectors.machineFormat, base_dosing);
  const summary = yield select(orderSelectors.summary);
  log.info(`Start tinting: ${summary}`);

  if (machine_order?.formula.cnts.length) {
    yield put(machineActions.tintOrder(dispID, machine_order, skip_tdf_base));
  } else {
    log.info('Formula is empty, tinting skipped');
    for (let i = machine_order.cansTinted; i < machine_order.lotSize; ++i) {
      yield put({
        type: machineActionTypes.TINT_CAN_READY,
        payload: { itemid: machine_order.itemID, machineid: undefined },
      });
    }
    yield put({
      type: actionTypes.ORDERITEM_TINTED,
      payload: action.payload.order,
    });
  }
}

function* orderitemTinted() {
  try {
    yield put({
      type: actionTypes.SAVE_ORDERITEM,
      payload: { itemstatus: ORDER_STATUS_DONE },
    });

    // Wait until order is saved
    yield take(fulfilled(orderActionTypes.SAVE_ORDERITEM));

    //const { payload } = action;
    // Set user to null if supposed to log out after tinting.
    const { uac_auto_logout, open_queue_after_tinting } = yield select(
      configSelectors.config_values
    );
    if (uac_auto_logout === UAC_AUTO_LOGOUT_AFTER_ORDER) {
      yield put(userActions.loginUser(null, null));
    }

    if (open_queue_after_tinting) {
      // Navigate to queue if option set
      yield put(push('/order-queue'));
      return;
    }

    const order = yield select(orderSelectors.order);
    const local_queue = yield select(orderQueueSelectors.local_queue);

    // Check if order has multiple items. Then move to next not dispensed item
    const queue_order = _.find(local_queue, ['orderid', order.orderid]);
    const next_item = queue_order?.orderitems.find(
      (x) => x.ncans > x.ncans_tinted && x.itemid !== order.item.itemid
    );
    if (next_item) {
      // Load one of the items still not tinted.
      yield put(actions.fetchOrderitem(next_item.itemid));
    } else {
      // Navigate back to home after printing is finished and no items to be tinted
      yield put(push('/'));
    }
  } catch (e) {
    log.error(e);
  }
}

function* saveCustomer(action) {
  const customer = yield select(orderSelectors.customer);
  const { type } = action;
  if (customer != null)
    yield* saveWithSiteid(save_customer, { type, payload: customer });
}

function* deleteCustomer(action) {
  const { payload, type } = action;
  if (payload !== null) {
    yield* deleteWithSiteid(delete_customer, { type, payload });
    yield put({
      type: customerTypes.SEARCH_CUSTOMERS,
      payload: { search: '', page: 0 },
    });
  }
}

function* applyCustomer(action) {
  try {
    const {
      type,
      payload: {
        customer,
        orderid,
        historySearchParams: {
          search,
          fromDate,
          toDate,
          onlyCustomFormulas,
          onlyLocalOrders,
          seek,
        },
      },
    } = action;
    yield put(busyActions.setBusy(true));

    let customerid = customer ? customer.customerid : null;
    // If applying a new customer, save the customer first
    if (customer && customerid == null) {
      const data = yield* saveWithSiteid(save_customer, {
        type,
        payload: customer,
      });
      customerid = data.customerid;
      yield put(customersActions.searchCustomers(customer.customername, 0));
    }

    const localorder = {
      orderid,
      customerid,
    };
    yield call(webRequest, save_localorder(localorder));
    yield put(
      historyActions.searchOrderitems(
        search,
        fromDate,
        toDate,
        onlyCustomFormulas,
        onlyLocalOrders,
        seek
      )
    );

    yield put(busyActions.setBusy(false));
  } catch (e) {
    log.error(e);
    yield put(busyActions.setBusy(false));
    errorActions.showCriticalError(
      i18n.t('msg.unableApplyCustomer', 'Unable to apply customer to order'),
      e
    );
  }
}

function* machineTintCanReady(action) {
  try {
    const { itemid, machineid } = action.payload;

    const localuserid = yield select(userSelectors.localuserid);
    yield call(
      webRequest,
      post_dispense({
        itemid,
        dispensetime: new Date().toISOString(),
        machineid,
        localuserid,
      })
    );
    yield put(queueActions.fetchLocalQueue());
  } catch (e) {
    log.error(e);
    errorActions.showCriticalError(
      i18n.t('msg.unableReportDispense', 'Unable to report dispense'),
      e
    );
  }

  // Save orderitem ncans change

  /*
  // Notify QT side about finished dispense
  try {
    const { siteid } = yield select(configSelectors.site);
    const { zoneid } = yield select(configSelectors.zone);
    const order = yield select(orderSelectors.order);
    const localorder = _.pick(order, 'orderid', 'customerid', 'notes');
    const itemstatus = yield select(orderSelectors.itemstatus);
    localorder.siteid = siteid;
    localorder.zoneid = zoneid;
    localorder.orderstatus = NUMERIC_ORDER_STATUS[itemstatus];

    const { cnts, notes, ...item } = order.item;
    const orderitem = {
      ...item,
      cntsinitem: cnts,
      itemnotes: notes,
      status: NUMERIC_ORDER_STATUS[itemstatus],
      ..._.pick(order.can, 'basevolume', 'nominalamount'),
      gravimetricnominal: order.can ? order.can.gravimetric : null,
    };

    // also call the Qt side for external transfer
    yield put(
      transferActions.sendOrderQt({
        ...localorder,
        orderitem: { ...orderitem },
      })
    );
  } catch (e) {
    // TODO: handle failure
    yield put(
      errorActions.showCriticalError(
        i18n.t('msg.unableToSendOrderToQt', 'Unable to send order to Qt'),
        e
      )
    );
  }
  */
}

function* setColourCodeAfterFormulaEdit(action) {
  try {
    yield put(actions.setOrderitemSource(SOURCE_USER));

    //Recalculate originalColorFormula based on formulaEdited
    const shotFormatter = action.payload.shotFormatter
      ? action.payload.shotFormatter
      : yield select(configSelectors.shotFormatter);
    const order = yield select(orderSelectors.order);
    const cntmap = yield select(cacheSelectors.cntmap);
    const factor =
      order.can && order.base ? can_scaling_factor(order.base, order.can) : 1;

    const formula = action.payload.frm || order.formula;
    yield put(formulaActions.synthesizeRgb(formula));
    const scaledby = action.payload.scaledby || order.item.scaledby;

    const colorCodeParts = [order.item.originalCode || order.item.colourcode];
    const percent = (scaledby - 1) * 100;
    if (percent) {
      const sign = percent > 0 ? '+' : '-';
      const rounded = Math.abs(Math.round(percent));
      colorCodeParts.push(`${sign}${rounded}%`);
    }

    const original_cnts = order.original_formula.cntinformula.map(
      (c) => c.cntid
    );
    const cnts = formula.cntinformula.map((c) => c.cntid);

    // Get the formula changes
    const diff = _.union(cnts, original_cnts)
      .map((cntid) => {
        const o = order.original_formula.cntinformula.find(
          (c) => c.cntid === cntid
        );
        const n = formula.cntinformula.find((c) => c.cntid === cntid);

        return {
          ...cntmap.get(cntid),

          volume: n?.volume || 0,
          original_volume: (o?.volume || 0) * scaledby,
        };
      })
      .filter((x) => !isAlmostEqual(x.volume, x.original_volume));

    for (const x of _.sortBy(diff, 'cntcode')) {
      const v = shotFormatter.format({
        volume: (x.volume - x.original_volume) * factor,
        specificgravity: x.specificgravity,
      });
      colorCodeParts.push(`${x.cntcode} ${v}`);
    }

    yield put({
      type: actionTypes.SET_COLOURCODE,
      payload: { manual_set_code: false, code: colorCodeParts.join(' ') },
    });
  } catch (e) {
    yield put({
      type: actionTypes.SET_COLOURCODE_AFTER_FORMULA_EDIT_REJECTED,
      payload: e,
    });
  }
}

function* setFormulaAfterCorrection(action) {
  try {
    yield put(actions.setOrderitemSource(SOURCE_USER));

    const { correctionType, correctionData } = action.payload;

    const { original_formula } = yield select(orderSelectors.order);
    const { rgb, formula: corrected_frm } = correctionData;

    yield put(actions.setFormulaCorrectionType(correctionType));
    yield put(
      actions.setAdditionOnly(
        correctionType !== FORMULA_CORRECTION_TYPE_REFORMULATE
      )
    );
    yield put(
      actions.setFormulaCorrectionScalingFactor(
        correctionType === FORMULA_CORRECTION_TYPE_ADD_BASE
          ? base_addition_scaling_factor(
              corrected_frm.cntinformula,
              original_formula.cntinformula
            )
          : 1
      )
    );

    yield put(actions.setFormula(corrected_frm));
    const order = yield select(orderSelectors.order);
    yield put(actions.setCan(order.can));
    yield take(actionTypes.SET_SCALED_CNTS);

    let auto_code =
      t('lbl.corrected_autoColorCode', 'Corrected') +
      ' ' +
      dateTimeToLocaleString(new Date());

    yield put(
      actions.setColor({
        colourid: null,
        colourcode: auto_code,
        colournames: undefined,
        rgb, // of corrected, for display
        reflectance: order.color?.reflectance, // of original, for possible 2nd correction
      })
    );
  } catch (e) {
    yield put({
      type: actionTypes.SET_FORMULA_AFTER_CORRECTION_REJECTED,
      payload: e,
    });
  }
}

function* setProductAfterConfirmation() {
  try {
    const order = yield select(orderSelectors.order);
    const matchingMode =
      order.color_search_mode === MATCH_SEARCH ||
      order.product_search_mode === PRODUCT_SEARCH_MODE_MATCH;
    const currentOrderColor = yield select(orderSelectors.color);
    if (currentOrderColor.colourid && !matchingMode) {
      const product = yield select(orderSelectors.product);

      const oldCan = yield select(orderSelectors.can);

      yield put({
        type: formulaTypes.FETCH_FORMULA,
        payload: {
          colourid: currentOrderColor.colourid,
          productid: product.productid,
        },
      });
      const newBase = yield take(actionTypes.SET_BASE);
      yield take(actionTypes.SET_FORMULA_FULFILLED);

      if (
        newBase.payload.cans.find(
          (can) => can.cansizecode === oldCan?.cansizecode
        )
      ) {
        yield put(
          actions.setCan(
            newBase.payload.cans.find(
              (can) => can.cansizecode === oldCan.cansizecode
            )
          )
        );
        yield put({
          type: actionTypes.SET_PREVIOUS_SECTION,
          payload: PRODUCT_SEARCH_CARD,
        });
        yield put({
          type: actionTypes.SET_OPEN_SECTION,
          payload: INFO_CARD,
        });
        yield put({
          type: actionTypes.SET_PRODUCT_AFTER_CONFIRMATION_FULFILLED,
          payload: true,
        });
      } else {
        yield put(actions.setCan(null));
        yield put({ type: actionTypes.SET_OPEN_SECTION, payload: AMOUNT_CARD });
      }
    } else {
      yield put(actions.setColor(null));
      yield put(actions.setCard(null));
      yield put(actions.setCan(null));
      yield put(actions.clearFormula());
      yield put({
        type: actionTypes.SET_OPEN_SECTION,
        payload: COLOR_SEARCH_CARD,
      });
      yield put({
        type: actionTypes.SET_PRODUCT_AFTER_CONFIRMATION_FULFILLED,
        payload: true,
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.SET_PRODUCT_AFTER_CONFIRMATION_REJECTED,
      payload: e,
    });
  }
}

function* setColorAfterConfirmation() {
  try {
    const order = yield select(orderSelectors.order);
    const matchingMode =
      order.color_search_mode === MATCH_SEARCH ||
      order.product_search_mode === PRODUCT_SEARCH_MODE_MATCH;
    const product = yield select(orderSelectors.product);
    const can = yield select(orderSelectors.can);
    let formula;
    if (product?.productid && !matchingMode) {
      const colour = yield select(orderSelectors.color);
      yield put({
        type: formulaTypes.FETCH_FORMULA,
        payload: {
          colourid: colour.colourid,
          productid: product.productid,
        },
      });
      formula = (yield take(actionTypes.SET_FORMULA_FULFILLED)).payload;
    }
    if (formula) {
      yield put(actions.setCan(can));
      yield put({
        type: actionTypes.SET_PREVIOUS_SECTION,
        payload: COLOR_SEARCH_CARD,
      });
      yield put({
        type: actionTypes.SET_OPEN_SECTION,
        payload: INFO_CARD,
      });
      yield put({
        type: actionTypes.SET_COLOR_AFTER_CONFIRMATION_FULFILLED,
        payload: true,
      });
    } else {
      yield put({ type: actionTypes.SET_PRODUCT, payload: null });
      yield put(actions.setCan(null));
      yield put(actions.clearFormula());
      yield put({
        type: actionTypes.SET_OPEN_SECTION,
        payload: PRODUCT_SEARCH_CARD,
      });
      yield put({
        type: actionTypes.SET_COLOR_AFTER_CONFIRMATION_FULFILLED,
        payload: true,
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.SET_COLOR_AFTER_CONFIRMATION_REJECTED,
      payload: e,
    });
  }
}

function sectionOrder(
  order_mode,
  order_start_section,
  color_search_mode,
  product_search_mode
) {
  switch (order_mode) {
    case ORDER_MODE_NORMAL: {
      switch (order_start_section) {
        case PRODUCT_SEARCH_CARD:
          if (color_search_mode === MATCH_SEARCH) {
            return [
              PRODUCT_SEARCH_CARD,
              COLOR_SEARCH_CARD,
              AMOUNT_CARD,
              FORMULA_SEARCH_CARD,
              INFO_CARD,
            ];
          } else {
            return [
              PRODUCT_SEARCH_CARD,
              COLOR_SEARCH_CARD,
              AMOUNT_CARD,
              INFO_CARD,
            ];
          }
        case ARTICLE_SEARCH_CARD:
          return [
            ARTICLE_SEARCH_CARD,
            PRODUCT_SEARCH_CARD,
            AMOUNT_CARD,
            INFO_CARD,
          ];
        default:
          if (product_search_mode === PRODUCT_SEARCH_MODE_MATCH)
            return [
              COLOR_SEARCH_CARD,
              PRODUCT_SEARCH_CARD,
              AMOUNT_CARD,
              FORMULA_SEARCH_CARD,
              INFO_CARD,
            ];
          else
            return [
              COLOR_SEARCH_CARD,
              PRODUCT_SEARCH_CARD,
              AMOUNT_CARD,
              INFO_CARD,
            ];
      }
    }
    case ORDER_MODE_FORMULA_CORRECTION:
      return [MATCH_SEARCH_CARD, FORMULA_SEARCH_CARD, INFO_CARD];
    case ORDER_MODE_FREE_DISPENSE:
      return [FORMULA_INPUT_CARD, INFO_CARD];
    case ORDER_MODE_LOCAL_FORMULA:
      return [
        COLOR_SEARCH_CARD,
        PRODUCT_SEARCH_CARD,
        AMOUNT_CARD,
        FORMULA_INPUT_CARD,
        INFO_CARD,
      ];
    case ORDER_MODE_MATCHING:
      return [
        COLOR_SEARCH_CARD,
        PRODUCT_SEARCH_CARD,
        AMOUNT_CARD,
        FORMULA_SEARCH_CARD,
        INFO_CARD,
      ];
    default:
      // should be unnecessary
      return [COLOR_SEARCH_CARD, PRODUCT_SEARCH_CARD, AMOUNT_CARD, INFO_CARD];
  }
}

/**
 * Added support for moving forward or backward as well as setting currenct section
 * @param action
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"PUT", PutEffectDescriptor<{payload: null, type: *}>>|<"SELECT", SelectEffectDescriptor>|<"PUT", PutEffectDescriptor<{type: *}>>>}
 */
function* setOpenSection(action) {
  const order_mode = yield select(orderSelectors.order_mode);
  const order_start_section = yield select(orderSelectors.order_start_section);
  const color_search_mode = yield select(orderSelectors.color_search_mode);
  const product_search_mode = yield select(orderSelectors.product_search_mode);
  const sections = sectionOrder(
    order_mode,
    order_start_section,
    color_search_mode,
    product_search_mode
  );
  const open_sction = yield select(orderSelectors.open_section);
  yield put({ type: actionTypes.SET_PREVIOUS_SECTION, payload: open_sction });
  const prev_section = yield select(orderSelectors.prev_section);
  let section = action.payload.section || open_sction;
  if (action.payload.moveToNext) {
    const i = sections.indexOf(section);
    if (i < sections.length - 1) {
      section = sections[i + 1];
    } else {
      return;
    }
  } else if (action.payload.moveToPrev) {
    const i = sections.indexOf(section);
    if (i > 0) {
      section = sections[i - 1];
    } else {
      return;
    }
  }

  if (prev_section !== INFO_CARD) {
    const sec_i = sections.indexOf(section);
    if (sec_i > -1) {
      const color_i = sections.indexOf(COLOR_SEARCH_CARD);
      const product_i = sections.indexOf(PRODUCT_SEARCH_CARD);
      const amount_i = sections.indexOf(AMOUNT_CARD);
      const formulaSearch_i = sections.indexOf(FORMULA_SEARCH_CARD);

      if (sec_i < color_i && color_i > -1) {
        yield put(actions.setColor(null));
      }

      if (sec_i < product_i && product_i > -1) {
        yield put({ type: actionTypes.SET_PRODUCT, payload: null });
      }

      if (
        (sec_i < amount_i &&
          amount_i > -1 &&
          (order_mode === ORDER_MODE_NORMAL ||
            order_mode === ORDER_MODE_MATCHING)) ||
        (sec_i === 0 && order_mode !== ORDER_MODE_FORMULA_CORRECTION) // If in first section and not formula correction clear rest
      ) {
        yield put(actions.setCan(null));
        // clear also formula here
        yield put(actions.clearFormula());
        yield put({
          type: formulaTypes.MATCH_FORMULA_FULFILLED,
          payload: null,
        });
        yield put({
          type: formulaTypes.SEARCH_CLOSEST_FULFILLED,
          payload: null,
        });
        yield put({ type: formulaTypes.SET_ADDED_FORMULA, payload: {} });
      }
      if (
        sec_i === formulaSearch_i &&
        ![ORDER_MODE_FORMULA_CORRECTION, ORDER_MODE_MATCHING].includes(
          order_mode
        )
      ) {
        yield put({ type: actionTypes.SET_BASE, payload: null });
      }
    }
  }
  if (section === FORMULA_INPUT_CARD) {
    yield put(formulaActions.synthesizeRgbBeforeEdit());
  }
  if (section === INFO_CARD && order_mode === ORDER_MODE_FREE_DISPENSE)
    yield put({ type: actionTypes.FETCH_ORDERITEM_PRICE });
  yield put({
    type: actionTypes.SET_OPEN_SECTION,
    payload: section,
  });
}

/**
 * Updates the order reducer based on configuration changes
 * @param action
 * @returns {IterableIterator<<"SELECT", SelectEffectDescriptor>|IterableIterator<<"SELECT", SelectEffectDescriptor>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>>}
 */
function* updateBasedOnConfig() {
  // TODO: Check how this should work!
  /*
  const card_search = _.get(action.payload, 'color_search_tree.value', true);
  if (!card_search) {
    yield put(actions.setColorSearchMode(COLOR_SEARCH));
  } else {
    yield put(actions.setColorSearchMode(CARD_SEARCH));
  }

  const prod_tree_search = _.get(
    action.payload,
    'product_search_table.value',
    false
  );
  if (!prod_tree_search) {
    yield put(actions.setProductSearchMode(PRODUCT_SEARCH_MODE_LIST));
  } else {
    yield put(actions.setProductSearchMode(PRODUCT_SEARCH_MODE_TREE));
  }

   */
}

export default function* saga() {
  yield all([
    takeLatest(actionTypes.SET_COLOR, setColor),
    takeLatest(actionTypes.SET_SCALED_CNTS, setScaledCnts),
    takeLatest(
      [
        actionTypes.SET_SCALED_CNTS,
        actionTypes.SET_ADDITION_ONLY,
        machineActionTypes.SET_CURRENT_MACHINE,
      ],
      validateFormula
    ),
    takeLatest(actionTypes.SWITCH_OPEN_SECTION, setOpenSection),
    takeEvery(actionTypes.SAVE_ORDERITEM, saveOrderitem),
    takeLatest(
      [actionTypes.RETINT_ORDER, actionTypes.RETINT_ITEM],
      retintOrderitem
    ),
    takeEvery(actionTypes.CLOSE_ORDERITEMS, closeOrderItems),
    takeEvery(actionTypes.SAVE_CUSTOMER, saveCustomer),
    takeEvery(actionTypes.DELETE_CUSTOMER, deleteCustomer),
    takeLatest(actionTypes.APPLY_CUSTOMER, applyCustomer),
    takeLatest(actionTypes.TINT_ORDERITEM, tintOrderitem),
    takeEvery(machineActionTypes.TINT_CAN_READY, machineTintCanReady),
    takeLatest(orderActionTypes.ORDERITEM_TINTED, orderitemTinted),
    takeLatest(configActionTypes.FETCH_CONFIG_FULFILLED, updateBasedOnConfig),
    takeLatest(
      actionTypes.SET_COLOR_AFTER_CONFIRMATION,
      setColorAfterConfirmation
    ),
    takeLatest(
      actionTypes.SET_PRODUCT_AFTER_CONFIRMATION,
      setProductAfterConfirmation
    ),
    takeLatest(
      actionTypes.SET_COLOURCODE_AFTER_FORMULA_EDIT,
      setColourCodeAfterFormulaEdit
    ),
    takeLatest(
      actionTypes.SET_FORMULA_AFTER_CORRECTION,
      setFormulaAfterCorrection
    ),
    takeEvery(actionTypes.HIDE_ITEM, hideOrderitem),
  ]);
}
