import { globalizeSelectors } from '../Utils';
import { BARCODE_MODE_NORMAL } from 'js/Constants';
/**
 * Just a simple flag to notify the UI that progress modal needs to be shown!
 */

/*
 * action types
 */

const types = {
  BARCODE_DETAILS: 'BARCODEACTIONS/BARCODE_DETAILS',
  BARCODE_DETAILS_PENDING: 'BARCODEACTIONS/BARCODE_DETAILS_PENDING',
  BARCODE_DETAILS_FULFILLED: 'BARCODEACTIONS/BARCODE_DETAILS_FULFILLED',
  BARCODE_DETAILS_REJECTED: 'BARCODEACTIONS/BARCODE_DETAILS_REJECTED',
  RESOLVE_BARCODE: 'BARCODEACTIONS/RESOLVE_BARCODE',
  RESOLVE_BARCODE_PENDING: 'BARCODEACTIONS/RESOLVE_BARCODE_PENDING',
  RESOLVE_BARCODE_FULFILLED: 'BARCODEACTIONS/RESOLVE_BARCODE_FULFILLED',
  RESOLVE_BARCODE_REJECTED: 'BARCODEACTIONS/RESOLVE_BARCODE_REJECTED',
  CLEAR_BARCODE_STATE: 'BARCODEACTIONS/CLEAR_BARCODE_STATE',
  SETMODE: 'BARCODEACTIONS/SETMODE',
  SETCANBARCODEVALID: 'BARCODEACTIONS/SETCANBARCODEVALID',
  SETBARCODE: 'BARCODEACTIONS/SETBARCODE',
  SET_SHOW_BARCODE_BLOCKED_DURING_DISPENSE_MODAL:
    'BARCODEACTIONS/SET_SHOW_BARCODE_BLOCKED_DURING_DISPENSE_MODAL',
};

/*
 * action creators
 */

const actions = {
  resolveBarcode: (barcode) => ({
    type: types.RESOLVE_BARCODE,
    payload: barcode,
  }),
  barcodeDetails: (barcode) => ({
    type: types.BARCODE_DETAILS,
    payload: barcode,
  }),
  setMode: (mode) => ({
    type: types.SETMODE,
    payload: mode,
  }),
  clearBarcodeState: () => ({ type: types.CLEAR_BARCODE_STATE }),
  setShowBarcodeWarningModal: (mode) => ({
    type: types.SET_SHOW_BARCODE_BLOCKED_DURING_DISPENSE_MODAL,
    payload: mode,
  }),
};

const mountPath = 'barcodeaction'; // mount point in global state, must match root reducer

/**
 * Handling login and log out site actions
 */

const initialState = {
  barcodemode: BARCODE_MODE_NORMAL,
  barcode: null,
  barcodeaction: null,
  canbarcodevalid: false,
  fetch_barcodeaction_error: null,
  fetch_barcodeaction_start: false,
  last_scanned_canid: null,
  barcode_details: null,
  barcode_details_pending: false,
  barcode_details_rejected: null,
  show_barcode_blocked_during_dispense_modal: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SETMODE: {
      return {
        ...state,
        barcodemode: action.payload,
      };
    }
    case types.SET_SHOW_BARCODE_BLOCKED_DURING_DISPENSE_MODAL: {
      return {
        ...state,
        show_barcode_blocked_during_dispense_modal: action.payload,
      };
    }
    case types.SETCANBARCODEVALID: {
      return {
        ...state,
        canbarcodevalid: action.payload,
      };
    }
    case types.SETBARCODE: {
      return {
        ...state,
        barcode: action.payload,
      };
    }
    case types.CLEAR_BARCODE_STATE: {
      return {
        ...state,
        canbarcodevalid: false,
        barcodeaction: null,
        fetch_barcodeaction_start: false,
        fetch_barcodeaction_error: null,
        barcode: null,
        last_scanned_canid: null,
      };
    }
    case types.RESOLVE_BARCODE_PENDING: {
      return {
        ...state,
        fetch_barcodeaction_start: action.payload,
      };
    }
    case types.RESOLVE_BARCODE_REJECTED: {
      return {
        ...state,
        barcodeaction: null,
        fetch_barcodeaction_start: false,
        fetch_barcodeaction_error: action.payload,
      };
    }
    case types.RESOLVE_BARCODE_FULFILLED: {
      return {
        ...state,
        barcodeaction: action.payload,
        fetch_barcodeaction_start: false,
        fetch_barcodeaction_error: null,
        last_scanned_canid: action.payload.canid || state.last_scanned_canid,
      };
    }

    case types.BARCODE_DETAILS_FULFILLED: {
      return {
        ...state,
        barcode_details: action.payload,
        barcode_details_pending: false,
        barcode_details_rejected: null,
      };
    }
    case types.BARCODE_DETAILS_REJECTED: {
      return {
        ...state,
        barcode_details: null,
        barcode_details_pending: false,
        barcode_details_rejected: action.payload,
      };
    }
    case types.BARCODE_DETAILS_PENDING: {
      return {
        ...state,
        barcode_details_pending: true,
        barcode_details_rejected: null,
      };
    }
    default: {
      return state;
    }
  }
}

// selectors

const localSelectors = {
  barcodeaction: (state) => state.barcodeAction,
  barcodemode: (state) => state.barcodemode,
  last_scanned_canid: (state) => state.last_scanned_canid,
  show_barcode_blocked_during_dispense_modal: (state) =>
    state.show_barcode_blocked_during_dispense_modal,
};

const selectors = globalizeSelectors(localSelectors, mountPath);

export { types as actionTypes, actions as default, selectors, reducer };
